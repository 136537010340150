import React, { useContext, useState } from 'react';
import { Button, Link, Menu, MenuItem } from '@mui/material';
import { apiContext } from '../hooks/call_apis';

const MainMenu = () => {
  const WAREHOUSE_USER = 'warehouse_user';
  const CUSTOMER = 'customer';

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { authorities } = useContext(apiContext);

  const open = {
    master: Boolean(anchorEl?.id === 'basic-button-master'),
    stock: Boolean(anchorEl?.id === 'basic-button-stock'),
    berth: Boolean(anchorEl?.id === 'basic-button-berth'),
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {authorities.includes(WAREHOUSE_USER) && (
        <>
          <Button
            id="basic-button-master"
            aria-controls={open.master ? 'basic-menu-master' : undefined}
            aria-haspopup="true"
            aria-expanded={open.master ? 'true' : undefined}
            onClick={handleClick}
            sx={{ marginLeft: 2 }}
          >
            マスタ管理
          </Button>
          <Menu
            id="basic-menu-master"
            anchorEl={anchorEl}
            open={open.master}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button-master',
            }}
          >
            <MenuItem onClick={handleClose}>
              <Link id="users-link" href="/users">
                ユーザーマスタ
              </Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link id="warehouses-link" href="/warehouses">
                倉庫マスタ
              </Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link id="berths-link" href="/berths">
                バースマスタ
              </Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link id="reservation-slots-link" href="/reservation-slots">
                バース予約枠マスタ
              </Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link id="customers-link" href="/customers">
                取引先マスタ
              </Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link id="offices-link" href="/offices">
                事業所マスタ
              </Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link id="departments-link" href="/departments">
                部署マスタ
              </Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link id="products-link" href="/products">
                商品マスタ
              </Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link id="stock-units-link" href="/stock-units">
                在庫単位マスタ
              </Link>
            </MenuItem>
          </Menu>
        </>
      )}
      {(authorities.includes(WAREHOUSE_USER) || authorities.includes(CUSTOMER)) && (
        <>
          <Button
            id="basic-button-berth"
            aria-controls={open.berth ? 'basic-menu-berth' : undefined}
            aria-haspopup="true"
            aria-expanded={open.berth ? 'true' : undefined}
            onClick={handleClick}
            sx={{ marginLeft: 2 }}
          >
            バース予約
          </Button>
          <Menu
            id="basic-menu-berth"
            anchorEl={anchorEl}
            open={open.berth}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button-berth',
            }}
          >
            {authorities.includes(WAREHOUSE_USER) && [
              <MenuItem onClick={handleClose} key="menuitem-berth-timeline-link">
                <Link id="berth-timeline-link" href="/berth-reservations">
                  バース予約状況
                </Link>
              </MenuItem>,
              <MenuItem onClick={handleClose} key="menuitem-weekly-plans-link">
                <Link id="weekly-plans-link" href="/weekly-plans">
                  週間予定表
                </Link>
              </MenuItem>,
            ]}
            {authorities.includes(CUSTOMER) && (
              <MenuItem onClick={handleClose} key="menuitem-reservation-list-link">
                <Link id="berth-reservation-link" href="/reservation-list">
                  納品作業予約一覧
                </Link>
              </MenuItem>
            )}
          </Menu>
        </>
      )}
      <Button
        id="basic-button-stock"
        aria-controls={open.stock ? 'basic-menu-stock' : undefined}
        aria-haspopup="true"
        aria-expanded={open.stock ? 'true' : undefined}
        onClick={handleClick}
        sx={{ marginLeft: 2 }}
      >
        在庫管理
      </Button>
      <Menu
        id="basic-menu-stock"
        anchorEl={anchorEl}
        open={open.stock}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button-stock',
        }}
      >
        <MenuItem onClick={handleClose} key="menuitem-stocks-link">
          <Link id="stocks-link" href="/stocks">
            在庫一覧
          </Link>
        </MenuItem>
        {authorities.includes(WAREHOUSE_USER) && [
          <MenuItem onClick={handleClose} key="menuitem-purchase-link">
            <Link id="purchase-orders-link" href="/purchase-orders">
              発注/入荷一覧
            </Link>
          </MenuItem>,
          <MenuItem onClick={handleClose} key="menuitem-received-link">
            <Link id="received-orders-link" href="/received-orders">
              受注/在庫引当一覧
            </Link>
          </MenuItem>,
          <MenuItem onClick={handleClose} key="menuitem-shipments-link">
            <Link id="shipments-link" href="/shipments">
              出荷一覧
            </Link>
          </MenuItem>,
        ]}
      </Menu>
    </>
  );
};

export default MainMenu;
