import React, { useContext, useState, useEffect } from 'react';
import { Dialog, DialogContent, Grid, TextField } from '@mui/material';

import type { Warehouse } from './types';
import type { InputFields, ErrorMessages } from '../types';
import MasterManageDialogHeaderButtons from '../master_manage_dialog_header_buttons';
import { apiContext } from '../../hooks/call_apis';

const WarehouseManageDialog: React.FC<{
  setAlertMessage: CallableFunction;
  handleCloseDialog: () => void;
  open: boolean;
  updateTargetID: string | null;
  setUpdateTargetID: CallableFunction;
}> = ({ setAlertMessage, handleCloseDialog, open, updateTargetID, setUpdateTargetID }) => {
  const [warehouseInputs, setWarehouseInputs] = useState<InputFields>({
    warehouse_code: '',
    warehouse_name: '',
    warehouse_postal_number: '',
    warehouse_address: '',
    warehouse_tel: '',
    warehouse_fax: '',
  });
  const [changed, setChanged] = useState<boolean>(false);

  const [warehouseError, setWarehouseError] = useState<ErrorMessages>({});

  const clearWarehouse = () => {
    setWarehouseInputs({
      warehouse_code: '',
      warehouse_name: '',
      warehouse_postal_number: '',
      warehouse_address: '',
      warehouse_tel: '',
      warehouse_fax: '',
    });
  };
  const clearWarehouseError = () => {
    setWarehouseError({
      warehouse_code: '',
      warehouse_name: '',
      warehouse_postal_number: '',
      warehouse_address: '',
      warehouse_tel: '',
      warehouse_fax: '',
    });
  };

  const ctx = useContext(apiContext);
  useEffect(() => {
    setChanged(false);
    if (updateTargetID) {
      ctx
        .getRecord<Warehouse>('/api/warehouses/', updateTargetID)
        .then((data) => {
          setWarehouseInputs({
            warehouse_code: data.warehouse_code,
            warehouse_name: data.warehouse_name,
            warehouse_postal_number: data.warehouse_postal_number,
            warehouse_address: data.warehouse_address,
            warehouse_tel: data.warehouse_tel,
            warehouse_fax: data.warehouse_fax,
          });
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      clearWarehouse();
    }
    clearWarehouseError();
  }, [ctx, updateTargetID, open]);

  return (
    <Dialog open={open} fullWidth maxWidth="xl">
      <DialogContent>
        <Grid container spacing={6}>
          <MasterManageDialogHeaderButtons
            url="/api/warehouses/"
            object={warehouseInputs}
            clearObjFunc={clearWarehouse}
            clearErrorFunc={clearWarehouseError}
            handleCloseDialog={handleCloseDialog}
            setAlertMessage={setAlertMessage}
            setErrorFunc={setWarehouseError}
            updateTargetID={updateTargetID}
            setUpdateTargetID={setUpdateTargetID}
            backConfirm={changed}
          />
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="warehouse_code-inputs"
                  label="倉庫コード"
                  type="text"
                  value={warehouseInputs.warehouse_code}
                  required
                  disabled={!!updateTargetID}
                  onChange={(event) => {
                    setWarehouseInputs({ ...warehouseInputs, warehouse_code: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={warehouseError.warehouse_code !== '' && warehouseError.warehouse_code !== undefined}
                  helperText={warehouseError.warehouse_code}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="warehouse_name-inputs"
                  label="倉庫名"
                  type="text"
                  value={warehouseInputs.warehouse_name}
                  required
                  onChange={(event) => {
                    setWarehouseInputs({ ...warehouseInputs, warehouse_name: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={warehouseError.warehouse_name !== '' && warehouseError.warehouse_name !== undefined}
                  helperText={warehouseError.warehouse_name}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="warehouse_postal_number-inputs"
                  label="郵便番号"
                  type="text"
                  value={warehouseInputs.warehouse_postal_number}
                  onChange={(event) => {
                    setWarehouseInputs({ ...warehouseInputs, warehouse_postal_number: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={
                    warehouseError.warehouse_postal_number !== '' &&
                    warehouseError.warehouse_postal_number !== undefined
                  }
                  helperText={warehouseError.warehouse_postal_number}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="warehouse_address-inputs"
                  label="住所"
                  type="text"
                  value={warehouseInputs.warehouse_address}
                  onChange={(event) => {
                    setWarehouseInputs({ ...warehouseInputs, warehouse_address: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={warehouseError.warehouse_address !== '' && warehouseError.warehouse_address !== undefined}
                  helperText={warehouseError.warehouse_address}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="warehouse_tel-inputs"
                  label="電話番号"
                  type="text"
                  value={warehouseInputs.warehouse_tel}
                  onChange={(event) => {
                    setWarehouseInputs({ ...warehouseInputs, warehouse_tel: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={warehouseError.warehouse_tel !== '' && warehouseError.warehouse_tel !== undefined}
                  helperText={warehouseError.warehouse_tel}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="warehouse_fax-inputs"
                  label="Fax番号"
                  type="text"
                  value={warehouseInputs.warehouse_fax}
                  onChange={(event) => {
                    setWarehouseInputs({ ...warehouseInputs, warehouse_fax: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={warehouseError.warehouse_fax !== '' && warehouseError.warehouse_fax !== undefined}
                  helperText={warehouseError.warehouse_fax}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default WarehouseManageDialog;
