import React, { useContext, useEffect, useState } from 'react';
import { Button, Dialog, DialogContent, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { AppRegistration } from '@mui/icons-material';
import { AxiosError } from 'axios';

import type { ErrorMessages } from '../types';
import type { Stock } from './types';
import { apiContext } from '../../hooks/call_apis';
import ConfirmationDialog from '../confirmation_dialog';
import BackButton from '../back_button';

const WasteDialog: React.FC<{
  handleCloseDialog: () => void;
  targetStock: Stock;
  open: boolean;
  setAlertMessage: CallableFunction;
}> = ({ handleCloseDialog, targetStock, open, setAlertMessage }) => {
  const [quantityVal, setQuantityVal] = useState<number | null>(null);
  const [remarksVal, setRemarksVal] = useState<string | null>(null);
  const [changed, setChanged] = useState<boolean>(false);

  const [quantityError, setQuantityError] = useState<string | null>(null);
  const [remarksError, setRemarksError] = useState<string | null>(null);
  const onClose = () => {
    handleCloseDialog();
  };
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  const message = <Typography>登録した廃棄内容は変更できません。廃棄内容を登録しますか。</Typography>;

  const onSubmit = () => {
    setOpenConfirmDialog(true);
  };

  useEffect(() => {
    setQuantityError(null);
    setRemarksVal(null);
    setQuantityVal(null);
    setRemarksVal(null);
    setChanged(false);
  }, [open]);

  const ctx = useContext(apiContext);
  const submitWaste = () => {
    setQuantityError(null);
    setRemarksError(null);
    ctx
      .create('/api/stock/waste', {
        stock: targetStock.code,
        quantity: quantityVal,
        remarks: remarksVal,
      })
      .then(() => {
        setAlertMessage({
          open: true,
          message: '廃棄の登録に成功しました。',
          severity: 'success',
        });
        handleCloseDialog();
      })
      .catch((e: AxiosError<ErrorMessages>) => {
        if (e.response) {
          setQuantityError(e.response.data.quantity);
          setRemarksError(e.response.data.remarks);
        }
        setAlertMessage({
          open: true,
          message: '廃棄の登録に失敗しました。',
          severity: 'error',
        });
        console.error(e);
      });
    setOpenConfirmDialog(false);
  };

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item>
                  <BackButton onClose={onClose} requireConfirm={changed} />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={onSubmit}
                    startIcon={<AppRegistration />}
                    sx={{ width: 150 }}
                  >
                    廃棄
                  </Button>
                </Grid>
                <Grid item>
                  <Typography variant="h6">{`${targetStock.lot.code} ${targetStock.lot.product.name}`}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="廃棄数量"
                type="number"
                value={quantityVal ?? ''}
                onChange={(event) => {
                  setQuantityVal(Number(event.target.value));
                  setChanged(true);
                }}
                size="small"
                error={!!quantityError}
                helperText={quantityError}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">{targetStock.lot.product.stock_unit.name}</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="備考"
                value={remarksVal ?? ''}
                onChange={(event) => {
                  setRemarksVal(event.target.value);
                  setChanged(true);
                }}
                size="small"
                fullWidth
                error={!!remarksError}
                helperText={remarksError}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        message={message}
        open={openConfirmDialog}
        setOpenFunc={setOpenConfirmDialog}
        submitFunc={submitWaste}
      />
    </>
  );
};

export default WasteDialog;
