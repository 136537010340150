import React, { useContext, useEffect } from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { apiContext, useAPI } from './hooks/call_apis';
import HomePage from './pages/home';
import WarehousePage from './pages/berth/warehouses';
import BerthPage from './pages/berth/berths';
import ReservationSlotPage from './pages/berth/reservation_slots';
import CustomerPage from './pages/berth/customers';
import UserPage from './pages/accounts/users';
import OfficePage from './pages/stock/offices';
import DepartmentPage from './pages/stock/departments';
import StockUnitPage from './pages/stock/stock_units';
import ProductPage from './pages/stock/products';
import BerthReservationsPage from './pages/berth/berth_reservations';
import CustomerReservationListPage from './pages/berth/customer_reservation_list';
import PlanListPage from './pages/berth/plan_list';
import PurchaseOrderPage from './pages/stock/purchase_orders';
import ReceivedOrderPage from './pages/stock/received_orders';
import ShipmentPage from './pages/stock/shipments';
import StockPage from './pages/stock/stocks';
import LoginPage from './pages/accounts/login';
import NotFoundPage from './pages/not_found';

type Props = {
  component: React.ReactElement;
  redirect: string;
  permittedAuthorities?: string[];
};

const RequireAuth: React.FC<Props> = ({ component, redirect, permittedAuthorities = [] }) => {
  const apiCtx = useContext(apiContext);
  useEffect(() => {
    apiCtx.getValidAccessToken(redirect, permittedAuthorities);
  }, [apiCtx, redirect, permittedAuthorities]);

  return component;
};

const App = () => {
  const ctx = useAPI();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <apiContext.Provider value={ctx}>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/"
            element={
              <RequireAuth
                component={<HomePage />}
                redirect="/login"
                permittedAuthorities={['warehouse_user', 'customer', 'office']}
              />
            }
          />
          <Route
            path="/berths"
            element={
              <RequireAuth component={<BerthPage />} redirect="/login" permittedAuthorities={['warehouse_user']} />
            }
          />
          <Route
            path="/warehouses"
            element={
              <RequireAuth component={<WarehousePage />} redirect="/login" permittedAuthorities={['warehouse_user']} />
            }
          />
          <Route
            path="/reservation-slots"
            element={
              <RequireAuth
                component={<ReservationSlotPage />}
                redirect="/login"
                permittedAuthorities={['warehouse_user']}
              />
            }
          />
          <Route
            path="/customers"
            element={
              <RequireAuth component={<CustomerPage />} redirect="/login" permittedAuthorities={['warehouse_user']} />
            }
          />
          <Route
            path="/users"
            element={
              <RequireAuth component={<UserPage />} redirect="/login" permittedAuthorities={['warehouse_user']} />
            }
          />
          <Route
            path="/offices"
            element={
              <RequireAuth component={<OfficePage />} redirect="/login" permittedAuthorities={['warehouse_user']} />
            }
          />
          <Route
            path="/stock-units"
            element={
              <RequireAuth component={<StockUnitPage />} redirect="/login" permittedAuthorities={['warehouse_user']} />
            }
          />
          <Route
            path="/departments"
            element={
              <RequireAuth component={<DepartmentPage />} redirect="/login" permittedAuthorities={['warehouse_user']} />
            }
          />
          <Route
            path="/products"
            element={
              <RequireAuth component={<ProductPage />} redirect="/login" permittedAuthorities={['warehouse_user']} />
            }
          />
          <Route
            path="/berth-reservations"
            element={
              <RequireAuth
                component={<BerthReservationsPage />}
                redirect="/login"
                permittedAuthorities={['warehouse_user']}
              />
            }
          />
          <Route
            path="/weekly-plans"
            element={
              <RequireAuth component={<PlanListPage />} redirect="/login" permittedAuthorities={['warehouse_user']} />
            }
          />
          <Route
            path="/reservation-list"
            element={
              <RequireAuth
                component={<CustomerReservationListPage />}
                redirect="/login"
                permittedAuthorities={['customer']}
              />
            }
          />
          <Route
            path="/purchase-orders"
            element={
              <RequireAuth
                component={<PurchaseOrderPage />}
                redirect="/login"
                permittedAuthorities={['warehouse_user']}
              />
            }
          />
          <Route
            path="/received-orders"
            element={
              <RequireAuth
                component={<ReceivedOrderPage />}
                redirect="/login"
                permittedAuthorities={['warehouse_user']}
              />
            }
          />
          <Route
            path="/shipments"
            element={
              <RequireAuth component={<ShipmentPage />} redirect="/login" permittedAuthorities={['warehouse_user']} />
            }
          />
          <Route
            path="/stocks"
            element={
              <RequireAuth
                component={<StockPage />}
                redirect="/login"
                permittedAuthorities={['warehouse_user', 'customer', 'office']}
              />
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </apiContext.Provider>
    </LocalizationProvider>
  );
};
export default App;
