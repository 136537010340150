import React, { useEffect, useContext, useState } from 'react';
import { Grid } from '@mui/material';

import type { Warehouse, TempZone } from './types';
import { MultiSelectBox } from '../auto_complete_select_box';
import { apiContext } from '../../hooks/call_apis';

export const BerthsSearchBox: React.FC<{
  tempZoneVals: TempZone[];
  setTempZoneVals: CallableFunction;
  warehouseVals: Warehouse[];
  setWarehouseVals: CallableFunction;
}> = ({ tempZoneVals, setTempZoneVals, warehouseVals, setWarehouseVals }) => {
  const ctx = useContext(apiContext);
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const [tempZones, setTempZones] = useState<TempZone[]>([]);

  useEffect(() => {
    ctx
      .getRecordList<Warehouse>('/api/warehouses/', {})
      .then((data) => {
        setWarehouses(data);
      })
      .catch((e) => {
        console.error(e);
      });
    ctx
      .getRecordList<TempZone>('/api/temp-zones/', {})
      .then((data) => {
        setTempZones(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [ctx]);
  return (
    <Grid container direction="column" spacing={5}>
      <Grid item>
        <MultiSelectBox
          id="warehouse-search"
          items={warehouses}
          labelKey="warehouse_name"
          idKey="warehouse_code"
          boxLabel="倉庫"
          boxPlaceholder="倉庫を選択"
          itemVals={warehouseVals}
          setFunc={setWarehouseVals}
        />
      </Grid>
      <Grid item>
        <MultiSelectBox
          id="temp_zone-search"
          items={tempZones}
          labelKey="temp_name"
          idKey="temp_code"
          boxLabel="温度帯"
          boxPlaceholder="温度帯を選択"
          itemVals={tempZoneVals}
          setFunc={setTempZoneVals}
        />
      </Grid>
    </Grid>
  );
};

export default BerthsSearchBox;
