import React, { useContext, useState, useEffect } from 'react';
import { Backdrop, Button, CircularProgress, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import { AppRegistration, Print } from '@mui/icons-material';
import { AxiosError } from 'axios';

import type { AllocateItem, ReceivedOrderItem } from './types';
import type { ErrorMessages, InputFields } from '../types';
import { CommonTable } from '../common_table';
import { apiContext } from '../../hooks/call_apis';
import { getAmountFormat } from '../get_format';
import AddAllocateItemDialog from './add_allocate_item_dialog';
import BackButton from '../back_button';

const AllocateStockDialog: React.FC<{
  targetCode: string | null;
  setTargetCode: CallableFunction;
  shipFromCode: string | null;
  handleCloseDialog: () => void;
  open: boolean;
  setAlertMessage: CallableFunction;
}> = ({ targetCode, setTargetCode, shipFromCode, handleCloseDialog, open, setAlertMessage }) => {
  const [orderItems, setOrderItems] = useState<ReceivedOrderItem[]>([]);
  const [allocateInputs, setAllocateInputs] = useState<{ allocateItem: AllocateItem; input: InputFields }[]>([]);
  const [targetItemCode, setTargetItemCode] = useState<string | null>(null);
  const [changed, setChanged] = useState<boolean>(false);

  const [openAddAllocateDialog, setOpenAddAllocateDialog] = useState<boolean>(false);
  const [allocateInputsErrors, setAllocateInputsErrors] = useState<ErrorMessages[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const handleOpenAddAllocateDialog = () => {
    setOpenAddAllocateDialog(true);
  };

  const onClickDelete = (targetIndex: string) => {
    setAllocateInputs(
      allocateInputs.map((inputs) =>
        inputs.allocateItem.stock.lot.code === targetIndex
          ? {
              ...inputs,
              input: {
                ...inputs.input,
                deleted: true,
              },
            }
          : inputs
      )
    );
    setChanged(true);
  };

  const setQuantityToInputs = (val: number, rowIndex: number, params: { target: string }) => {
    setAllocateInputs(
      allocateInputs.map((inputs) =>
        inputs.allocateItem.stock.lot.code === params.target
          ? { ...inputs, input: { ...inputs.input, quantity: val } }
          : inputs
      )
    );
    setChanged(true);
  };

  const includeAllocated = orderItems.find((item) => !!item.allocated_quantity);
  const headers = [
    { header: '商品コード' },
    { header: '商品名', width: '30%' },
    { header: 'メーカー' },
    { header: '単位' },
    { header: '入数' },
    { header: '納品数' },
    { header: '引当済み数' },
    { header: '引当追加', columnType: 'Button', columnFunc: handleOpenAddAllocateDialog },
  ];

  const allocateHeaders = [
    { header: 'ロット' },
    { header: '賞味期限/消費期限' },
    { header: '引当可能在庫数' },
    { header: '引当数', columnType: 'TextField-number', columnFunc: setQuantityToInputs },
    { header: '削除', columnType: 'Button', columnFunc: onClickDelete },
  ];

  const rows = orderItems.map((item) => [
    { display: item.item.code, val: item.code },
    { display: item.item.name },
    { display: item.item.manufacturer },
    { display: item.item.stock_unit.name },
    { display: getAmountFormat(item.item.quantity?.toString() ?? '') },
    { display: getAmountFormat(item.delivery_quantity?.toString() ?? '0') },
    { display: getAmountFormat(item.allocated_quantity?.toString() ?? '0') },
    {
      display: item.is_shipped ? '出荷済み' : '引当追加',
      val: item.item.code,
      disabled: item.is_shipped,
    },
  ]);

  const notShipped = orderItems.filter((item) => !item.is_shipped).length > 0;

  const allocateRows = targetItemCode
    ? allocateInputs
        .filter((inputs) => inputs.input.order_item_code === targetItemCode && !inputs.input.deleted)
        .map((inputs) => [
          { display: inputs.allocateItem.stock.lot.code, val: inputs.allocateItem.stock.lot.code },
          { display: inputs.allocateItem.stock.lot.expiration_date },
          { display: inputs.allocateItem.stock.allocatable_quantity },
          {
            display: '',
            val: getAmountFormat(inputs.input.quantity?.toString() ?? '0'),
            params: { target: inputs.allocateItem.stock.lot.code },
            disabled: inputs.allocateItem.order_item?.is_shipped,
          },
          {
            display: inputs.allocateItem.shipped_quantity > 0 ? '-' : '削除',
            val: inputs.allocateItem.stock.lot.code,
            disabled: inputs.allocateItem.shipped_quantity > 0,
          },
        ])
    : [];

  const onClickItemRow = (code: string) => {
    setTargetItemCode(code);
  };

  const onClose = () => {
    handleCloseDialog();
  };

  const ctx = useContext(apiContext);

  const onSubmit = () => {
    ctx
      .create<InputFields>(
        '/api/stock/allocate-items',
        allocateInputs
          .filter((inputs) => inputs.input.update_code || !inputs.input.deleted)
          .map((inputs) => inputs.input)
      )
      .then(() => {
        setAlertMessage({
          open: true,
          message: '引当の更新に成功しました。',
          severity: 'success',
        });
        setTargetCode(null);
        handleCloseDialog();
      })
      .catch((e: AxiosError<ErrorMessages[]>) => {
        if (e.response) {
          setAllocateInputsErrors(e.response.data);
        }
        setAlertMessage({
          open: true,
          message: '登録に失敗しました。',
          severity: 'error',
        });
        console.error(e);
      });
  };

  useEffect(() => {
    setAllocateInputsErrors([]);
    setTargetItemCode(null);
    setChanged(false);
    if (targetCode) {
      ctx
        .getRecordList<ReceivedOrderItem>('/api/received-order-items/', { code: targetCode })
        .then((data) => {
          setOrderItems(data);
        })
        .catch((e) => {
          console.error(e);
        });
      ctx
        .getRecordList<AllocateItem>('/api/stock/allocate-items', { code: targetCode })
        .then((data) => {
          setAllocateInputs(
            data.map((item) => ({
              allocateItem: item,
              input: {
                update_code: item.code ?? null,
                order_item_code: item.order_item.code,
                stock_code: item.stock.code,
                quantity: item.quantity,
              },
            }))
          );
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [ctx, targetCode, open]);

  const itemErrors = allocateInputsErrors.map((item) => {
    if (typeof item === 'string') {
      return (
        <Grid item xs={12}>
          <Typography color="red">{item}</Typography>
        </Grid>
      );
    }
    return Object.keys(item).map((key) => (
      <Grid item xs={12}>
        <Typography color="red">{`${item[key] as string}`}</Typography>
      </Grid>
    ));
  });

  const print = () => {
    if (targetCode) {
      setLoading(true);
      ctx
        .getAPI('/api/stock/print-picking-list', { received_order_header: targetCode }, 'blob')
        .then((data) => {
          const downloadUrl = window.URL.createObjectURL(data as Blob);
          window.open(downloadUrl, '_blank');
        })
        .catch((e) => {
          setAlertMessage({
            open: true,
            message: 'ピッキングリストの出力に失敗しました。',
            severity: 'error',
          });
          console.error(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item>
                  <BackButton onClose={onClose} requireConfirm={changed} />
                </Grid>
                <Grid item>
                  {notShipped && (
                    <Button
                      variant="contained"
                      color="success"
                      onClick={onSubmit}
                      startIcon={<AppRegistration />}
                      sx={{ width: 150 }}
                    >
                      引当
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  {includeAllocated && (
                    <Button
                      variant="contained"
                      color="success"
                      startIcon={<Print />}
                      onClick={print}
                      sx={{ width: 200 }}
                    >
                      ピッキングリスト出力
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography> 引当を確認する受注明細を選択してください。 </Typography>
                  <CommonTable
                    columns={headers}
                    onClickRowFunc={onClickItemRow}
                    idColumn={0}
                    rows={rows}
                    emptyMsg="受注明細がありません。"
                  />
                </Grid>
                <Grid item xs={12}>
                  {itemErrors}
                  {targetItemCode && (
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="h5">引当明細一覧</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <CommonTable
                          columns={allocateHeaders}
                          idColumn={0}
                          rows={allocateRows}
                          emptyMsg="引当が行われていません。"
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <AddAllocateItemDialog
        allocateInputs={allocateInputs}
        setAllocateInputs={(itemInputs: { allocateItem: AllocateItem; input: InputFields }[]) => {
          setAllocateInputs(itemInputs);
          setChanged(true);
        }}
        handleCloseDialog={() => setOpenAddAllocateDialog(false)}
        orderItem={orderItems.find((item) => item.code === targetItemCode) ?? null}
        placeCode={shipFromCode}
        open={openAddAllocateDialog}
      />
      <Backdrop sx={{ color: '#fff', zIndex: 9999 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default AllocateStockDialog;
