import React, { forwardRef, ChangeEvent, useImperativeHandle, useState } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  TablePagination,
  TextField,
  Paper,
} from '@mui/material';

import type { Column, Cell } from './types';
import { StyledHeaderCell, StyledTableCell } from './styles';

export interface Handler {
  clearSelect: () => void;
  initializePage: () => void;
}
interface Props {
  columns: Column[];
  idColumn: number;
  rows: Cell[][];
  onClickRowFunc?: CallableFunction | null;
  width?: number | string;
  emptyMsg?: string;
  pagination?: boolean;
}
export const CommonTable = forwardRef<Handler, Props>(
  (
    {
      columns,
      idColumn,
      rows,
      onClickRowFunc = null,
      width = 1,
      emptyMsg = '検索条件に一致するデータが見つかりません。',
      pagination = true,
    },
    ref
  ) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selectedId, setSelectedId] = useState<string | number | null>(null);

    const widthVal = width;

    const initializePage = () => {
      setPage(0);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };

    const onSelect = (id: string | number) => {
      setSelectedId(id);
    };

    const clearSelect = () => {
      setSelectedId(null);
    };
    useImperativeHandle(ref, () => ({
      clearSelect,
      initializePage,
    }));

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const displayRows = pagination ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows;

    return (
      <TableContainer component={Paper}>
        <Table sx={{ width: widthVal }}>
          <TableHead sx={{ bgcolor: '#A0A0A0' }}>
            <TableRow>
              {columns.map((column) => (
                <StyledHeaderCell
                  key={`table-header-${column.header}`}
                  style={{ width: column.width ? column.width : '' }}
                >
                  {column.header}
                </StyledHeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length ? (
              displayRows.map((row, rowIndex) => {
                let bgColor = '#eeeeee';
                if (row[idColumn]?.val === selectedId) {
                  bgColor = '#ffdddd';
                } else if (rowIndex % 2 === 0) {
                  bgColor = '#e3e3e3';
                }
                return (
                  <TableRow
                    sx={{ bgcolor: bgColor }}
                    onClick={() => {
                      if (onClickRowFunc) {
                        onSelect(row[idColumn]?.val ?? '');
                        onClickRowFunc(row[idColumn]?.val);
                      }
                    }}
                    key={`table-row-${row[idColumn]?.val ?? ''}`}
                  >
                    {row.map((item, colIndex) => {
                      if (columns[colIndex].columnType === 'Button' && !item.disabled)
                        return (
                          <StyledTableCell
                            onClick={() => {
                              columns[colIndex].columnFunc?.(item.val);
                            }}
                            id={`${colIndex}_${rowIndex}-cell`}
                            sx={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                            key={`table-cell-${columns[colIndex].header}-${row[idColumn].val ?? ''}}`}
                          >
                            {item.display}
                          </StyledTableCell>
                        );
                      if (columns[colIndex].columnType?.startsWith('TextField')) {
                        let textType = 'text';
                        if (columns[colIndex].columnType?.endsWith('number')) {
                          textType = 'number';
                        } else if (columns[colIndex].columnType?.endsWith('date')) {
                          textType = 'date';
                        }
                        return (
                          <StyledTableCell
                            id={`${colIndex}_${rowIndex}-cell`}
                            key={`table-cell-${columns[colIndex].header}-${row[idColumn].val ?? ''}}`}
                          >
                            <TextField
                              sx={{
                                bgcolor: '#ffffff',
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                              }}
                              disabled={item.disabled}
                              value={item.val}
                              type={textType}
                              onChange={(event) => {
                                columns[colIndex].columnFunc?.(
                                  event.target.value,
                                  page * rowsPerPage + rowIndex,
                                  item.params
                                );
                              }}
                            />
                          </StyledTableCell>
                        );
                      }
                      return (
                        <StyledTableCell
                          id={`${colIndex}_${rowIndex}-cell`}
                          sx={{ color: row[colIndex].color ?? '' }}
                          key={`table-cell-${columns[colIndex].header}-${row[idColumn].val ?? ''}}`}
                        >
                          {item.display}
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <StyledTableCell colSpan={columns.length}>{emptyMsg}</StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {pagination && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="1ページ当たりの表示件数"
            sx={{ width: widthVal }}
          />
        )}
      </TableContainer>
    );
  }
);

export default CommonTable;
