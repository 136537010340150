import { createContext, useCallback, useState } from 'react';
import axios, { AxiosResponse, ResponseType } from 'axios';
import { useNavigate } from 'react-router-dom';

import type { Pagination } from '../components/types';
import type { Auth } from '../components/account/types';

type APIContext = {
  login: (username: string, password: string) => Promise<AxiosResponse>;
  logout: () => void;
  getValidAccessToken: (redirect: string, permittedAuthorities: string[]) => void;
  username: string;
  authorities: string[];
  getRecordList: <T>(url: string, params: { [name: string]: string | string[] }) => Promise<T[]>;
  getPaginationRecordList: <T>(
    url: string,
    pageVal: number,
    pageSizeVal: number,
    params: { [name: string]: string | string[] }
  ) => Promise<Pagination<T>>;
  getRecord: <T>(url: string, id: string) => Promise<T>;
  getAPI: <T>(url: string, params: { [name: string]: string | string[] }, responseType?: ResponseType) => Promise<T>;
  create: <T>(url: string, value: T | T[]) => Promise<AxiosResponse>;
  update: <T>(url: string, id: string, value: T) => Promise<AxiosResponse>;
  del: (url: string, id: string) => Promise<AxiosResponse>;
  postAPI: <T>(url: string, value: T, header_vals?: { [name: string]: string }) => Promise<AxiosResponse>;
};

const defaultContext: APIContext = {
  // 便宜上のcotextのため、eslintを一時的にoff
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  login: (_username: string, _password: string) => axios.get(''),
  logout: () => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getValidAccessToken: (_redirect: string, _permittedAuthorities: string[]) => {},
  username: '',
  authorities: [],
  getRecordList: async <T>() => {
    const res = await axios.get<T[]>('');
    return res.data;
  },
  getPaginationRecordList: async <T>() => {
    const res = await axios.get<Pagination<T>>('');
    return res.data;
  },
  getRecord: async <T>() => {
    const res = await axios.get<T>('');
    return res.data;
  },
  getAPI: async <T>() => {
    const res = await axios.get<T>('');
    return res.data;
  },
  create: async () => {
    const res = await axios.post('');
    return res;
  },
  update: async () => {
    const res = await axios.put('');
    return res;
  },
  del: async () => {
    const res = await axios.delete('');
    return res;
  },
  postAPI: async <T>() => {
    const res = await axios.post<T>('');
    return res;
  },
};

export const apiContext = createContext<APIContext>(defaultContext);

export const useAPI = (): APIContext => {
  const [username, setUsername] = useState<string>('');
  const [authorities, setAuthorities] = useState<string[]>([]);
  const navigate = useNavigate();

  const login = (name: string, pass: string): Promise<AxiosResponse> =>
    axios.post('/api/token/', {
      username: name,
      password: pass,
    });

  const logout = () => {
    axios.get('/api/token/invalidate/').catch(() => {});
  };

  const getValidAccessToken = useCallback(
    (redirect: string, permittedAuthorities: string[] = []) => {
      axios
        .post<Auth>('/api/token/verify/')
        .then(() => {
          axios
            .post<Auth>('/api/token/refresh/')
            .then((res) => {
              setUsername(res.data.username);
              setAuthorities(res.data.authority);
              if (permittedAuthorities.length !== 0) {
                const auths = permittedAuthorities.filter((auth) => res.data.authority.includes(auth));
                if (auths.length === 0) {
                  navigate(redirect);
                }
              }
            })
            .catch(() => {
              navigate(redirect);
            });
        })
        .catch(() => {
          navigate(redirect);
        });
    },
    [navigate]
  );

  const getRecordList = async <T>(url: string, paramsVal: { [name: string]: string | string[] }): Promise<T[]> => {
    getValidAccessToken('/login/', []);
    const res = await axios.get<T[]>(url, { params: paramsVal });
    return res ? res.data : [];
  };

  const getPaginationRecordList = async <T>(
    url: string,
    pageVal: number,
    pageSizeVal: number,
    paramsVal: { [name: string]: string | string[] }
  ) => {
    getValidAccessToken('/login/', []);
    const res = await axios.get<Pagination<T>>(url, {
      params: { ...paramsVal, page_size: pageSizeVal, page: pageVal },
    });
    return res.data;
  };

  const getRecord = async <T>(url: string, id: string): Promise<T> => {
    getValidAccessToken('/login/', []);
    const urlVal = url.slice(-1) === '/' ? url : `${url}/`;
    const res = await axios.get<T>(urlVal + id);
    return res.data;
  };

  const getAPI = async <T>(
    url: string,
    paramsVal: { [name: string]: string | string[] },
    resType: ResponseType = 'json'
  ): Promise<T> => {
    getValidAccessToken('/login/', []);
    const res = await axios.get<T>(url, { params: paramsVal, responseType: resType });
    return res.data;
  };

  const create = async <T>(url: string, value: T | T[]): Promise<AxiosResponse> => {
    getValidAccessToken('/login/', []);
    const res = await axios.post(url, value);
    return res;
  };

  const update = async <T>(url: string, id: string, value: T): Promise<AxiosResponse> => {
    getValidAccessToken('/login/', []);
    const urlVal = url.slice(-1) === '/' ? url : `${url}/`;
    const res = await axios.put(`${urlVal}${id}/`, value);
    return res;
  };

  const del = async (url: string, id: string): Promise<AxiosResponse> => {
    getValidAccessToken('/login/', []);
    const res = await axios.delete(url + id);
    return res;
  };

  const postAPI = async <T>(
    url: string,
    value: T,
    header_vals: { [name: string]: string } = {}
  ): Promise<AxiosResponse> => {
    getValidAccessToken('/login/', []);
    const res = await axios.post(url, value, { headers: header_vals });
    return res;
  };

  return {
    login,
    logout,
    getValidAccessToken,
    username,
    authorities,
    getRecord,
    getRecordList,
    getPaginationRecordList,
    getAPI,
    create,
    update,
    del,
    postAPI,
  };
};
