import React, { useCallback, useContext, useState, useEffect, useRef } from 'react';
import { Dialog, DialogContent, Grid, Typography } from '@mui/material';

import type { Product, StockHistory } from './types';
import type { Warehouse } from '../berth/types';
import { apiContext } from '../../hooks/call_apis';
import { getDatetimeFormat } from '../get_format';
import CommonPaginationTable, { RowsAndCount, Handler } from '../common_pagination_table';
import BackButton from '../back_button';

const StockHistoryDialog: React.FC<{
  handleCloseDialog: () => void;
  open: boolean;
  targetProduct: Product;
  targetWarehouse: Warehouse;
}> = ({ handleCloseDialog, open, targetProduct, targetWarehouse }) => {
  const ctx = useContext(apiContext);

  const [rows, setRows] = useState<RowsAndCount>({ count: 0, rows: [] });
  const onClose = () => {
    handleCloseDialog();
  };

  const columns = [
    { header: '日時' },
    { header: 'ロットID' },
    { header: '増減量' },
    { header: '変更理由' },
    { header: '備考' },
  ];

  const paginationTableRef = useRef({} as Handler);
  // CommonPaginationTableで呼び出すデータ取得
  const setRowsFunc = useCallback(
    (page: number, pageSize: number) => {
      ctx
        .getPaginationRecordList<StockHistory>('/api/stock/stock-histories', page, pageSize, {
          product: targetProduct.code,
          place: targetWarehouse.warehouse_code,
        })
        .then((data) => {
          const reasons: { [key: string]: string } = {
            arrival: '入荷',
            shipment: '出荷',
            inventory: '棚卸',
            waste: '廃棄',
          };
          setRows({
            count: data.count,
            rows: data.results.map((history) => [
              { display: getDatetimeFormat(new Date(history.date)), val: history.pk },
              { display: history.lot_code },
              { display: history.quantity },
              { display: reasons[history.reason] },
              { display: history.remarks },
            ]),
          });
        })
        .catch((e) => {
          console.error(e);
        });
    },
    [ctx, targetProduct, targetWarehouse]
  );

  useEffect(() => {
    if (open) {
      setRowsFunc(1, 5);
    }
  }, [open, setRowsFunc]);

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogContent>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item>
                <BackButton onClose={onClose} requireConfirm={false} />
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  {`${targetWarehouse.warehouse_name} ー ${targetProduct.name}（${targetProduct.code}）`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <CommonPaginationTable
              columns={columns}
              idColumn={0}
              rowsAndCount={rows}
              paginationAPIFunc={setRowsFunc}
              ref={paginationTableRef}
              emptyMsg="在庫履歴はありません。"
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default StockHistoryDialog;
