import React, { ChangeEvent, useCallback, useContext, useState, useEffect, useRef } from 'react';
import { Box, Button, Dialog, DialogContent, FormControl, Grid, Typography } from '@mui/material';
import { FileUpload } from '@mui/icons-material';
import { AxiosError } from 'axios';

import type { FileUploadHistory } from './types';
import type { Warehouse } from '../berth/types';
import type { ErrorMessages } from '../types';
import { apiContext } from '../../hooks/call_apis';
import { SelectBox } from '../auto_complete_select_box';
import { getDatetimeFormat } from '../get_format';
import CommonPaginationTable, { RowsAndCount, Handler } from '../common_pagination_table';
import BackButton from '../back_button';

const UploadReceivedOrderDialog: React.FC<{
  handleCloseDialog: () => void;
  open: boolean;
  setAlertMessage: CallableFunction;
}> = ({ handleCloseDialog, open, setAlertMessage }) => {
  const [shipFroms, setShipFroms] = useState<Warehouse[]>([]);
  const [shipFromVal, setShipFromVal] = useState<Warehouse | null>(null);
  const [changed, setChanged] = useState<boolean>(false);

  const ctx = useContext(apiContext);

  const [result, setResult] = useState<string | null>(null);
  const [shipFromError, setShipFromError] = useState<string | null>(null);
  const [uploadFileError, setUploadFileError] = useState<string[]>([]);
  const [uploadItemError, setUploadItemError] = useState<ErrorMessages[]>([]);

  const [uploadFile, setUploadFile] = useState<FileList | null>(null);
  const [rows, setRows] = useState<RowsAndCount>({ count: 0, rows: [] });
  const onClose = () => {
    handleCloseDialog();
  };

  const inputRef = useRef<HTMLInputElement>(null);
  const columns = [{ header: '日時' }, { header: '倉庫' }, { header: 'ファイル名' }, { header: '結果' }];

  const paginationTableRef = useRef({} as Handler);
  // CommonPaginationTableで呼び出すデータ取得
  const setRowsFunc = useCallback(
    (page: number, pageSize: number) => {
      ctx
        .getPaginationRecordList<FileUploadHistory>('/api/stock/file-upload-histories', page, pageSize, {})
        .then((data) => {
          setRows({
            count: data.count,
            rows: data.results.map((history) => [
              { display: getDatetimeFormat(new Date(history.created_at)), val: history.code },
              { display: history.warehouse.warehouse_name },
              { display: history.file_name },
              { display: history.result },
            ]),
          });
        })
        .catch((e) => {
          console.error(e);
        });
    },
    [ctx]
  );

  const itemErrors = uploadItemError.map((item, index) => {
    const fields: { [key: string]: string } = {
      office: '事業所',
      department: '部署',
      product: '商品',
      preferred_date: '納品希望日',
    };
    return Object.keys(item).map((key) => (
      <Grid item xs={12}>
        <Typography color="red">{`${index + 1}行目（${fields[key]}）:${item[key] as string}`}</Typography>
      </Grid>
    ));
  });

  const fileErrors = uploadFileError.map((item) => (
    <Grid item xs={12}>
      <Typography color="red">{item}</Typography>
    </Grid>
  ));

  const setErrorMessage = () => {
    setAlertMessage({
      open: true,
      message: '登録に失敗しました。',
      severity: 'error',
    });
  };

  const setWarningMessage = () => {
    setAlertMessage({
      open: true,
      message: '登録に失敗したデータが存在します。',
      severity: 'warning',
    });
  };

  const setSuccessMessage = () => {
    setAlertMessage({
      open: true,
      message: '登録に成功しました。',
      severity: 'success',
    });
  };

  const onSubmit = () => {
    setShipFromError(null);
    setUploadFileError([]);
    setUploadItemError([]);
    setResult(null);
    ctx
      .postAPI(
        '/api/stock/upload-received-order',
        {
          ship_from: shipFromVal?.warehouse_code ?? '',
          file: uploadFile ? uploadFile[0] : null,
        },
        { 'Content-Type': 'multipart/form-data' }
      )
      .then(() => {
        setSuccessMessage();
        handleCloseDialog();
      })
      .catch((e: AxiosError<{ ship_from: string; file?: string[]; items?: ErrorMessages[]; recorded_num: number }>) => {
        if (e.response) {
          setShipFromError(e.response.data.ship_from);
          setUploadFileError(e.response.data.file ?? []);
          if (e.response.data.items) {
            setUploadItemError(e.response.data.items);
            setResult(`登録成功件数：${e.response.data.recorded_num}件`);
            setWarningMessage();
          } else {
            setErrorMessage();
          }
        }
        setRowsFunc(1, 5);
        console.error(e);
      });
  };

  useEffect(() => {
    setShipFromVal(null);
    setUploadFile(null);
    setShipFromError(null);
    setUploadFileError([]);
    setUploadItemError([]);
    setResult(null);
    setChanged(false);

    ctx
      .getRecordList<Warehouse>('/api/warehouses/', {})
      .then((data) => {
        setShipFroms(data);
      })
      .catch((e) => {
        console.error(e);
      });
    setRowsFunc(1, 5);
  }, [ctx, setRowsFunc, open]);

  useEffect(() => {
    if (open) {
      setRowsFunc(1, 5);
    }
  }, [open, setRowsFunc]);

  const clickFileUploadButton = () => {
    inputRef.current?.click();
  };
  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogContent>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item>
                <BackButton onClose={onClose} requireConfirm={changed} />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="success"
                  onClick={onSubmit}
                  startIcon={<FileUpload />}
                  sx={{ width: 150 }}
                >
                  アップロード
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={5} alignItems="center">
              <Grid item xs={4}>
                <SelectBox
                  id="shipFrom-inputs"
                  items={shipFroms}
                  labelKey="warehouse_name"
                  idKey="warehouse_code"
                  boxLabel="倉庫"
                  boxPlaceholder=""
                  itemVal={shipFromVal}
                  setFunc={(value: Warehouse) => {
                    setShipFromVal(value);
                    setChanged(true);
                  }}
                  errorMsg={shipFromError}
                />
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={clickFileUploadButton} sx={{ width: 150 }}>
                  <FormControl>
                    ファイルを選択
                    <input
                      id="csv_file-inputs"
                      type="file"
                      accept=".csv,.CSV"
                      ref={inputRef}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setUploadFile(event.target.files);
                        setChanged(true);
                      }}
                      hidden
                    />
                  </FormControl>
                </Button>
              </Grid>
              <Grid item>
                <Typography>
                  {' '}
                  {uploadFile && uploadFile[0] ? uploadFile[0].name : 'ファイルが選択されていません'}{' '}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <CommonPaginationTable
              columns={columns}
              idColumn={0}
              rowsAndCount={rows}
              paginationAPIFunc={setRowsFunc}
              ref={paginationTableRef}
              emptyMsg="アップロード履歴はありません。"
            />
          </Grid>
          {fileErrors}
          {result && (
            <Grid item xs={12}>
              <Typography> {result} </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box
              sx={{
                height: 100,
                overflow: 'hidden',
                overflowY: 'scroll',
              }}
            >
              {itemErrors}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default UploadReceivedOrderDialog;
