import React, { useContext, useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, Grid, InputAdornment, TextField } from '@mui/material';
import { AppRegistration } from '@mui/icons-material';
import { AxiosError } from 'axios';

import type { InputFields, ErrorMessages } from '../types';
import type { Warehouse } from './types';
import ConfirmationDialog from '../confirmation_dialog';
import { apiContext } from '../../hooks/call_apis';
import { SelectBox } from '../auto_complete_select_box';
import BackButton from '../back_button';

const ReservationSlotBulkCreateDialog: React.FC<{
  setAlertMessage: CallableFunction;
  handleCloseDialog: () => void;
  open: boolean;
}> = ({ setAlertMessage, handleCloseDialog, open }) => {
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);

  const [warehouseVal, setWarehouseVal] = useState<Warehouse | null>(null);
  const [reservationSlotInputs, setReservationSlotInputs] = useState<InputFields>({});
  const [changed, setChanged] = useState<boolean>(false);

  const [reservationSlotInputsError, setReservationSlotInputsError] = useState<ErrorMessages>({});

  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const message = (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        一括登録を行うとこれまでの予約枠および週間予定表が削除されます。よろしいですか。
      </Grid>
    </Grid>
  );

  const clearReservationSlot = () => {
    setReservationSlotInputs({
      warehouse: '',
      start_time: '',
      end_time: '',
      duration: '',
    });
    setWarehouseVal(null);
  };

  const clearReservationSlotError = () => {
    setReservationSlotInputsError({
      warehouse: '',
      start_time: '',
      end_time: '',
      duration: '',
    });
  };

  const ctx = useContext(apiContext);
  useEffect(() => {
    clearReservationSlot();
    clearReservationSlotError();
    setChanged(false);
    ctx
      .getRecordList<Warehouse>('/api/warehouses/', {})
      .then((data) => {
        setWarehouses(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [ctx, open]);

  const submitReservationSlot = () => {
    ctx
      .postAPI<InputFields>('/api/berth/reservation-slot-bulk-create', {
        warehouse: reservationSlotInputs.warehouse,
        start_time: reservationSlotInputs.start_time,
        end_time: reservationSlotInputs.end_time,
        duration: reservationSlotInputs.duration,
      })
      .then(() => {
        setAlertMessage({
          open: true,
          message: '予約枠を一括登録しました。',
          severity: 'success',
        });
        handleCloseDialog();
        setOpenConfirmDialog(false);
      })
      .catch((e: AxiosError<ErrorMessages>) => {
        setAlertMessage({
          open: true,
          message: '登録に失敗しました。',
          severity: 'error',
        });
        setOpenConfirmDialog(false);
        if (e.response) {
          setReservationSlotInputsError(e.response.data);
        }
        console.error(e);
      });
  };

  const onClickSubmit = () => {
    setOpenConfirmDialog(true);
  };

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item>
                  <BackButton onClose={handleCloseDialog} requireConfirm={changed} />
                </Grid>
                <Grid item>
                  <Button
                    color="success"
                    variant="contained"
                    onClick={onClickSubmit}
                    startIcon={<AppRegistration />}
                    sx={{ width: 150 }}
                    key="button-create"
                  >
                    登録
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={5}>
                <Grid item xs={4}>
                  <SelectBox
                    id="warehouse-inputs"
                    items={warehouses}
                    labelKey="warehouse_name"
                    idKey="warehouse_code"
                    boxLabel="倉庫"
                    required
                    boxPlaceholder=""
                    itemVal={warehouseVal}
                    setFunc={(value: Warehouse) => {
                      setReservationSlotInputs({ ...reservationSlotInputs, warehouse: value?.warehouse_code ?? '' });
                      setWarehouseVal(value);
                      setChanged(true);
                    }}
                    errorMsg={reservationSlotInputsError.warehouse}
                  />
                </Grid>
                <Grid item xs={8} />
                <Grid item xs={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="start_time-inputs"
                    label="開始時刻"
                    type="time"
                    value={reservationSlotInputs.start_time}
                    required
                    onChange={(event) => {
                      setReservationSlotInputs({ ...reservationSlotInputs, start_time: event.target.value });
                      setChanged(true);
                    }}
                    size="small"
                    error={!!reservationSlotInputsError.start_time}
                    helperText={reservationSlotInputsError.start_time}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="end_time-inputs"
                    label="終了時刻"
                    type="time"
                    value={reservationSlotInputs.end_time}
                    required
                    onChange={(event) => {
                      setReservationSlotInputs({ ...reservationSlotInputs, end_time: event.target.value });
                      setChanged(true);
                    }}
                    size="small"
                    error={!!reservationSlotInputsError.end_time}
                    helperText={reservationSlotInputsError.end_time}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="duration-inputs"
                    label="間隔"
                    type="number"
                    value={reservationSlotInputs.duration}
                    required
                    onChange={(event) => {
                      setReservationSlotInputs({ ...reservationSlotInputs, duration: event.target.value });
                      setChanged(true);
                    }}
                    size="small"
                    error={!!reservationSlotInputsError.duration}
                    helperText={reservationSlotInputsError.duration}
                    InputProps={{
                      endAdornment: <InputAdornment position="start">分</InputAdornment>,
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        message={message}
        open={openConfirmDialog}
        setOpenFunc={setOpenConfirmDialog}
        submitFunc={submitReservationSlot}
      />
    </>
  );
};

export default ReservationSlotBulkCreateDialog;
