import React, { useContext, useEffect, useState } from 'react';
import { Dialog, DialogContent, Grid, TextField } from '@mui/material';

import type { InputFields, ErrorMessages } from '../types';
import type { Department, Office } from './types';
import MasterManageDialogHeaderButtons from '../master_manage_dialog_header_buttons';
import { apiContext } from '../../hooks/call_apis';
import { SelectBox } from '../auto_complete_select_box';

const DepartmentManageDialog: React.FC<{
  setAlertMessage: CallableFunction;
  handleCloseDialog: () => void;
  open: boolean;
  updateTargetID: string | null;
  setUpdateTargetID: CallableFunction;
}> = ({ setAlertMessage, handleCloseDialog, open, updateTargetID, setUpdateTargetID }) => {
  const ctx = useContext(apiContext);
  const [departmentInputs, setDepartmentInputs] = useState<InputFields>({});
  const [officeVal, setOfficeVal] = useState<Office | null>(null);
  const [changed, setChanged] = useState<boolean>(false);

  const [departmentInputsError, setDepartmentInputsError] = useState<ErrorMessages>({});
  const [offices, setOffices] = useState<Office[]>([]);

  const clearDepartment = () => {
    setDepartmentInputs({
      code: '',
      name: '',
      kana: '',
      office_code: '',
    });
    setOfficeVal(null);
  };

  const clearDepartmentError = () => {
    setDepartmentInputsError({
      code: '',
      name: '',
      kana: '',
      office_code: '',
    });
  };

  useEffect(() => {
    ctx
      .getRecordList<Office>('/api/offices/', {})
      .then((data) => {
        setOffices(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [ctx]);

  useEffect(() => {
    clearDepartment();
    setChanged(false);
    if (updateTargetID) {
      ctx
        .getRecord<Department>('/api/departments/', updateTargetID)
        .then((data) => {
          setDepartmentInputs({
            code: data.code,
            name: data.name,
            kana: data.kana,
            office_code: data.office.code,
          });
          setOfficeVal(data.office);
        })
        .catch((e) => {
          console.error(e);
        });
    }
    clearDepartmentError();
  }, [ctx, updateTargetID, open]);

  return (
    <Dialog open={open} fullWidth maxWidth="xl">
      <DialogContent>
        <Grid container spacing={6}>
          <MasterManageDialogHeaderButtons
            url="/api/departments/"
            object={departmentInputs}
            clearObjFunc={clearDepartment}
            clearErrorFunc={clearDepartmentError}
            handleCloseDialog={handleCloseDialog}
            setAlertMessage={setAlertMessage}
            setErrorFunc={setDepartmentInputsError}
            updateTargetID={updateTargetID}
            setUpdateTargetID={setUpdateTargetID}
            backConfirm={changed}
          />
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="code-inputs"
                  label="部署コード"
                  type="text"
                  value={departmentInputs.code ?? ''}
                  required
                  disabled={!!updateTargetID}
                  onChange={(event) => {
                    setDepartmentInputs({ ...departmentInputs, code: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={!!departmentInputsError.code}
                  helperText={departmentInputsError.code}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="name-inputs"
                  label="部署名"
                  type="text"
                  value={departmentInputs.name ?? ''}
                  required
                  onChange={(event) => {
                    setDepartmentInputs({ ...departmentInputs, name: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={!!departmentInputsError.name}
                  helperText={departmentInputsError.name}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="kana-inputs"
                  label="部署名カナ"
                  type="text"
                  required
                  value={departmentInputs.kana ?? ''}
                  onChange={(event) => {
                    setDepartmentInputs({ ...departmentInputs, kana: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={!!departmentInputsError.kana}
                  helperText={departmentInputsError.kana}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <SelectBox
                  id="office-inputs"
                  items={offices}
                  labelKey="name"
                  idKey="code"
                  boxLabel="事業所"
                  required
                  boxPlaceholder=""
                  itemVal={officeVal}
                  setFunc={(value: Office) => {
                    setDepartmentInputs({ ...departmentInputs, office_code: value?.code ?? '' });
                    setOfficeVal(value);
                    setChanged(true);
                  }}
                  errorMsg={departmentInputsError.office_code}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DepartmentManageDialog;
