import React, { useContext, useEffect, useState } from 'react';
import { Dialog, DialogContent, Grid, TextField } from '@mui/material';

import type { InputFields, ErrorMessages } from '../types';
import type { StockUnit } from './types';
import MasterManageDialogHeaderButtons from '../master_manage_dialog_header_buttons';
import { apiContext } from '../../hooks/call_apis';

const StockUnitManageDialog: React.FC<{
  setAlertMessage: CallableFunction;
  handleCloseDialog: () => void;
  open: boolean;
  updateTargetID: string | null;
  setUpdateTargetID: CallableFunction;
}> = ({ setAlertMessage, handleCloseDialog, open, updateTargetID, setUpdateTargetID }) => {
  const ctx = useContext(apiContext);
  const [stockUnitInputs, setStockUnitInputs] = useState<InputFields>({});
  const [changed, setChanged] = useState<boolean>(false);

  const [stockUnitInputsError, setStockUnitInputsError] = useState<ErrorMessages>({});

  const clearStockUnit = () => {
    setStockUnitInputs({
      name: '',
    });
  };

  const clearStockUnitError = () => {
    setStockUnitInputsError({
      name: '',
    });
  };

  useEffect(() => {
    clearStockUnit();
    setChanged(false);
    if (updateTargetID) {
      ctx
        .getRecord<StockUnit>('/api/stock-units/', updateTargetID)
        .then((data) => {
          setStockUnitInputs({
            name: data.name,
          });
        })
        .catch((e) => {
          console.error(e);
        });
    }
    clearStockUnitError();
  }, [ctx, updateTargetID, open]);

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogContent>
        <Grid container spacing={6}>
          <MasterManageDialogHeaderButtons
            url="/api/stock-units/"
            object={stockUnitInputs}
            clearObjFunc={clearStockUnit}
            clearErrorFunc={clearStockUnitError}
            handleCloseDialog={handleCloseDialog}
            setAlertMessage={setAlertMessage}
            setErrorFunc={setStockUnitInputsError}
            updateTargetID={updateTargetID}
            setUpdateTargetID={setUpdateTargetID}
            updateDisabled
            backConfirm={changed}
          />
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{ shrink: true }}
              id="name-inputs"
              label="在庫単位表示"
              type="text"
              value={stockUnitInputs.name ?? ''}
              required
              disabled={!!updateTargetID}
              onChange={(event) => {
                setStockUnitInputs({ ...stockUnitInputs, name: event.target.value });
                setChanged(true);
              }}
              size="small"
              error={!!stockUnitInputsError.name}
              helperText={stockUnitInputsError.name}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default StockUnitManageDialog;
