import React from 'react';
import { Grid, TextField } from '@mui/material';

export const OfficesSearchBox: React.FC<{
  nameVal: string | null;
  setNameVal: CallableFunction;
  kanaVal: string | null;
  setKanaVal: CallableFunction;
}> = ({ nameVal, setNameVal, kanaVal, setKanaVal }) => (
  <Grid container direction="column" spacing={5}>
    <Grid item xs={12}>
      <TextField
        InputLabelProps={{ shrink: true }}
        id="name-search"
        label="事業所名"
        type="text"
        value={nameVal ?? ''}
        onChange={(event) => {
          setNameVal(event.target.value);
        }}
        size="small"
        fullWidth
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        InputLabelProps={{ shrink: true }}
        id="kana-search"
        label="事業所名カナ"
        type="text"
        value={kanaVal ?? ''}
        onChange={(event) => {
          setKanaVal(event.target.value);
        }}
        size="small"
        fullWidth
      />
    </Grid>
  </Grid>
);

export default OfficesSearchBox;
