import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

import type { Cell } from '../../components/types';
import type { Customer } from '../../components/berth/types';
import { CommonTable, Handler } from '../../components/common_table';
import { MessageSnackbar, AlertMessage } from '../../components/message_snackbar';
import Header from '../../components/header';
import { CustomersSearchBox } from '../../components/berth/customers_search_box';
import CustomerManageDialog from '../../components/berth/customer_manage_dialog';
import { apiContext } from '../../hooks/call_apis';

const Buttons: React.FC<{
  handleOpenDialogFunc: CallableFunction;
  search: CallableFunction;
}> = ({ handleOpenDialogFunc, search }) => {
  const handleOpenCustomerDialog = () => {
    handleOpenDialogFunc();
  };
  const handleClickSearch = () => {
    search();
  };
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button id="search-customers" variant="contained" startIcon={<ManageSearchIcon />} onClick={handleClickSearch}>
          検索
        </Button>
      </Grid>
      <Grid item>
        <Button
          id="create-customer"
          variant="contained"
          color="success"
          startIcon={<AppRegistrationIcon />}
          onClick={handleOpenCustomerDialog}
        >
          新規登録
        </Button>
      </Grid>
    </Grid>
  );
};

const CustomerPage: React.FC = () => {
  const [openCustomerDialog, setOpenCustomerDialog] = useState<boolean>(false);
  const [updateCustomer, setUpdateCustomer] = useState<string | null>(null);

  const [rows, setRows] = useState<Cell[][]>([[]]);

  const ctx = useContext(apiContext);

  const [nameQuery, setNameQuery] = useState<string | null>(null);
  const [kanaQuery, setKanaQuery] = useState<string | null>(null);

  const tableRef = useRef({} as Handler);
  const getCustomerTable = () => {
    ctx
      .getRecordList<Customer>('/api/customers/', {
        name: nameQuery ?? '',
        kana: kanaQuery ?? '',
      })
      .then((data) => {
        setRows(
          data.map((customer) => [
            { display: customer.code, val: customer.code },
            { display: customer.name },
            { display: customer.kana },
            { display: customer.postal_number },
            { display: customer.address1 },
            { display: customer.address1_kana },
            { display: customer.address2 },
            { display: customer.address2_kana },
            { display: customer.tel },
            { display: customer.fax },
          ])
        );
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const search = () => {
    getCustomerTable();
    tableRef.current?.initializePage();
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseDialog = () => {
    setOpenCustomerDialog(false);
    getCustomerTable();
  };

  const handleOpenCustomerDialog = (targetCode?: string) => {
    if (targetCode !== undefined) setUpdateCustomer(targetCode);
    else setUpdateCustomer(null);
    setOpenCustomerDialog(true);
  };

  const [alertMessage, setAlertMessage] = useState<AlertMessage>({ open: false, message: '', severity: 'success' });

  const columns = [
    { header: '取引先コード', columnType: 'Button', columnFunc: handleOpenCustomerDialog },
    { header: '取引先名' },
    { header: '取引先名カナ' },
    { header: '郵便番号' },
    { header: '住所1' },
    { header: '住所1カナ' },
    { header: '住所2' },
    { header: '住所2カナ' },
    { header: '電話番号' },
    { header: 'FAX番号' },
  ];

  return (
    <>
      <MessageSnackbar alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
      <Header screenName="取引先マスタ" />
      <Box sx={{ margin: '2%' }}>
        <Grid container justifyContent="center" spacing={6}>
          <Grid item xs={12}>
            <Buttons handleOpenDialogFunc={handleOpenCustomerDialog} search={search} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={6}>
              <Grid item xs={4}>
                <CustomersSearchBox
                  nameVal={nameQuery}
                  setNameVal={setNameQuery}
                  kanaVal={kanaQuery}
                  setKanaVal={setKanaQuery}
                />
              </Grid>
              <Grid item xs={8}>
                <CommonTable columns={columns} idColumn={0} rows={rows} width={1250} ref={tableRef} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <CustomerManageDialog
        setAlertMessage={setAlertMessage}
        handleCloseDialog={() => handleCloseDialog()}
        open={openCustomerDialog}
        updateTargetID={updateCustomer}
        setUpdateTargetID={setUpdateCustomer}
      />
    </>
  );
};

export default CustomerPage;
