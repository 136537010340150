import React, { useContext, useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, Grid, InputAdornment, TextField } from '@mui/material';
import { AppRegistration } from '@mui/icons-material';

import type { Product } from './types';
import { apiContext } from '../../hooks/call_apis';
import { SelectBox } from '../auto_complete_select_box';
import BackButton from '../back_button';

const AddReceivedOrderItemDialog: React.FC<{
  orderItemList: { item: Product; ordered_quantity: number; delivery_quantity: number }[];
  setOrderItemList: CallableFunction;
  handleCloseDialog: () => void;
  open: boolean;
}> = ({ orderItemList, setOrderItemList, handleCloseDialog, open }) => {
  const [products, setProducts] = useState<Product[]>([]);

  const [itemVal, setItemVal] = useState<Product | null>(null);
  const [orderedQuantityVal, setOrderedQuantityVal] = useState<number | null>(null);
  const [deliveryQuantityVal, setDeliveryQuantityVal] = useState<number | null>(null);
  const [changed, setChanged] = useState<boolean>(false);

  const [itemError, setItemError] = useState<string | null>(null);
  const [orderedQuantityError, setOrderedQuantityError] = useState<string | null>(null);
  const [deliveryQuantityError, setDeliveryQuantityError] = useState<string | null>(null);

  const onClose = () => {
    handleCloseDialog();
  };

  const onSubmit = () => {
    setOrderedQuantityError(null);
    setDeliveryQuantityError(null);
    setItemError(null);

    let orderedQuantityErrorStr = null;
    let deliveryQuantityErrorStr = null;
    let itemErrorStr = null;

    if (orderedQuantityVal === null) {
      orderedQuantityErrorStr = 'この項目は必須です。';
    } else if (orderedQuantityVal <= 0) {
      orderedQuantityErrorStr = 'この値は0より大きくしてください。';
    }
    if (deliveryQuantityVal === null) {
      deliveryQuantityErrorStr = 'この項目は必須です。';
    } else if (deliveryQuantityVal <= 0) {
      deliveryQuantityErrorStr = 'この値は0より大きくしてください。';
    }
    if (!itemVal) {
      itemErrorStr = 'この項目は必須です。';
    }

    if (orderedQuantityErrorStr || deliveryQuantityErrorStr || itemErrorStr) {
      setOrderedQuantityError(orderedQuantityErrorStr);
      setDeliveryQuantityError(deliveryQuantityErrorStr);
      setItemError(itemErrorStr);
      return;
    }

    setOrderItemList([
      ...orderItemList,
      {
        item: itemVal,
        ordered_quantity: orderedQuantityVal,
        delivery_quantity: deliveryQuantityVal,
      },
    ]);
    handleCloseDialog();
  };

  const ctx = useContext(apiContext);
  useEffect(() => {
    setItemVal(null);
    setOrderedQuantityVal(null);
    setDeliveryQuantityVal(null);
    setItemError(null);
    setOrderedQuantityError(null);
    setDeliveryQuantityError(null);
    setChanged(false);
    ctx
      .getRecordList<Product>('/api/products/', {})
      .then((data) => {
        const productsInItem = orderItemList.map((item) => item.item.code);
        const productChoices = data.filter((product) => !productsInItem.includes(product.code));
        setProducts(productChoices);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [ctx, orderItemList, open]);

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogContent>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item>
                <BackButton onClose={onClose} requireConfirm={changed} />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="success"
                  onClick={onSubmit}
                  startIcon={<AppRegistration />}
                  sx={{ width: 150 }}
                >
                  追加
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item xs={4}>
                <SelectBox
                  id="product-input"
                  items={products}
                  labelKey="name"
                  idKey="code"
                  boxLabel="商品"
                  boxPlaceholder=""
                  itemVal={itemVal}
                  setFunc={(value: Product) => {
                    setItemVal(value);
                    setChanged(true);
                  }}
                  errorMsg={itemError}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="受注数"
                  type="number"
                  value={orderedQuantityVal ?? ''}
                  onChange={(event) => {
                    setOrderedQuantityVal(Number(event.target.value));
                    setChanged(true);
                  }}
                  size="small"
                  error={!!orderedQuantityError}
                  helperText={orderedQuantityError}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="納品数"
                  type="number"
                  value={deliveryQuantityVal ?? ''}
                  onChange={(event) => {
                    setDeliveryQuantityVal(Number(event.target.value));
                    setChanged(true);
                  }}
                  size="small"
                  error={!!deliveryQuantityError}
                  helperText={deliveryQuantityError}
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="start">{itemVal?.stock_unit.name}</InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddReceivedOrderItemDialog;
