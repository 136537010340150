import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { AppRegistration, ManageSearch } from '@mui/icons-material';

import type { Cell } from '../../components/types';
import type { ReservationSlot, Warehouse } from '../../components/berth/types';
import { CommonTable, Handler } from '../../components/common_table';
import { MessageSnackbar, AlertMessage } from '../../components/message_snackbar';
import Header from '../../components/header';
import ReservationSlotsSearchBox from '../../components/berth/reservation_slots_search_box';
import ReservationSlotManageDialog from '../../components/berth/reservation_slot_manage_dialog';
import ReservationSlotBulkCreateDialog from '../../components/berth/reservation_slot_bulk_create_dialog';
import { apiContext } from '../../hooks/call_apis';
import { getTimeFormat } from '../../components/get_format';

const Buttons: React.FC<{
  setOpenReservationSlotDialog: CallableFunction;
  setOpenBulkCreateDialog: CallableFunction;
  search: CallableFunction;
}> = ({ setOpenReservationSlotDialog, setOpenBulkCreateDialog, search }) => {
  const handleOpenReservationSlotDialog = () => {
    setOpenReservationSlotDialog();
  };
  const handleOpenBulkCreateDialog = () => {
    setOpenBulkCreateDialog();
  };
  const handleClickSearch = () => {
    search();
  };
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button id="search-berths" variant="contained" startIcon={<ManageSearch />} onClick={handleClickSearch}>
          検索
        </Button>
      </Grid>
      <Grid item>
        <Button
          id="create-reservation_slot"
          startIcon={<AppRegistration />}
          variant="contained"
          color="success"
          onClick={handleOpenReservationSlotDialog}
        >
          新規登録
        </Button>
      </Grid>
      <Grid item>
        <Button
          id="bulk-create-reservation_slot"
          startIcon={<AppRegistration />}
          variant="contained"
          color="success"
          onClick={handleOpenBulkCreateDialog}
        >
          一括登録
        </Button>
      </Grid>
    </Grid>
  );
};

const ReservationSlotPage: React.FC = () => {
  const [alertMessage, setAlertMessage] = useState<AlertMessage>({ open: false, message: '', severity: 'success' });

  const [warehouseQuery, setWarehouseQuery] = useState<Warehouse | null>(null);

  const [openReservationSlotDialog, setOpenReservationSlotDialog] = useState<boolean>(false);
  const [openBulkCreateDialog, setOpenBulkCreateDialog] = useState<boolean>(false);
  const [rows, setRows] = useState<Cell[][]>([[]]);
  const [updateReservationSlot, setUpdateReservationSlot] = useState<string>('');

  const ctx = useContext(apiContext);
  const tableRef = useRef({} as Handler);
  const getReservationSlotTable = () => {
    ctx
      .getRecordList<ReservationSlot>('/api/reservation-slots/', {
        warehouse: warehouseQuery ? warehouseQuery.warehouse_code : '',
      })
      .then((data) => {
        setRows(
          data.map((reservation_slot) => [
            {
              display: reservation_slot.warehouse.warehouse_name,
              val: reservation_slot.code,
            },
            { display: getTimeFormat(reservation_slot.start_time.toString()) },
            { display: getTimeFormat(reservation_slot.end_time.toString()) },
          ])
        );
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const search = () => {
    getReservationSlotTable();
    tableRef.current?.initializePage();
  };

  const handleCloseDialog = () => {
    setOpenReservationSlotDialog(false);
    setOpenBulkCreateDialog(false);
    getReservationSlotTable();
  };
  const handleOpenReservationSlotDialog = (targetCode?: string) => {
    if (targetCode !== undefined) setUpdateReservationSlot(targetCode);
    else setUpdateReservationSlot('');
    setOpenReservationSlotDialog(true);
  };
  const handleOpenBulkCreateDialog = () => {
    setOpenBulkCreateDialog(true);
  };
  const columns = [
    { header: '倉庫', columnType: 'Button', columnFunc: handleOpenReservationSlotDialog },
    { header: '開始時刻' },
    { header: '終了時刻' },
  ];

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MessageSnackbar alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
      <Header screenName="バース予約枠マスタ" />
      <Box sx={{ margin: '2%' }}>
        <Grid container justifyContent="center" spacing={6}>
          <Grid item xs={12}>
            <Buttons
              setOpenReservationSlotDialog={handleOpenReservationSlotDialog}
              setOpenBulkCreateDialog={handleOpenBulkCreateDialog}
              search={search}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={6}>
              <Grid item xs={4}>
                <ReservationSlotsSearchBox warehouseVal={warehouseQuery} setWarehouseVal={setWarehouseQuery} />
              </Grid>
              <Grid item xs={8}>
                <CommonTable columns={columns} idColumn={0} rows={rows} ref={tableRef} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <ReservationSlotManageDialog
        setAlertMessage={setAlertMessage}
        handleCloseDialog={() => handleCloseDialog()}
        open={openReservationSlotDialog}
        updateTargetID={updateReservationSlot}
        setUpdateTargetID={setUpdateReservationSlot}
      />
      <ReservationSlotBulkCreateDialog
        setAlertMessage={setAlertMessage}
        handleCloseDialog={() => handleCloseDialog()}
        open={openBulkCreateDialog}
      />
    </>
  );
};

export default ReservationSlotPage;
