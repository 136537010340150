import React, { useContext, useEffect, useState } from 'react';
import { Grid, TextField } from '@mui/material';

import type { Customer } from '../berth/types';
import { SelectBox } from '../auto_complete_select_box';
import { apiContext } from '../../hooks/call_apis';

export const UsersSearchBox: React.FC<{
  lastNameVal: string | null;
  setLastNameVal: CallableFunction;
  firstNameVal: string | null;
  setFirstNameVal: CallableFunction;
  customerVal: Customer | null;
  setCustomerVal: CallableFunction;
}> = ({ lastNameVal, setLastNameVal, firstNameVal, setFirstNameVal, customerVal, setCustomerVal }) => {
  const ctx = useContext(apiContext);
  const [customers, setCustomers] = useState<Customer[]>([]);

  useEffect(() => {
    ctx
      .getRecordList<Customer>('/api/customers/', {})
      .then((data) => {
        setCustomers(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [ctx]);

  return (
    <Grid container spacing={5}>
      <Grid item xs={6}>
        <TextField
          InputLabelProps={{ shrink: true }}
          id="last_name-search"
          label="氏名（姓）"
          type="text"
          value={lastNameVal ?? ''}
          onChange={(event) => {
            setLastNameVal(event.target.value);
          }}
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          InputLabelProps={{ shrink: true }}
          id="first_name-search"
          label="氏名（名）"
          type="text"
          value={firstNameVal ?? ''}
          onChange={(event) => {
            setFirstNameVal(event.target.value);
          }}
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <SelectBox
          id="customer-search"
          items={customers}
          labelKey="name"
          idKey="code"
          boxLabel="取引先"
          boxPlaceholder="取引先を選択"
          itemVal={customerVal}
          setFunc={setCustomerVal}
        />
      </Grid>
    </Grid>
  );
};

export default UsersSearchBox;
