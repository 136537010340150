import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

import type { BerthReservation, CalendarEvent, CalendarResource, Warehouse } from '../../components/berth/types';
import BerthReservationsSearchBox from '../../components/berth/berth_reservations_search_box';
import Header from '../../components/header';
import ReservationCalendar from '../../components/berth/reservation_calendar';
import { apiContext } from '../../hooks/call_apis';
import ReservationManageDialog from '../../components/berth/reservation_manage_dialog';
import { MessageSnackbar, AlertMessage } from '../../components/message_snackbar';

interface CalendarRange {
  SLOT_MIN: Date;
  SLOT_MAX: Date;
}

const Buttons: React.FC<{
  handleOpenDialogFunc: CallableFunction;
}> = ({ handleOpenDialogFunc }) => {
  const handleOpenDialog = () => {
    handleOpenDialogFunc();
  };
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button variant="contained" color="success" startIcon={<AppRegistrationIcon />} onClick={handleOpenDialog}>
          管理者用予約
        </Button>
      </Grid>
    </Grid>
  );
};

const BerthReservationsPage: React.FC = () => {
  const ctx = useContext(apiContext);

  const [openReservationDialog, setOpenReservationDialog] = useState<boolean>(false);

  const [calendarEvents, setCalendarEvents] = useState<CalendarEvent[]>([]);
  const [warehouseQuery, setWarehouseQuery] = useState<Warehouse[]>([]);
  const [calendarRange, setCalendarRange] = useState<CalendarRange | null>(null);

  const calendarResources: CalendarResource[] = warehouseQuery
    .filter((w) => w.berths)
    .flatMap((w) => w.berths)
    .map((b) => ({
      id: b.berth_code,
      warehouse: b.warehouse.warehouse_name,
      berth: b.berth_name,
    }));

  useEffect(() => {
    ctx
      .getRecordList<Warehouse>('/api/warehouses/', {})
      .then((data) => {
        setWarehouseQuery(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [ctx]);

  useEffect(() => {
    if (warehouseQuery.length !== 0) {
      ctx
        .getAPI<CalendarRange>('/api/berth/slot-range', {
          warehouse: warehouseQuery.map((w) => w.warehouse_code),
        })
        .then((data) => {
          setCalendarRange(data);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [ctx, warehouseQuery]);

  const today = new Date();
  const [calendarDate, setCalendarDate] = useState<Date>(today);
  const [updateReservation, setUpdateReservation] = useState<string | null>(null);

  const [alertMessage, setAlertMessage] = useState<AlertMessage>({ open: false, message: '', severity: 'success' });

  const getCalendarEvents = useCallback(() => {
    const dateStr = `${calendarDate.getFullYear()}-${calendarDate.getMonth() + 1}-${calendarDate.getDate()}`;
    ctx
      .getRecordList<BerthReservation>('/api/berth-reservations', {
        date: dateStr,
        warehouse: warehouseQuery.map((w) => w.warehouse_code),
      })
      .then((data) => {
        const eventList: CalendarEvent[] = data.map((berthReservation) => {
          let color = '';
          if (berthReservation.disabled) {
            color = 'gray';
          } else if (!berthReservation.is_scheduled_for_delay_notification) {
            color = 'red';
          }
          return {
            id: berthReservation.code,
            resourceId: berthReservation.berth.berth_code,
            title: berthReservation.disabled ? '利用不可' : berthReservation.customer?.name ?? '',
            start: `${berthReservation.reservation_date.toString()}T${berthReservation.start_time.toString()}`,
            end: `${berthReservation.reservation_date.toString()}T${berthReservation.end_time.toString()}`,
            backgroundColor: color,
            borderColor: color,
            textColor: '',
          };
        });
        setCalendarEvents(eventList);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [ctx, calendarDate, warehouseQuery]);

  const handleCloseDialog = () => {
    setOpenReservationDialog(false);
    getCalendarEvents();
  };

  const handleOpenReservationDialog = (targetCode?: string) => {
    if (targetCode !== undefined) setUpdateReservation(targetCode);
    else setUpdateReservation(null);
    setOpenReservationDialog(true);
  };

  useEffect(() => {
    getCalendarEvents();
  }, [getCalendarEvents]);

  return (
    <>
      <MessageSnackbar alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
      <Header screenName="バース予約状況" />
      <Box sx={{ margin: '2%' }}>
        <Grid container justifyContent="center" spacing={6}>
          <Grid item xs={12}>
            <Buttons handleOpenDialogFunc={handleOpenReservationDialog} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={6}>
              <Grid item xs={4}>
                <BerthReservationsSearchBox warehouseVals={warehouseQuery} setWarehouseFunc={setWarehouseQuery} />
              </Grid>
              <Grid item xs={8}>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <ReservationCalendar
                      events={calendarEvents}
                      resources={calendarResources}
                      setCalendarDate={setCalendarDate}
                      handleOpenDialogFunc={handleOpenReservationDialog}
                      minTime={calendarRange?.SLOT_MIN?.toString() ?? null}
                      maxTime={calendarRange?.SLOT_MAX?.toString() ?? null}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <ReservationManageDialog
        setAlertMessage={setAlertMessage}
        handleCloseDialog={handleCloseDialog}
        open={openReservationDialog}
        updateTargetID={updateReservation}
        setUpdateTargetID={setUpdateReservation}
      />
    </>
  );
};

export default BerthReservationsPage;
