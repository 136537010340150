import React, { Fragment, useCallback, useContext, useState, useEffect } from 'react';
import { Backdrop, Button, CircularProgress, Dialog, DialogContent, Grid, TextField, Typography } from '@mui/material';
import { AppRegistration, Print, ManageSearch } from '@mui/icons-material';
import { AxiosError } from 'axios';

import type { AllocateItem, ReceivedOrderHeader } from './types';
import type { Warehouse } from '../berth/types';
import type { Cell, InputFields, ErrorMessages } from '../types';
import { CommonTable } from '../common_table';
import { apiContext } from '../../hooks/call_apis';
import { getAmountFormat } from '../get_format';
import { SelectBox } from '../auto_complete_select_box';
import BackButton from '../back_button';

const BulkAllocateStockDialog: React.FC<{
  handleCloseDialog: () => void;
  open: boolean;
  setAlertMessage: CallableFunction;
}> = ({ handleCloseDialog, open, setAlertMessage }) => {
  const [orderHeaders, setOrderHeaders] = useState<ReceivedOrderHeader[]>([]);
  const [allocateInputs, setAllocateInputs] = useState<{ allocateItem: AllocateItem; input: InputFields }[]>([]);
  const [shipFromVal, setShipFromVal] = useState<Warehouse | null>(null);
  const [shipFroms, setShipFroms] = useState<Warehouse[]>([]);
  const [dateVal, setDateVal] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [printable, setPrintable] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const allocateColumns = [
    { header: '商品コード' },
    { header: '商品名' },
    { header: '納品数' },
    { header: 'ロット' },
    { header: '賞味期限/消費期限' },
    { header: '引当数' },
  ];

  const tableList = orderHeaders.map((header) => {
    const itemRows = header.received_order_items
      .filter((item) => !item.is_shipped)
      .map((item) => {
        let allocateQunatity = 0;
        const allocateRows: Cell[][] = allocateInputs
          .filter((inputs) => (inputs.input.order_item_code as string) === item.code)
          .map((inputs) => {
            allocateQunatity += Number(inputs.input.quantity ?? '0');
            return [
              { display: '', val: `${header.order_number}-${inputs.allocateItem.stock.lot.code}` },
              { display: '' },
              { display: '' },
              { display: inputs.allocateItem.stock.lot.code },
              { display: inputs.allocateItem.stock.lot.expiration_date },
              { display: getAmountFormat(inputs.input.quantity?.toString() ?? '0') },
            ];
          });

        const warningColor = allocateQunatity < item.delivery_quantity ? 'red' : '';
        const itemHeader: Cell[][] = [
          [
            {
              display: item.item.code,
              val: item.code,
              color: warningColor,
            },
            { display: item.item.name, color: warningColor },
            { display: getAmountFormat(item.delivery_quantity.toString()), color: warningColor },
            { display: '-' },
            { display: '-' },
            { display: `計 ${allocateQunatity}`, color: warningColor },
          ],
        ];
        return itemHeader.concat(allocateRows);
      });
    return (
      <Fragment key={header.order_number}>
        <Grid item xs={12}>
          <Typography>{`${header.order_number} ${header.department.office.name} ${header.department.name}`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <CommonTable
            columns={allocateColumns}
            idColumn={0}
            rows={itemRows.flat()}
            pagination={false}
            emptyMsg="未出荷の受注明細がありません。"
          />
        </Grid>
      </Fragment>
    );
  });

  const onClose = () => {
    handleCloseDialog();
  };

  const ctx = useContext(apiContext);

  useEffect(() => {
    setShipFromVal(null);
    setDateVal(null);
    setPrintable(false);
    setAllocateInputs([]);
    setOrderHeaders([]);
    setMessage(null);
    ctx
      .getRecordList<Warehouse>('/api/warehouses', {})
      .then((data) => {
        setShipFroms(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [ctx, open]);

  const getBulkAllocateList = useCallback(() => {
    if (dateVal && shipFromVal) {
      ctx
        .getRecordList<ReceivedOrderHeader>('/api/received-order-headers/', {
          ship_from: [shipFromVal?.warehouse_code ?? ''],
          preferred_date__gte: dateVal ?? '',
          preferred_date__lte: dateVal ?? '',
          page_size: '-1',
        })
        .then((data) => {
          setOrderHeaders(data);
        })
        .catch((e) => {
          console.error(e);
        });
      ctx
        .getRecordList<AllocateItem>('/api/stock/bulk-allocate-items', {
          date: dateVal,
          ship_from: shipFromVal.warehouse_code,
        })
        .then((data) => {
          setAllocateInputs(
            data.map((item) => ({
              allocateItem: item,
              input: {
                update_code: item.code ?? null,
                order_item_code: item.order_item.code,
                stock_code: item.stock.code,
                quantity: item.quantity,
              },
            }))
          );
        })
        .catch((e: AxiosError<ErrorMessages>) => {
          if (e.response) {
            setMessage(e.response.data.date);
          }
          setAlertMessage({
            open: true,
            message: '引当の取得に失敗しました。',
            severity: 'error',
          });

          console.error(e);
        });
    }
  }, [ctx, dateVal, shipFromVal, setAlertMessage]);

  const onClickSearch = () => {
    setMessage(null);
    setPrintable(false);
    getBulkAllocateList();
  };

  const onSubmit = () => {
    setPrintable(false);
    setMessage(null);
    ctx
      .create<InputFields>(
        '/api/stock/bulk-allocate-items',
        allocateInputs.map((inputs) => inputs.input)
      )
      .then(() => {
        setAlertMessage({
          open: true,
          message: '引当に成功しました。',
          severity: 'success',
        });
        setPrintable(true);
      })
      .catch((e: AxiosError<ErrorMessages>) => {
        if (e.response) {
          setMessage(e.response.data.general);
        }
        setAlertMessage({
          open: true,
          message: '引当に失敗しました。',
          severity: 'error',
        });
        console.error(e);
      })
      .finally(() => {
        getBulkAllocateList();
      });
  };

  const print = () => {
    if (dateVal && shipFromVal) {
      setLoading(true);
      ctx
        .getAPI('/api/stock/print-multi-picking-list', { date: dateVal, ship_from: shipFromVal.warehouse_code }, 'blob')
        .then((data) => {
          const downloadUrl = window.URL.createObjectURL(data as Blob);
          window.open(downloadUrl, '_blank');
        })
        .catch((e) => {
          setAlertMessage({
            open: true,
            message: 'ピッキングリストの出力に失敗しました。',
            severity: 'error',
          });
          console.error(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item>
                  <BackButton onClose={onClose} requireConfirm={orderHeaders.length > 0 && !printable} />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={onClickSearch}
                    startIcon={<ManageSearch />}
                    sx={{ width: 150 }}
                    disabled={!shipFromVal || !dateVal}
                  >
                    検索
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={onSubmit}
                    startIcon={<AppRegistration />}
                    sx={{ width: 150 }}
                    disabled={orderHeaders.length === 0}
                  >
                    引当
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={<Print />}
                    onClick={print}
                    sx={{ width: 200 }}
                    disabled={!printable}
                  >
                    ピッキングリスト出力
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography>一括引当を行う出荷元と納品希望日を選択して検索してください。</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <SelectBox
                    id="ship_from-inputs"
                    items={shipFroms}
                    labelKey="warehouse_name"
                    idKey="warehouse_code"
                    boxLabel="出荷元"
                    boxPlaceholder=""
                    itemVal={shipFromVal}
                    setFunc={(value: Warehouse) => setShipFromVal(value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="estimated_date_of_arrival"
                    label="納品希望日"
                    type="date"
                    value={dateVal ?? ''}
                    onChange={(event) => setDateVal(event.target.value)}
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ color: 'red' }}>{message}</Typography>
            </Grid>
            {tableList}
          </Grid>
        </DialogContent>
      </Dialog>
      <Backdrop sx={{ color: '#fff', zIndex: 9999 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default BulkAllocateStockDialog;
