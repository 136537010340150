import React, { useContext, useEffect, useState } from 'react';
import { Dialog, DialogContent, Grid, TextField } from '@mui/material';

import type { InputFields, ErrorMessages } from '../types';
import type { Customer } from './types';
import MasterManageDialogHeaderButtons from '../master_manage_dialog_header_buttons';
import { apiContext } from '../../hooks/call_apis';

const CustomerManageDialog: React.FC<{
  setAlertMessage: CallableFunction;
  handleCloseDialog: () => void;
  open: boolean;
  updateTargetID: string | null;
  setUpdateTargetID: CallableFunction;
}> = ({ setAlertMessage, handleCloseDialog, open, updateTargetID, setUpdateTargetID }) => {
  const ctx = useContext(apiContext);
  const [customerInputs, setCustomerInputs] = useState<InputFields>({});
  const [changed, setChanged] = useState<boolean>(false);

  const [customerInputsError, setCustomerInputsError] = useState<ErrorMessages>({});

  const clearCustomer = () => {
    setCustomerInputs({
      code: '',
      name: '',
      kana: '',
      postal_number: '',
      address1: '',
      address1_kana: '',
      address2: '',
      address2_kana: '',
      tel: '',
      fax: '',
    });
  };

  const clearCustomerError = () => {
    setCustomerInputsError({
      code: '',
      name: '',
      kana: '',
      postal_number: '',
      address1: '',
      address1_kana: '',
      address2: '',
      address2_kana: '',
      tel: '',
      fax: '',
    });
  };

  useEffect(() => {
    clearCustomer();
    setChanged(false);
    if (updateTargetID) {
      ctx
        .getRecord<Customer>('/api/customers/', updateTargetID)
        .then((data) => {
          setCustomerInputs({
            code: data.code,
            name: data.name,
            kana: data.kana,
            postal_number: data.postal_number,
            address1: data.address1,
            address1_kana: data.address1_kana,
            address2: data.address2,
            address2_kana: data.address2_kana,
            tel: data.tel,
            fax: data.fax,
          });
        })
        .catch((e) => {
          console.error(e);
        });
    }
    clearCustomerError();
  }, [ctx, updateTargetID, open]);

  return (
    <Dialog open={open} fullWidth maxWidth="xl">
      <DialogContent>
        <Grid container spacing={6}>
          <MasterManageDialogHeaderButtons
            url="/api/customers/"
            object={customerInputs}
            clearObjFunc={clearCustomer}
            clearErrorFunc={clearCustomerError}
            handleCloseDialog={handleCloseDialog}
            setAlertMessage={setAlertMessage}
            setErrorFunc={setCustomerInputsError}
            updateTargetID={updateTargetID}
            setUpdateTargetID={setUpdateTargetID}
            backConfirm={changed}
          />
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="code-inputs"
                  label="取引先コード"
                  type="text"
                  value={customerInputs.code ?? ''}
                  required
                  disabled={!!updateTargetID}
                  onChange={(event) => {
                    setCustomerInputs({ ...customerInputs, code: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={!!customerInputsError.code}
                  helperText={customerInputsError.code}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="name-inputs"
                  label="取引先名"
                  type="text"
                  value={customerInputs.name ?? ''}
                  required
                  onChange={(event) => {
                    setCustomerInputs({ ...customerInputs, name: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={!!customerInputsError.name}
                  helperText={customerInputsError.name}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="kana-inputs"
                  label="取引先名カナ"
                  type="text"
                  value={customerInputs.kana ?? ''}
                  onChange={(event) => {
                    setCustomerInputs({ ...customerInputs, kana: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={!!customerInputsError.kana}
                  helperText={customerInputsError.kana}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="postal_number-inputs"
                  label="郵便番号"
                  type="text"
                  value={customerInputs.postal_number ?? ''}
                  onChange={(event) => {
                    setCustomerInputs({ ...customerInputs, postal_number: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={!!customerInputsError.postal_number}
                  helperText={customerInputsError.postal_number}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="address1-inputs"
                  label="住所1"
                  type="text"
                  value={customerInputs.address1 ?? ''}
                  onChange={(event) => {
                    setCustomerInputs({ ...customerInputs, address1: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={!!customerInputsError.address1}
                  helperText={customerInputsError.address1}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="address1_kana-inputs"
                  label="住所1カナ"
                  type="text"
                  value={customerInputs.address1_kana ?? ''}
                  onChange={(event) => {
                    setCustomerInputs({ ...customerInputs, address1_kana: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={!!customerInputsError.address1_kana}
                  helperText={customerInputsError.address1_kana}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="address2-inputs"
                  label="住所2"
                  type="text"
                  value={customerInputs.address2 ?? ''}
                  onChange={(event) => {
                    setCustomerInputs({ ...customerInputs, address2: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={!!customerInputsError.address2}
                  helperText={customerInputsError.address2}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="address2_kana-inputs"
                  label="住所2カナ"
                  type="text"
                  value={customerInputs.address2_kana ?? ''}
                  onChange={(event) => {
                    setCustomerInputs({ ...customerInputs, address2_kana: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={!!customerInputsError.address2_kana}
                  helperText={customerInputsError.address2_kana}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="tel-inputs"
                  label="電話番号"
                  type="text"
                  value={customerInputs.tel ?? ''}
                  required
                  onChange={(event) => {
                    setCustomerInputs({ ...customerInputs, tel: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={!!customerInputsError.tel}
                  helperText={customerInputsError.tel}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="fax-inputs"
                  label="Fax番号"
                  type="text"
                  value={customerInputs.fax ?? ''}
                  onChange={(event) => {
                    setCustomerInputs({ ...customerInputs, fax: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={!!customerInputsError.fax}
                  helperText={customerInputsError.fax}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CustomerManageDialog;
