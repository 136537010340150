import React, { useContext, useEffect, useState } from 'react';
import { Grid, TextField } from '@mui/material';

import type { InputFields, SelectBoxItem } from '../types';
import type { Office, Product } from './types';
import type { Warehouse } from '../berth/types';
import { apiContext } from '../../hooks/call_apis';
import { MultiSelectBox, SelectBox } from '../auto_complete_select_box';

export const ReceivedOrderSearchBox: React.FC<{
  shipFromVals: Warehouse[];
  setShipFromVals: CallableFunction;
  productVals: Product[];
  setProductVals: CallableFunction;
  officeVals: Office[];
  setOfficeVals: CallableFunction;
  dateSearchVals: InputFields;
  setDateSearchVals: CallableFunction;
  isAllocatedVal: SelectBoxItem | null;
  setIsAllocatedVal: CallableFunction;
  isShippedVal: SelectBoxItem | null;
  setIsShippedVal: CallableFunction;
}> = ({
  shipFromVals,
  setShipFromVals,
  productVals,
  setProductVals,
  officeVals,
  setOfficeVals,
  dateSearchVals,
  setDateSearchVals,
  isAllocatedVal,
  setIsAllocatedVal,
  isShippedVal,
  setIsShippedVal,
}) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [shipFrom, setShipFrom] = useState<Warehouse[]>([]);
  const [offices, setOffices] = useState<Office[]>([]);
  const ctx = useContext(apiContext);
  useEffect(() => {
    ctx
      .getRecordList<Product>('/api/products/', {})
      .then((data) => {
        setProducts(data);
      })
      .catch((e) => {
        console.error(e);
      });
    ctx
      .getRecordList<Warehouse>('/api/warehouses/', {})
      .then((data) => {
        setShipFrom(data);
      })
      .catch((e) => {
        console.error(e);
      });
    ctx
      .getRecordList<Office>('/api/offices/', {})
      .then((data) => {
        setOffices(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [ctx]);
  return (
    <Grid container spacing={5}>
      <Grid item xs={6}>
        <TextField
          label="納品希望（以降）"
          type="date"
          value={dateSearchVals.preferred_date__gte ?? ''}
          onChange={(event) => {
            setDateSearchVals({ ...dateSearchVals, preferred_date__gte: event.target.value });
          }}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="納品希望（以前）"
          type="date"
          value={dateSearchVals.preferred_date__lte ?? ''}
          onChange={(event) => {
            setDateSearchVals({ ...dateSearchVals, preferred_date__lte: event.target.value });
          }}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <MultiSelectBox
          id="ship_from-search"
          items={shipFrom}
          labelKey="warehouse_name"
          idKey="warehouse_code"
          boxLabel="出荷元"
          boxPlaceholder="出荷元を選択"
          itemVals={shipFromVals}
          setFunc={setShipFromVals}
        />
      </Grid>
      <Grid item xs={12}>
        <MultiSelectBox
          id="product-search"
          items={products}
          labelKey="name"
          idKey="code"
          boxLabel="商品"
          boxPlaceholder="商品を選択"
          itemVals={productVals}
          setFunc={setProductVals}
        />
      </Grid>
      <Grid item xs={12}>
        <MultiSelectBox
          id="office-search"
          items={offices}
          labelKey="name"
          idKey="code"
          boxLabel="事業所"
          boxPlaceholder="事業所を選択"
          itemVals={officeVals}
          setFunc={setOfficeVals}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectBox<SelectBoxItem>
          id="is_allocated-search"
          items={[
            { name: '引当済み', code: 'True' },
            { name: '未引当', code: 'False' },
          ]}
          labelKey="name"
          idKey="code"
          boxLabel="引当状況"
          boxPlaceholder="引当状況を選択"
          itemVal={isAllocatedVal}
          setFunc={setIsAllocatedVal}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectBox<SelectBoxItem>
          id="is_shipped-search"
          items={[
            { name: '出荷済み', code: 'True' },
            { name: '未出荷', code: 'False' },
          ]}
          labelKey="name"
          idKey="code"
          boxLabel="出荷状況"
          boxPlaceholder="出荷状況を選択"
          itemVal={isShippedVal}
          setFunc={setIsShippedVal}
        />
      </Grid>
    </Grid>
  );
};

export default ReceivedOrderSearchBox;
