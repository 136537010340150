import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

import type { Cell } from '../../components/types';
import type { Department, Office } from '../../components/stock/types';
import { CommonTable, Handler } from '../../components/common_table';
import { MessageSnackbar, AlertMessage } from '../../components/message_snackbar';
import Header from '../../components/header';
import { DepartmentsSearchBox } from '../../components/stock/departments_search_box';
import DepartmentManageDialog from '../../components/stock/department_manage_dialog';
import { apiContext } from '../../hooks/call_apis';

const Buttons: React.FC<{
  handleOpenDialogFunc: CallableFunction;
  search: CallableFunction;
}> = ({ handleOpenDialogFunc, search }) => {
  const handleOpenDepartmentDialog = () => {
    handleOpenDialogFunc();
  };
  const handleClickSearch = () => {
    search();
  };
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button id="search-department" variant="contained" startIcon={<ManageSearchIcon />} onClick={handleClickSearch}>
          検索
        </Button>
      </Grid>
      <Grid item>
        <Button
          id="create-department"
          variant="contained"
          color="success"
          startIcon={<AppRegistrationIcon />}
          onClick={handleOpenDepartmentDialog}
        >
          新規登録
        </Button>
      </Grid>
    </Grid>
  );
};

const DepartmentPage: React.FC = () => {
  const [openDepartmentDialog, setOpenDepartmentDialog] = useState<boolean>(false);
  const [updateDepartment, setUpdateDepartment] = useState<string | null>(null);

  const [rows, setRows] = useState<Cell[][]>([[]]);

  const ctx = useContext(apiContext);

  const [officeQuery, setOfficeQuery] = useState<Office | null>(null);
  const [nameQuery, setNameQuery] = useState<string | null>(null);
  const [kanaQuery, setKanaQuery] = useState<string | null>(null);

  const tableRef = useRef({} as Handler);
  const getDepartmentTable = () => {
    ctx
      .getRecordList<Department>('/api/departments/', {
        office: officeQuery?.code ?? '',
        name: nameQuery ?? '',
        kana: kanaQuery ?? '',
      })
      .then((data) => {
        setRows(
          data.map((department) => [
            { display: department.code, val: department.code },
            { display: department.name },
            { display: department.kana },
            { display: department.office.name ?? '' },
          ])
        );
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const search = () => {
    getDepartmentTable();
    tableRef.current?.initializePage();
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseDialog = () => {
    setOpenDepartmentDialog(false);
    getDepartmentTable();
  };

  const handleOpenDepartmentDialog = (targetCode?: string) => {
    if (targetCode !== undefined) setUpdateDepartment(targetCode);
    else setUpdateDepartment(null);
    setOpenDepartmentDialog(true);
  };

  const [alertMessage, setAlertMessage] = useState<AlertMessage>({ open: false, message: '', severity: 'success' });

  const columns = [
    { header: '部署コード', columnType: 'Button', columnFunc: handleOpenDepartmentDialog },
    { header: '部署名' },
    { header: '部署名カナ' },
    { header: '事業所' },
  ];

  return (
    <>
      <MessageSnackbar alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
      <Header screenName="部署マスタ" />
      <Box sx={{ margin: '2%' }}>
        <Grid container justifyContent="center" spacing={6}>
          <Grid item xs={12}>
            <Buttons handleOpenDialogFunc={handleOpenDepartmentDialog} search={search} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={6}>
              <Grid item xs={4}>
                <DepartmentsSearchBox
                  nameVal={nameQuery}
                  setNameVal={setNameQuery}
                  kanaVal={kanaQuery}
                  setKanaVal={setKanaQuery}
                  officeVal={officeQuery}
                  setOfficeVal={setOfficeQuery}
                />
              </Grid>
              <Grid item xs={8}>
                <CommonTable columns={columns} idColumn={0} rows={rows} ref={tableRef} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <DepartmentManageDialog
        setAlertMessage={setAlertMessage}
        handleCloseDialog={() => handleCloseDialog()}
        open={openDepartmentDialog}
        updateTargetID={updateDepartment}
        setUpdateTargetID={setUpdateDepartment}
      />
    </>
  );
};

export default DepartmentPage;
