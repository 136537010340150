import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  TablePagination,
  TextField,
  Paper,
} from '@mui/material';

import type { Column, Cell } from './types';
import { StyledHeaderCell, StyledTableCell } from './styles';

interface Props {
  columns: Column[];
  idColumn: number;
  rowsAndCount: RowsAndCount;
  paginationAPIFunc: CallableFunction;
  onClickRowFunc?: CallableFunction;
  width?: number | string;
  emptyMsg?: string;
}
export interface Handler {
  initializePagination: () => void;
  updatePagination: () => void;
}
export interface RowsAndCount {
  rows: Cell[][];
  count: number;
}

const CommonPaginationTable = forwardRef<Handler, Props>(
  (
    {
      columns,
      idColumn,
      rowsAndCount,
      paginationAPIFunc,
      onClickRowFunc = null,
      width = 1,
      emptyMsg = '検索条件に一致するデータが見つかりません。',
    },
    ref
  ) => {
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [selectedId, setSelectedId] = useState<string | number | null>(null);

    const widthVal = width;

    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
      paginationAPIFunc(newPage + 1, pageSize);
      setSelectedId(null);
    };

    const initializePagination = () => {
      setPage(0);
      paginationAPIFunc(1, pageSize);
      setSelectedId(null);
    };
    const updatePagination = () => {
      paginationAPIFunc(page + 1, pageSize);
      setSelectedId(null);
    };

    useImperativeHandle(ref, () => ({
      initializePagination,
      updatePagination,
    }));

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setPageSize(parseInt(event.target.value, 10));
      setPage(0);
      paginationAPIFunc(1, parseInt(event.target.value, 10));
      setSelectedId(null);
    };

    const onSelect = (id: string | number) => {
      setSelectedId(id);
    };

    return (
      <TableContainer component={Paper}>
        <Table sx={{ width: widthVal }}>
          <TableHead sx={{ bgcolor: '#A0A0A0' }}>
            <TableRow>
              {columns.map((column) => (
                <StyledHeaderCell
                  key={`table-header-${column.header}`}
                  style={{ width: column.width ? column.width : '' }}
                >
                  {column.header}
                </StyledHeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsAndCount.rows.length ? (
              rowsAndCount.rows.map((row, rowIndex) => {
                let bgColor = '#eeeeee';
                if (row[idColumn]?.val === selectedId) {
                  bgColor = '#ffdddd';
                } else if (rowIndex % 2 === 0) {
                  bgColor = '#e3e3e3';
                }
                return (
                  <TableRow
                    sx={{ bgcolor: bgColor }}
                    onClick={() => {
                      if (onClickRowFunc) {
                        onSelect(row[idColumn]?.val ?? '');
                        onClickRowFunc(row[idColumn]?.val);
                      }
                    }}
                    key={`table-row-${row[idColumn]?.val ?? ''}`}
                  >
                    {row.map((item, colIndex) => {
                      if (columns[colIndex].columnType === 'Button' && !item.disabled)
                        return (
                          <StyledTableCell
                            onClick={() => {
                              columns[colIndex].columnFunc?.(item.val);
                            }}
                            id={`${colIndex}_${rowIndex}-cell`}
                            sx={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                            key={`table-cell-${columns[colIndex].header}-${row[idColumn].val ?? ''}}`}
                          >
                            {item.display}
                          </StyledTableCell>
                        );
                      if (columns[colIndex].columnType === 'TextField')
                        return (
                          <StyledTableCell
                            id={`${colIndex}_${rowIndex}-cell`}
                            key={`table-cell-${columns[colIndex].header}-${row[idColumn].val ?? ''}}`}
                          >
                            <TextField sx={{ bgcolor: '#ffffff' }} />
                          </StyledTableCell>
                        );
                      return (
                        <StyledTableCell
                          id={`${colIndex}_${rowIndex}-cell`}
                          sx={{ color: row[colIndex].color ?? '' }}
                          key={`table-cell-${columns[colIndex].header}-${row[idColumn].val ?? ''}}`}
                        >
                          {item.display}
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <StyledTableCell colSpan={columns.length}>{emptyMsg}</StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={rowsAndCount.count}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="1ページ当たりの表示件数"
          sx={{ width: widthVal }}
        />
      </TableContainer>
    );
  }
);

export default CommonPaginationTable;
