import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { ModeEdit, Visibility } from '@mui/icons-material';
import type {
  Customer,
  WeeklyPlanDict,
  WeeklyPlanUpdate,
  ReservationSlot,
  Warehouse,
} from '../../components/berth/types';
import PlanTable from '../../components/berth/plan_table';
import Header from '../../components/header';
import { PlanEditBox, PlanSearchBox } from '../../components/berth/plan_side_boxes';
import { apiContext } from '../../hooks/call_apis';

const Buttons: React.FC<{
  setIsEditMode: CallableFunction;
  setCustomerVal: CallableFunction;
  isEditMode: boolean;
  disabled: boolean;
}> = ({ setIsEditMode, setCustomerVal, isEditMode, disabled }) => {
  const onClickEdit = () => {
    setIsEditMode(!isEditMode);
    setCustomerVal(null);
  };
  const buttonName = isEditMode ? '閲覧モード' : '編集モード';
  const buttonIcon = isEditMode ? <Visibility /> : <ModeEdit />;
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button variant="contained" color="success" startIcon={buttonIcon} onClick={onClickEdit} disabled={disabled}>
          {buttonName}
        </Button>
      </Grid>
    </Grid>
  );
};

const PlanListPage: React.FC = () => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [customerVal, setCustomerVal] = useState<Customer | null>(null);
  const [reservationSlots, setReservationSlots] = useState<ReservationSlot[]>([]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [weeklyPlanDict, setWeeklyPlanDict] = useState<WeeklyPlanDict | null>(null);
  const [weeklyPlanUpdate, setWeeklyPlanUpdate] = useState<{ [code: string]: WeeklyPlanUpdate }>({});
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const [warehouseVal, setWarehouseVal] = useState<Warehouse | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const ctx = useContext(apiContext);

  const getWeeklyPlanDict = (val: Warehouse | null) => {
    if (val) {
      ctx
        .getAPI<WeeklyPlanDict>('/api/berth/weekly-plan-edit', {
          warehouse: val.warehouse_code,
        })
        .then((data) => {
          setWeeklyPlanDict(data);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const onSetWarehouse = (val: Warehouse) => {
    setWarehouseVal(val);
    if (val) {
      getWeeklyPlanDict(val);
      ctx
        .getRecordList<ReservationSlot>('/api/reservation-slots', {
          warehouse: val.warehouse_code,
        })
        .then((data) => {
          setReservationSlots(data);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  useEffect(() => {
    ctx
      .getRecordList<Customer>('/api/customers/', {})
      .then((data) => {
        setCustomers(data);
      })
      .catch((e) => {
        console.error(e);
      });
    ctx
      .getRecordList<Warehouse>('/api/warehouses/', {})
      .then((data) => {
        setWarehouses(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [ctx]);

  return (
    <>
      <Header screenName="週間予定表" />
      <Box sx={{ margin: '2%' }}>
        <Grid container justifyContent="center" spacing={6}>
          <Grid item xs={12}>
            <Buttons
              setIsEditMode={setIsEditMode}
              setCustomerVal={setCustomerVal}
              isEditMode={isEditMode}
              disabled={!warehouseVal}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={6}>
              <Grid item xs={3}>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <PlanSearchBox
                      warehouses={warehouses}
                      warehouseVal={warehouseVal}
                      onSetWarehouse={onSetWarehouse}
                      disabled={isEditMode}
                    />
                  </Grid>
                  {isEditMode && (
                    <Grid item xs={12}>
                      <PlanEditBox
                        customers={customers}
                        customerVal={customerVal}
                        weeklyPlanDict={weeklyPlanDict}
                        weeklyPlanUpdate={weeklyPlanUpdate}
                        setCustomerVal={setCustomerVal}
                        setWeeklyPlanUpdate={setWeeklyPlanUpdate}
                        updateWeeklyPlanDict={() => {
                          getWeeklyPlanDict(warehouseVal);
                        }}
                        setErrorMessage={setErrorMessage}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={9}>
                {warehouseVal ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography color="red">{errorMessage}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <PlanTable
                        reservationSlots={reservationSlots}
                        weeklyPlanDict={weeklyPlanDict}
                        weeklyPlanUpdate={weeklyPlanUpdate}
                        setWeeklyPlanUpdate={setWeeklyPlanUpdate}
                        editCustomer={customerVal}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  '倉庫を選択してください。'
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PlanListPage;
