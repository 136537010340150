import React, { useContext, useEffect, useState } from 'react';
import { Grid, TextField } from '@mui/material';

import type { Customer, Warehouse } from '../berth/types';
import type { Product } from './types';
import type { InputFields, SelectBoxItem } from '../types';
import { apiContext } from '../../hooks/call_apis';
import { MultiSelectBox, SelectBox } from '../auto_complete_select_box';

export const PerchaseOrderSearchBox: React.FC<{
  storeInVals: Warehouse[];
  setStoreInVals: CallableFunction;
  CustomerVals: Customer[];
  setCustomerVals: CallableFunction;
  productVals: Product[];
  setProductVals: CallableFunction;
  dateSearchVals: InputFields;
  setDateSearchVals: CallableFunction;
  arrivalCompletedVal: SelectBoxItem | null;
  setArrivalCompletedVal: CallableFunction;
}> = ({
  storeInVals,
  setStoreInVals,
  CustomerVals,
  setCustomerVals,
  productVals,
  setProductVals,
  dateSearchVals,
  setDateSearchVals,
  arrivalCompletedVal,
  setArrivalCompletedVal,
}) => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [storeIn, setStoreIn] = useState<Warehouse[]>([]);
  const [products, setProducts] = useState<Product[]>([]);

  const ctx = useContext(apiContext);
  useEffect(() => {
    ctx
      .getRecordList<Customer>('/api/stock/product-suppliers', {})
      .then((data) => {
        setCustomers(data);
      })
      .catch((e) => {
        console.error(e);
      });
    ctx
      .getRecordList<Warehouse>('/api/warehouses/', {})
      .then((data) => {
        setStoreIn(data);
      })
      .catch((e) => {
        console.error(e);
      });
    ctx
      .getRecordList<Product>('/api/products/', {})
      .then((data) => {
        setProducts(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [ctx]);
  return (
    <Grid container spacing={5}>
      <Grid item xs={6}>
        <TextField
          id="order_date__gte"
          label="発注日（以降）"
          type="date"
          value={dateSearchVals.order_datetime__gte ?? ''}
          onChange={(event) => {
            setDateSearchVals({ ...dateSearchVals, order_datetime__gte: event.target.value });
          }}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="order_date__lte"
          label="発注日（以前）"
          type="date"
          value={dateSearchVals.order_datetime__lte ?? ''}
          onChange={(event) => {
            setDateSearchVals({ ...dateSearchVals, order_datetime__lte: event.target.value });
          }}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="estimated_date_of_arrival__gte"
          label="入荷予定日（以降）"
          type="date"
          value={dateSearchVals.estimated_date_of_arrival__gte ?? ''}
          onChange={(event) => {
            setDateSearchVals({ ...dateSearchVals, estimated_date_of_arrival__gte: event.target.value });
          }}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="estimated_date_of_arrival__lte"
          label="入荷予定日（以前）"
          type="date"
          value={dateSearchVals.estimated_date_of_arrival__lte ?? ''}
          onChange={(event) => {
            setDateSearchVals({ ...dateSearchVals, estimated_date_of_arrival__lte: event.target.value });
          }}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <MultiSelectBox
          id="customer-search"
          items={customers}
          labelKey="name"
          idKey="code"
          boxLabel="取引先"
          boxPlaceholder="取引先を選択"
          itemVals={CustomerVals}
          setFunc={setCustomerVals}
        />
      </Grid>
      <Grid item xs={12}>
        <MultiSelectBox
          id="stock_in-search"
          items={storeIn}
          labelKey="warehouse_name"
          idKey="warehouse_code"
          boxLabel="入荷先"
          boxPlaceholder="入荷先を選択"
          itemVals={storeInVals}
          setFunc={setStoreInVals}
        />
      </Grid>
      <Grid item xs={12}>
        <MultiSelectBox
          id="product-search"
          items={products}
          labelKey="name"
          idKey="code"
          boxLabel="商品"
          boxPlaceholder="商品を選択"
          itemVals={productVals}
          setFunc={setProductVals}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectBox<SelectBoxItem>
          id="arrival_complated-search"
          items={[
            { name: '入荷済み', code: 'True' },
            { name: '未入荷', code: 'False' },
          ]}
          labelKey="name"
          idKey="code"
          boxLabel="入荷状況"
          boxPlaceholder="入荷状況を選択"
          itemVal={arrivalCompletedVal}
          setFunc={setArrivalCompletedVal}
        />
      </Grid>
    </Grid>
  );
};

export default PerchaseOrderSearchBox;
