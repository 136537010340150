import React, { useContext, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { AppRegistration, Delete } from '@mui/icons-material';
import { AxiosError } from 'axios';

import type { InputFields, ErrorMessages } from './types';
import { apiContext } from '../hooks/call_apis';
import ConfirmationDialog from './confirmation_dialog';
import BackButton from './back_button';

interface Props {
  url: string;
  object: InputFields;
  clearObjFunc: CallableFunction;
  clearErrorFunc: CallableFunction;
  handleCloseDialog: CallableFunction;
  setAlertMessage: CallableFunction;
  setErrorFunc: CallableFunction;
  updateTargetID: string | null;
  setUpdateTargetID: CallableFunction;
  extraButtons?: React.ReactNode[];
  deleteDisabled?: boolean;
  updateDisabled?: boolean;
  backConfirm?: boolean;
}

const MasterManageDialogHeaderButtons = ({
  url,
  object,
  clearObjFunc,
  clearErrorFunc,
  handleCloseDialog,
  setAlertMessage,
  setErrorFunc,
  updateTargetID,
  setUpdateTargetID,
  extraButtons = [],
  deleteDisabled = false,
  updateDisabled = false,
  backConfirm = true,
}: Props) => {
  const onClose = () => {
    handleCloseDialog();
  };

  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const message = <Typography>削除します。本当によろしいですか。</Typography>;

  const ctx = useContext(apiContext);
  const onUpdateSubmit = () => {
    if (updateTargetID && setUpdateTargetID)
      ctx
        .update(url, updateTargetID, object)
        .then(() => {
          setAlertMessage({
            open: true,
            message: '登録に成功しました。',
            severity: 'success',
          });
          clearObjFunc();
          clearErrorFunc();
          onClose();
          setUpdateTargetID('');
        })
        .catch((e: AxiosError<ErrorMessages>) => {
          if (e.response) {
            const d = e.response.data;
            setErrorFunc(d);
            setAlertMessage({
              open: true,
              message: '登録に失敗しました。',
              severity: 'error',
            });
            console.error(e);
          }
        })
        .finally(() => {});
  };

  const onCreateSubmit = () => {
    ctx
      .create<InputFields>(url, object)
      .then(() => {
        setAlertMessage({
          open: true,
          message: '登録に成功しました。',
          severity: 'success',
        });
        clearObjFunc();
        clearErrorFunc();
        onClose();
      })
      .catch((e: AxiosError<ErrorMessages>) => {
        if (e.response) {
          const d = e.response.data;
          setErrorFunc(d);
          setAlertMessage({
            open: true,
            message: '登録に失敗しました。',
            severity: 'error',
          });
        }
        console.error(e);
      })
      .finally(() => {});
  };

  const onDeleteSubmit = (id: string | null) => {
    if (id) {
      ctx
        .del(url, id)
        .then(() => {
          setAlertMessage({
            open: true,
            message: '削除に成功しました。',
            severity: 'success',
          });
          onClose();
        })
        .catch((e: AxiosError<ErrorMessages>) => {
          setAlertMessage({
            open: true,
            message: e.response?.data.general ?? '削除に失敗しました。',
            severity: 'error',
          });
        })
        .finally(() => {
          setOpenConfirmDialog(false);
        });
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item>
            <BackButton onClose={onClose} requireConfirm={backConfirm} />
          </Grid>
          {!updateTargetID ? (
            <Grid item>
              <Button
                id="create-master"
                variant="contained"
                color="success"
                onClick={onCreateSubmit}
                startIcon={<AppRegistration />}
                sx={{ width: 150 }}
              >
                新規登録
              </Button>
            </Grid>
          ) : (
            <>
              <Grid item>
                <Button
                  id="update-master"
                  variant="contained"
                  color="success"
                  onClick={onUpdateSubmit}
                  startIcon={<AppRegistration />}
                  sx={{ width: 150 }}
                  disabled={updateDisabled}
                >
                  更新
                </Button>
              </Grid>
              <Grid item>
                <Button
                  id="delete-master"
                  variant="contained"
                  color="error"
                  onClick={() => {
                    setOpenConfirmDialog(true);
                  }}
                  sx={{ width: 150 }}
                  startIcon={<Delete />}
                  disabled={deleteDisabled}
                >
                  削除
                </Button>
              </Grid>
            </>
          )}
          {extraButtons.map((button, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid item key={`grid-${index}`}>
              {button}
            </Grid>
          ))}
        </Grid>
      </Grid>
      <ConfirmationDialog
        message={message}
        open={openConfirmDialog}
        setOpenFunc={setOpenConfirmDialog}
        submitFunc={() => onDeleteSubmit(updateTargetID)}
      />
    </>
  );
};

export default MasterManageDialogHeaderButtons;
