import React, { useContext, useCallback, useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import { AppRegistration } from '@mui/icons-material';
import { AxiosError } from 'axios';

import type { Product, Stock } from './types';
import type { Warehouse } from '../berth/types';
import type { ErrorMessages, InputFields } from '../types';
import { apiContext } from '../../hooks/call_apis';
import { getAmountFormat } from '../get_format';
import { CommonTable } from '../common_table';
import AddInventoryDialog from './add_inventory_dialog';
import ConfirmationDialog from '../confirmation_dialog';
import BackButton from '../back_button';

const InventoryDialog: React.FC<{
  handleCloseDialog: () => void;
  open: boolean;
  targetProduct: Product;
  targetWarehouse: Warehouse;
  setAlertMessage: CallableFunction;
}> = ({ handleCloseDialog, open, targetProduct, targetWarehouse, setAlertMessage }) => {
  const ctx = useContext(apiContext);

  const [inventoryInputs, setInventoryInputs] = useState<{ stock: Stock; changed: boolean; input: InputFields }[]>([]);
  const [inventoryInputsErrors, setInventoryInputsErrors] = useState<ErrorMessages[]>([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [openAddInventoryDialog, setOpenAddInventoryDialog] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(false);

  const onClose = () => {
    handleCloseDialog();
    setOpenConfirmDialog(false);
  };

  const setQuantityToInputs = (val: number, rowIndex: number) => {
    setInventoryInputs(
      inventoryInputs.map((inputs, index) =>
        index === rowIndex ? { ...inputs, changed: true, input: { ...inputs.input, quantity: val } } : inputs
      )
    );
    setChanged(true);
  };

  const setRemarksToInputs = (val: string, rowIndex: number) => {
    setInventoryInputs(
      inventoryInputs.map((inputs, index) =>
        index === rowIndex ? { ...inputs, input: { ...inputs.input, remarks: val } } : inputs
      )
    );
    setChanged(true);
  };

  const message = <Typography>登録した棚卸内容は変更できません。棚卸内容を登録しますか。</Typography>;

  const submitInventory = () => {
    ctx
      .create<InputFields>(
        '/api/stock/inventory',
        inventoryInputs.map((inputs) => inputs.input)
      )
      .then(() => {
        setAlertMessage({
          open: true,
          message: '棚卸の登録に成功しました。',
          severity: 'success',
        });
        setInventoryInputs([]);
        onClose();
      })
      .catch((e: AxiosError<ErrorMessages[]>) => {
        if (e.response) {
          setInventoryInputsErrors(e.response.data);
        }
        setAlertMessage({
          open: true,
          message: '棚卸の登録に失敗しました。',
          severity: 'error',
        });
        setOpenConfirmDialog(false);
        console.error(e);
      });
  };

  const fields: { [key: string]: string } = {
    quantity: '確認数',
    remarks: '備考',
  };

  const itemErrors = inventoryInputsErrors.map((item, idx) =>
    Object.keys(item).map((key) => (
      <Grid item xs={12}>
        <Typography color="red">{`${idx + 1}行目（${fields[key]}）:${item[key] as string}`}</Typography>
      </Grid>
    ))
  );

  const onSubmit = () => {
    setOpenConfirmDialog(true);
  };

  const handleOpenAddInventoryDialog = () => {
    setOpenAddInventoryDialog(true);
  };

  const columns = [
    { header: 'ロット' },
    { header: '賞味期限/消費期限' },
    { header: '登録在庫数' },
    { header: '確認数', columnType: 'TextField-number', columnFunc: setQuantityToInputs },
    { header: '備考', columnType: 'TextField', columnFunc: setRemarksToInputs },
  ];

  const getStocks = useCallback(() => {
    ctx
      .getRecordList<Stock>('/api/stocks/', {
        product: targetProduct.code,
        place: targetWarehouse.warehouse_code,
        for_inventory: '',
      })
      .then((data) => {
        setInventoryInputs(
          data.map((stockVal) => ({
            stock: stockVal,
            changed: false,
            input: { stock: stockVal.code, quantity: stockVal.quantity, remarks: '' },
          }))
        );
      })
      .catch((e) => {
        console.error(e);
      });
  }, [ctx, targetProduct, targetWarehouse]);

  useEffect(() => {
    getStocks();
    setChanged(false);
  }, [getStocks, open]);

  const onCloseAddInventoryDialog = () => {
    setOpenAddInventoryDialog(false);
    getStocks();
  };

  const rows = inventoryInputs.map((inputs) => [
    { display: inputs.stock.lot.code, val: inputs.stock.lot.code },
    { display: inputs.stock.lot.expiration_date },
    { display: getAmountFormat(inputs.stock.quantity?.toString() ?? '0') },
    { display: '', val: getAmountFormat(inputs.input.quantity?.toString() ?? '0') },
    { display: '', val: (inputs.input.remarks as string) ?? '', disabled: !inputs.changed },
  ]);

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="md">
        <DialogContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item>
                  <BackButton onClose={onClose} requireConfirm={changed} />
                </Grid>
                <Grid item>
                  <Button
                    onClick={onSubmit}
                    color="success"
                    variant="contained"
                    startIcon={<AppRegistration />}
                    sx={{ width: 150 }}
                  >
                    棚卸登録
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={handleOpenAddInventoryDialog}
                    color="success"
                    variant="contained"
                    startIcon={<AppRegistration />}
                    sx={{ width: 200 }}
                  >
                    未登録在庫を登録
                  </Button>
                </Grid>
                <Grid item>
                  <Typography variant="h6">
                    {`${targetWarehouse.warehouse_name} ー ${targetProduct.name}（${targetProduct.code}）`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography>備考欄は確認数を変更した場合のみ入力可能です。</Typography>
              <CommonTable columns={columns} idColumn={0} rows={rows} emptyMsg="在庫が存在しません。" />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {itemErrors}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        message={message}
        open={openConfirmDialog}
        setOpenFunc={setOpenConfirmDialog}
        submitFunc={submitInventory}
      />
      <AddInventoryDialog
        handleCloseDialog={onCloseAddInventoryDialog}
        open={openAddInventoryDialog}
        targetProduct={targetProduct}
        targetWarehouse={targetWarehouse}
        setAlertMessage={setAlertMessage}
      />
    </>
  );
};

export default InventoryDialog;
