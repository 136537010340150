import React, { useContext, useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, Grid, InputAdornment, TextField } from '@mui/material';
import { AppRegistration } from '@mui/icons-material';

import type { InputFields } from '../types';
import type { PurchaseOrderItem } from './types';
import { apiContext } from '../../hooks/call_apis';
import { SelectBox } from '../auto_complete_select_box';
import BackButton from '../back_button';

const AddArrivalItemDialog: React.FC<{
  arrivalInputs: { orderItem: PurchaseOrderItem; input: InputFields }[];
  setArrivalInputs: CallableFunction;
  handleCloseDialog: () => void;
  order_number: string | null;
  open: boolean;
}> = ({ arrivalInputs, setArrivalInputs, handleCloseDialog, order_number, open }) => {
  const [orderItems, setOrderItems] = useState<{ name: string; code: string; orderItem: PurchaseOrderItem }[]>([]);

  const [itemVal, setItemVal] = useState<{ name: string; code: string; orderItem: PurchaseOrderItem } | null>(null);
  const [expirationDateVal, setExpirationDateVal] = useState<string | null>(null);
  const [quantityVal, setQuantityVal] = useState<number | null>(null);
  const [changed, setChanged] = useState<boolean>(false);

  const [itemError, setItemError] = useState<string | null>(null);
  const [quantityError, setQuantityError] = useState<string | null>(null);
  const [expirationDateError, setExpirationDateError] = useState<string | null>(null);
  const onClose = () => {
    handleCloseDialog();
  };

  const onSubmit = () => {
    setExpirationDateError(null);
    setQuantityError(null);
    setItemError(null);

    let quantityErrorStr = null;
    let itemErrorStr = null;
    let expirationDateErrorStr = null;

    if (quantityVal === null) {
      quantityErrorStr = 'この項目は必須です。';
    } else if (quantityVal <= 0) {
      quantityErrorStr = 'この値は0より大きくしてください。';
    }
    if (!itemVal) {
      itemErrorStr = 'この項目は必須です。';
    }
    if (!expirationDateVal) {
      expirationDateErrorStr = 'この項目は必須です。';
    } else if (new Date(new Date().toISOString().split('T')[0]) > new Date(expirationDateVal)) {
      expirationDateErrorStr = '過去の日付は設定できません。';
    }
    if (quantityErrorStr || itemErrorStr || expirationDateErrorStr) {
      setQuantityError(quantityErrorStr);
      setItemError(itemErrorStr);
      setExpirationDateError(expirationDateErrorStr);
      return;
    }

    setArrivalInputs([
      ...arrivalInputs,
      {
        orderItem: itemVal?.orderItem,
        input: {
          item_code: itemVal?.orderItem.code,
          expiration_date: expirationDateVal,
          quantity: quantityVal,
        },
      },
    ]);
    handleCloseDialog();
  };

  const ctx = useContext(apiContext);
  useEffect(() => {
    setItemVal(null);
    setQuantityVal(null);
    setExpirationDateVal(null);
    setChanged(false);
    if (order_number) {
      ctx
        .getRecordList<PurchaseOrderItem>('/api/purchase-order-items/', {
          code: order_number,
        })
        .then((data) => {
          setOrderItems(
            data
              .filter((item) => item.quantity > item.arrival_quantity)
              .map((item) => ({
                name: item.item.name,
                code: item.code,
                orderItem: item,
              }))
          );
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [ctx, order_number, open]);

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogContent>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item>
                <BackButton onClose={onClose} requireConfirm={changed} />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="success"
                  onClick={onSubmit}
                  startIcon={<AppRegistration />}
                  sx={{ width: 150 }}
                >
                  追加
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item xs={4}>
                <SelectBox
                  id="product-inputs"
                  items={orderItems}
                  labelKey="name"
                  idKey="code"
                  boxLabel="商品"
                  boxPlaceholder=""
                  itemVal={itemVal}
                  setFunc={(value: { name: string; code: string; orderItem: PurchaseOrderItem }) => {
                    setItemVal(value);
                    setChanged(true);
                  }}
                  errorMsg={itemError}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="賞味期限/消費期限"
                  type="date"
                  value={expirationDateVal ?? ''}
                  onChange={(event) => {
                    setExpirationDateVal(event.target.value);
                    setChanged(true);
                  }}
                  size="small"
                  error={!!expirationDateError}
                  helperText={expirationDateError}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="入荷数"
                  type="number"
                  value={quantityVal ?? ''}
                  onChange={(event) => {
                    setQuantityVal(Number(event.target.value));
                    setChanged(true);
                  }}
                  size="small"
                  error={!!quantityError}
                  helperText={quantityError}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">{itemVal?.orderItem.item.stock_unit.name}</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddArrivalItemDialog;
