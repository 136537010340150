import React from 'react';

import { Grid, Button, Box, Container } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import Logo from '../components/logo';

const NotFoundPage = () => (
  <Box sx={{ bgcolor: 'beige' }}>
    <Container>
      <Box
        alignItems="center"
        bgcolor="background.paper"
        borderRadius="12px"
        boxShadow="10"
        fontWeight="bold"
        margin="250px"
        width="800px"
      >
        <Grid container spacing={1} p="70px 70px">
          <Grid item xs={6}>
            <Logo />
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={1} pt="60px">
              <Grid item>
                <WarningAmberIcon />
                ページがみつかりません。
              </Grid>
              <Grid item>
                <Button variant="contained" href="/login">
                  ログイン画面へ
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  </Box>
);

export default NotFoundPage;
