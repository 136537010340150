import React from 'react';
import { Snackbar } from '@mui/material';
import Alert, { AlertColor } from '@mui/material/Alert';

export interface AlertMessage {
  open: boolean;
  message: string;
  severity: AlertColor;
}

export const MessageSnackbar: React.FC<{
  alertMessage: AlertMessage;
  setAlertMessage: CallableFunction;
}> = ({ alertMessage, setAlertMessage }) => {
  const onAlertClose = () => {
    setAlertMessage({
      open: false,
      message: '',
      severity: 'success',
    });
  };
  return (
    <Snackbar
      id="alert-message-snackbar"
      open={alertMessage.open}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={onAlertClose}
      autoHideDuration={3000}
      key="topright"
    >
      <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
    </Snackbar>
  );
};

export default MessageSnackbar;
