import React, { useContext, useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, Grid, InputAdornment, TextField } from '@mui/material';
import { AppRegistration } from '@mui/icons-material';

import type { Customer } from '../berth/types';
import type { Product } from './types';
import { apiContext } from '../../hooks/call_apis';
import { SelectBox } from '../auto_complete_select_box';
import BackButton from '../back_button';

const AddPurchaseOrderItemDialog: React.FC<{
  orderItemList: { item: Product; quantity: number }[];
  setOrderItemList: CallableFunction;
  handleCloseDialog: () => void;
  customer: Customer | null;
  open: boolean;
}> = ({ orderItemList, setOrderItemList, handleCloseDialog, customer, open }) => {
  const [products, setProducts] = useState<Product[]>([]);

  const [itemVal, setItemVal] = useState<Product | null>(null);
  const [quantityVal, setQuantityVal] = useState<number | null>(null);
  const [changed, setChanged] = useState<boolean>(false);

  const [itemError, setItemError] = useState<string | null>(null);
  const [quantityError, setQuantityError] = useState<string | null>(null);

  const onClose = () => {
    handleCloseDialog();
  };

  const onSubmit = () => {
    setQuantityError(null);
    setItemError(null);

    let quantityErrorStr = null;
    let itemErrorStr = null;
    if (quantityVal === null) {
      quantityErrorStr = 'この項目は必須です。';
    } else if (quantityVal <= 0) {
      quantityErrorStr = 'この値は0より大きくしてください。';
    }
    if (!itemVal) {
      itemErrorStr = 'この項目は必須です。';
    }

    if (quantityErrorStr || itemErrorStr) {
      setQuantityError(quantityErrorStr);
      setItemError(itemErrorStr);
      return;
    }
    setOrderItemList([
      ...orderItemList,
      {
        item: itemVal,
        quantity: quantityVal,
      },
    ]);
    handleCloseDialog();
  };

  const ctx = useContext(apiContext);
  useEffect(() => {
    setItemVal(null);
    setQuantityVal(null);
    setQuantityError(null);
    setItemError(null);
    setChanged(false);
    if (customer) {
      ctx
        .getRecordList<Product>('/api/products/', {
          customer: customer.code,
        })
        .then((data) => {
          const productsInItem = orderItemList.map((item) => item.item.code);
          const productChoices = data.filter((product) => !productsInItem.includes(product.code));
          setProducts(productChoices);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [ctx, orderItemList, customer, open]);

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogContent>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item>
                <BackButton onClose={onClose} requireConfirm={changed} />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="success"
                  onClick={onSubmit}
                  startIcon={<AppRegistration />}
                  sx={{ width: 150 }}
                >
                  追加
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <SelectBox
                  id="product-input"
                  items={products}
                  labelKey="name"
                  idKey="code"
                  boxLabel="商品"
                  boxPlaceholder=""
                  itemVal={itemVal}
                  setFunc={(value: Product) => {
                    setItemVal(value);
                    setChanged(true);
                  }}
                  errorMsg={itemError}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="quantity-input"
                  label="数量"
                  type="number"
                  value={quantityVal ?? ''}
                  onChange={(event) => {
                    setQuantityVal(Number(event.target.value));
                    setChanged(true);
                  }}
                  size="small"
                  error={!!quantityError}
                  helperText={quantityError}
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="start">{itemVal?.stock_unit.name}</InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddPurchaseOrderItemDialog;
