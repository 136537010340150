import React, { useContext, useState, useEffect } from 'react';
import { Dialog, DialogContent, Grid, TextField } from '@mui/material';

import type { InputFields, ErrorMessages } from '../types';
import type { ReservationSlot, Warehouse } from './types';
import MasterManageDialogHeaderButtons from '../master_manage_dialog_header_buttons';
import { apiContext } from '../../hooks/call_apis';
import { SelectBox } from '../auto_complete_select_box';

const ReservationManageSlotDialog: React.FC<{
  setAlertMessage: CallableFunction;
  handleCloseDialog: () => void;
  open: boolean;
  updateTargetID: string;
  setUpdateTargetID: CallableFunction;
}> = ({ setAlertMessage, handleCloseDialog, open, updateTargetID, setUpdateTargetID }) => {
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);

  const [warehouseVal, setWarehouseVal] = useState<Warehouse | null>(null);
  const [reservationSlotInputs, setReservationSlotInputs] = useState<InputFields>({});
  const [changed, setChanged] = useState<boolean>(false);

  const [reservationSlotInputsError, setReservationSlotInputsError] = useState<ErrorMessages>({});

  const clearReservationSlot = () => {
    setReservationSlotInputs({
      warehouse_code: '',
      start_time: '',
      end_time: '',
    });
    setWarehouseVal(null);
  };

  const clearReservationSlotError = () => {
    setReservationSlotInputsError({
      warehouse_code: '',
      start_time: '',
      end_time: '',
    });
  };

  const ctx = useContext(apiContext);
  useEffect(() => {
    ctx
      .getRecordList<Warehouse>('/api/warehouses/', {})
      .then((data) => {
        setWarehouses(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [ctx]);
  useEffect(() => {
    setChanged(false);
    if (updateTargetID) {
      ctx
        .getRecord<ReservationSlot>('/api/reservation-slots/', updateTargetID)
        .then((data) => {
          setReservationSlotInputs({
            warehouse_code: data.warehouse.warehouse_code,
            start_time: data.start_time,
            end_time: data.end_time,
          });
          setWarehouseVal(data.warehouse);
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      clearReservationSlot();
    }
    clearReservationSlotError();
  }, [ctx, warehouses, updateTargetID, open]);

  return (
    <Dialog open={open} fullWidth maxWidth="xl">
      <DialogContent>
        <Grid container spacing={6}>
          <MasterManageDialogHeaderButtons
            url="/api/reservation-slots/"
            object={reservationSlotInputs}
            clearObjFunc={clearReservationSlot}
            clearErrorFunc={clearReservationSlotError}
            handleCloseDialog={handleCloseDialog}
            setAlertMessage={setAlertMessage}
            setErrorFunc={setReservationSlotInputsError}
            updateTargetID={updateTargetID}
            setUpdateTargetID={setUpdateTargetID}
            backConfirm={changed}
          />
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item xs={4}>
                <SelectBox
                  id="warehouse-inputs"
                  items={warehouses}
                  labelKey="warehouse_name"
                  idKey="warehouse_code"
                  boxLabel="倉庫"
                  required
                  boxPlaceholder=""
                  itemVal={warehouseVal}
                  setFunc={(value: Warehouse) => {
                    setReservationSlotInputs({ ...reservationSlotInputs, warehouse_code: value?.warehouse_code ?? '' });
                    setWarehouseVal(value);
                    setChanged(true);
                  }}
                  errorMsg={reservationSlotInputsError.warehouse_code}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="start_time-inputs"
                  label="開始時刻"
                  type="time"
                  value={reservationSlotInputs.start_time}
                  required
                  onChange={(event) => {
                    setReservationSlotInputs({ ...reservationSlotInputs, start_time: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={!!reservationSlotInputsError.start_time}
                  helperText={reservationSlotInputsError.start_time}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="end_time-inputs"
                  label="終了時刻"
                  type="time"
                  value={reservationSlotInputs.end_time}
                  required
                  onChange={(event) => {
                    setReservationSlotInputs({ ...reservationSlotInputs, end_time: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={!!reservationSlotInputsError.end_time}
                  helperText={reservationSlotInputsError.end_time}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ReservationManageSlotDialog;
