import React, { useContext, useEffect, useState } from 'react';
import { Grid, TextField } from '@mui/material';

import type { Office } from './types';
import { SelectBox } from '../auto_complete_select_box';
import { apiContext } from '../../hooks/call_apis';

export const DepartmentsSearchBox: React.FC<{
  nameVal: string | null;
  setNameVal: CallableFunction;
  kanaVal: string | null;
  setKanaVal: CallableFunction;
  officeVal: Office | null;
  setOfficeVal: CallableFunction;
}> = ({ nameVal, setNameVal, kanaVal, setKanaVal, officeVal, setOfficeVal }) => {
  const ctx = useContext(apiContext);
  const [offices, setOffices] = useState<Office[]>([]);

  useEffect(() => {
    ctx
      .getRecordList<Office>('/api/offices/', {})
      .then((data) => {
        setOffices(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [ctx]);

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <TextField
          InputLabelProps={{ shrink: true }}
          id="name-search"
          label="部署名"
          type="text"
          value={nameVal ?? ''}
          onChange={(event) => {
            setNameVal(event.target.value);
          }}
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          InputLabelProps={{ shrink: true }}
          id="kana-search"
          label="部署名カナ"
          type="text"
          value={kanaVal ?? ''}
          onChange={(event) => {
            setKanaVal(event.target.value);
          }}
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <SelectBox
          id="office-search"
          items={offices}
          labelKey="name"
          idKey="code"
          boxLabel="事業所"
          boxPlaceholder="事業所を選択"
          itemVal={officeVal}
          setFunc={setOfficeVal}
        />
      </Grid>
    </Grid>
  );
};

export default DepartmentsSearchBox;
