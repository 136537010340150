import React, { useContext, useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, Grid, TextField, Typography } from '@mui/material';
import { AppRegistration } from '@mui/icons-material';
import { AxiosError } from 'axios';

import type { ReceivedOrderHeader, ShipmentHeader, ShipmentItem } from './types';
import type { ErrorMessages } from '../types';
import AddShipmentItemDialog from './add_shipment_item_dialog';
import { apiContext } from '../../hooks/call_apis';
import { CommonTable } from '../common_table';
import { getAmountFormat } from '../get_format';
import { SelectBox } from '../auto_complete_select_box';
import ConfirmationDialog from '../confirmation_dialog';
import BackButton from '../back_button';

interface ShipmentHeaderErrors {
  date: string;
  received_order_number: string;
  general: ErrorMessages[];
  no_item: string;
}

const ShipmentCompleteDialog: React.FC<{
  targetCode: string | null;
  setTargetCode: CallableFunction;
  setAlertMessage: CallableFunction;
  handleCloseDialog: () => void;
  open: boolean;
  is_shipped: boolean;
}> = ({ targetCode, setTargetCode, setAlertMessage, handleCloseDialog, open, is_shipped }) => {
  const [receivedOrderHeaders, setReceivedOrderHeaders] = useState<ReceivedOrderHeader[]>([]);

  const [dateVal, setDateVal] = useState<string | null>(null);
  const [receivedOrderHeaderVal, setReceivedOrderHeaderVal] = useState<ReceivedOrderHeader | null>(null);
  const [shipmentItemInputs, setShipmentItemInputs] = useState<
    {
      shipmentItem: ShipmentItem;
      input: { allocate_item_code: string; quantity: number };
    }[]
  >([]);
  const [changed, setChanged] = useState<boolean>(false);

  const [targetItemCode, setTargetItemCode] = useState<string | null>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [openAddShipmentItemDialog, setOpenAddShipmentItemDialog] = useState<boolean>(false);

  const [dateError, setDateError] = useState<string | null>(null);
  const [receivedOrderHeaderError, setReceivedOrderHeaderError] = useState<string | null>(null);
  const [shipmentItemInputsErrors, setShipmentItemInputsErrors] = useState<ErrorMessages[]>([]);

  const shipmentHeaderInputs = {
    received_order_number: receivedOrderHeaderVal?.order_number ?? '',
    date: dateVal,
    shipment_items: shipmentItemInputs.map((inputs) => inputs.input),
  };

  const shipmentRows = targetItemCode
    ? shipmentItemInputs
        .filter((inputs) => inputs.shipmentItem.allocate_item.order_item.code === targetItemCode)
        .map((inputs) => [
          { display: inputs.shipmentItem.allocate_item.stock.lot.code, val: inputs.input.allocate_item_code },
          { display: inputs.shipmentItem.allocate_item.stock.lot.expiration_date },
          { display: getAmountFormat(inputs.shipmentItem.allocate_item.quantity?.toString() ?? '0') },
          {
            display: '',
            val: getAmountFormat(inputs.input.quantity?.toString() ?? '0'),
            params: { target: inputs.input.allocate_item_code },
            disabled: is_shipped,
          },
          { display: !is_shipped ? '削除' : '-', val: inputs.input.allocate_item_code, disabled: is_shipped },
        ])
    : [];
  const itemErrors = Array.isArray(shipmentItemInputsErrors)
    ? shipmentItemInputsErrors.map((item) => {
        if (typeof item === 'string') {
          return (
            <Grid item xs={12}>
              <Typography color="red">{item}</Typography>
            </Grid>
          );
        }
        return Object.keys(item).map((key) => (
          <Grid item xs={12}>
            <Typography color="red">{`${item[key] as string}`}</Typography>
          </Grid>
        ));
      })
    : '';

  const message = <Typography>確定した出荷内容は変更できません。出荷内容を確定しますか。</Typography>;

  const clearInputsError = () => {
    setDateError(null);
    setReceivedOrderHeaderError(null);
    setShipmentItemInputsErrors([]);
  };
  const clearInputs = () => {
    setDateVal(null);
    setReceivedOrderHeaderVal(null);
    setShipmentItemInputs([]);
  };

  const setShipmentHeaderInputsErrors = (data: ShipmentHeaderErrors) => {
    setDateError(data.date);
    setReceivedOrderHeaderError(data.received_order_number);
    if (data.general) {
      setShipmentItemInputsErrors(data.general);
    } else if (data.no_item) {
      setShipmentItemInputsErrors([{ no_item: data.no_item }]);
    }
  };

  const ctx = useContext(apiContext);
  const rows =
    receivedOrderHeaderVal?.received_order_items.map((item) => {
      let status = '出荷明細追加';
      if (item.is_shipped) {
        status = '出荷済み';
      } else if (item.allocated_quantity === 0) {
        status = '未引当';
      } else if (is_shipped) {
        status = '-';
      }

      return [
        { display: item.item.code, val: item.code },
        { display: item.item.name },
        { display: item.item.temp_zone.temp_name },
        { display: getAmountFormat(item.delivery_quantity.toString()) },
        { display: getAmountFormat(item.shipped_quantity?.toString() ?? '0') },
        { display: item.item.stock_unit.name },
        { display: status, disabled: status !== '出荷明細追加' },
      ];
    }) ?? [];

  const onClose = () => {
    handleCloseDialog();
  };

  const onSubmit = () => {
    setOpenConfirmDialog(true);
  };

  const onClickDelete = (targetIndex: string) => {
    setShipmentItemInputs(shipmentItemInputs.filter((inputs) => inputs.input.allocate_item_code !== targetIndex));
    setChanged(true);
  };

  const onClickItemRow = (code: string) => {
    setTargetItemCode(code);
  };

  const setQuantityToInputs = (val: number, rowIndex: number, params: { target: string }) => {
    setShipmentItemInputs(
      shipmentItemInputs.map((inputs) =>
        inputs.input.allocate_item_code === params.target
          ? { ...inputs, input: { ...inputs.input, quantity: val } }
          : inputs
      )
    );
    setChanged(true);
  };

  const handleOpenAddShipmentItemDialog = () => {
    setOpenAddShipmentItemDialog(true);
  };

  const submitShipment = () => {
    if (targetCode) {
      ctx
        .update('/api/shipment-headers/', targetCode, shipmentHeaderInputs)
        .then(() => {
          setTargetCode(null);
          handleCloseDialog();
          setAlertMessage({
            open: true,
            message: '出荷を確定しました。',
            severity: 'success',
          });
        })
        .catch((e: AxiosError<ShipmentHeaderErrors>) => {
          if (e.response) {
            setShipmentHeaderInputsErrors(e.response.data);
          }
          setAlertMessage({
            open: true,
            message: '出荷を確定できませんでした。',
            severity: 'error',
          });
          console.log(e);
        });
      setOpenConfirmDialog(false);
    } else {
      ctx
        .create('/api/shipment-headers/', shipmentHeaderInputs)
        .then(() => {
          setTargetCode(null);
          handleCloseDialog();
          setAlertMessage({
            open: true,
            message: '出荷を確定しました。',
            severity: 'success',
          });
        })
        .catch((e: AxiosError<ShipmentHeaderErrors>) => {
          if (e.response) {
            setShipmentHeaderInputsErrors(e.response.data);
          }
          setAlertMessage({
            open: true,
            message: '出荷を確定できませんでした。',
            severity: 'error',
          });
          console.log(e);
        });
      setOpenConfirmDialog(false);
    }
  };

  const headers = [
    { header: '商品コード' },
    { header: '商品名', width: '30%' },
    { header: '温度帯' },
    { header: '予定納品数' },
    { header: '出荷済み数' },
    { header: '単位' },
    { header: '出荷明細追加', columnType: 'Button', columnFunc: handleOpenAddShipmentItemDialog },
  ];

  const shipmentHeaders = [
    { header: 'ロット' },
    { header: '賞味期限/消費期限' },
    { header: '引当数' },
    { header: '出荷数', columnType: 'TextField-number', columnFunc: setQuantityToInputs },
    { header: '削除', columnType: 'Button', columnFunc: onClickDelete },
  ];

  useEffect(() => {
    clearInputsError();
    setTargetItemCode(null);
    setChanged(false);
    if (targetCode) {
      ctx
        .getRecord<ShipmentHeader>('/api/shipment-headers', targetCode)
        .then((data) => {
          setReceivedOrderHeaderVal(data.received_order);
          setReceivedOrderHeaders([data.received_order]);
          setDateVal(data.date?.toString() ?? '');
          setShipmentItemInputs(
            data.shipment_items.map((item) => ({
              shipmentItem: item,
              input: {
                allocate_item_code: item.allocate_item.code,
                quantity: item.quantity,
              },
            }))
          );
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      clearInputs();
    }
    ctx
      .getRecordList<ReceivedOrderHeader>('/api/stock/received-orders-shipment', {})
      .then((data) => {
        setReceivedOrderHeaders(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [ctx, targetCode, open]);

  return (
    <>
      <Dialog open={open} maxWidth="xl">
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item>
                  <BackButton onClose={onClose} requireConfirm={changed} />
                </Grid>
                {!is_shipped && (
                  <Grid item>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={onSubmit}
                      startIcon={<AppRegistration />}
                      sx={{ width: 150 }}
                    >
                      出荷確定
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={5}>
                <Grid item xs={3}>
                  <SelectBox
                    id="received_order-inputs"
                    items={receivedOrderHeaders}
                    disabled={!!targetCode || is_shipped}
                    labelKey="order_number"
                    idKey="order_number"
                    boxLabel="受注番号"
                    boxPlaceholder=""
                    itemVal={receivedOrderHeaderVal}
                    setFunc={(value: ReceivedOrderHeader) => {
                      setReceivedOrderHeaderVal(value);
                      setShipmentItemInputs([]);
                      setChanged(true);
                    }}
                    helperText="部分的に出荷された受注番号のみ選択できます。"
                    errorMsg={receivedOrderHeaderError}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled={is_shipped}
                    label="出荷日"
                    type="date"
                    value={dateVal ?? ''}
                    onChange={(event) => {
                      setDateVal(event.target.value);
                      setChanged(true);
                    }}
                    size="small"
                    error={!!dateError}
                    helperText={dateError}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled
                    label="事業所"
                    type="text"
                    value={receivedOrderHeaderVal?.department.office.name ?? ''}
                    size="small"
                    helperText="選択された受注ヘッダーから自動入力されます。"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled
                    label="部署"
                    type="text"
                    value={receivedOrderHeaderVal?.department.name ?? ''}
                    size="small"
                    helperText="選択された受注ヘッダーから自動入力されます。"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography> 出荷明細を確認する発注明細を選択してください。 </Typography>
                  <CommonTable
                    columns={headers}
                    idColumn={0}
                    rows={rows}
                    onClickRowFunc={onClickItemRow}
                    emptyMsg="出荷確定を行う受注番号を選択して下さい。"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {itemErrors}
              {targetItemCode && (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h5">出荷明細一覧</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <CommonTable
                      columns={shipmentHeaders}
                      idColumn={0}
                      rows={shipmentRows}
                      emptyMsg="出荷明細が登録されていません。"
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <AddShipmentItemDialog
        shipmentInputs={shipmentItemInputs}
        setShipmentInputs={(
          inputs: {
            shipmentItem: ShipmentItem;
            input: { allocate_item_code: string; quantity: number };
          }[]
        ) => {
          setShipmentItemInputs(inputs);
          setChanged(true);
        }}
        handleCloseDialog={() => {
          setOpenAddShipmentItemDialog(false);
        }}
        orderItemCode={targetItemCode}
        open={openAddShipmentItemDialog}
      />
      <ConfirmationDialog
        inputs={shipmentHeaderInputs}
        url="api/shipment-headers/"
        message={message}
        open={openConfirmDialog}
        setOpenFunc={setOpenConfirmDialog}
        submitFunc={submitShipment}
      />
    </>
  );
};

export default ShipmentCompleteDialog;
