import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper, Grid, Checkbox } from '@mui/material';

import type { Customer, ReservationSlot, WeeklyPlanDict, WeeklyPlanUpdate } from './types';
import { StyledHeaderCell } from '../styles';
import { getTimeFormat } from '../get_format';

const PlanTable: React.FC<{
  reservationSlots: ReservationSlot[];
  weeklyPlanDict: WeeklyPlanDict | null;
  weeklyPlanUpdate: { [code: string]: WeeklyPlanUpdate };
  setWeeklyPlanUpdate: CallableFunction;
  editCustomer: Customer | null;
}> = ({ reservationSlots, weeklyPlanDict, weeklyPlanUpdate, setWeeklyPlanUpdate, editCustomer }) => {
  const weekdayCodes = [
    { code: '0', name: '月' },
    { code: '1', name: '火' },
    { code: '2', name: '水' },
    { code: '3', name: '木' },
    { code: '4', name: '金' },
    { code: '5', name: '土' },
    { code: '6', name: '日' },
  ];
  const onCheckFunc = (checkVal: boolean, weekdayCode: string, slotCode: string, customerCode: string) => {
    setWeeklyPlanUpdate({
      ...weeklyPlanUpdate,
      [`${weekdayCode}_${slotCode}_${customerCode}`]: {
        weekday_code: weekdayCode,
        slot_code: slotCode,
        customer_code: customerCode,
        status: checkVal,
      },
    });
  };
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ bgcolor: '#A0A0A0' }}>
          <TableRow>
            <StyledHeaderCell> 時間帯 </StyledHeaderCell>
            {reservationSlots.map((slot) => (
              <StyledHeaderCell key={`header-${slot.code}`}>
                {getTimeFormat(slot.start_time.toString())}~{getTimeFormat(slot.end_time.toString())}
              </StyledHeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {weekdayCodes.map((weekdayCode) => (
            <TableRow key={`row-${weekdayCode.code}`}>
              <StyledHeaderCell sx={{ bgcolor: '#A0A0A0' }} key={`header-${weekdayCode.code}`}>
                {weekdayCode.name}
              </StyledHeaderCell>
              {reservationSlots.map((slot) => {
                if (
                  weeklyPlanDict &&
                  weekdayCode.code in weeklyPlanDict &&
                  slot.code in weeklyPlanDict[weekdayCode.code]
                ) {
                  let contains = false;
                  if (editCustomer && `${weekdayCode.code}_${slot.code}_${editCustomer.code}` in weeklyPlanUpdate) {
                    contains = weeklyPlanUpdate[`${weekdayCode.code}_${slot.code}_${editCustomer.code}`].status;
                  }
                  return (
                    <TableCell key={`cell-${weekdayCode.code}-${slot.code}`}>
                      {weeklyPlanDict
                        ? weeklyPlanDict[weekdayCode.code][slot.code]?.map((customer) => (
                            <Grid key={`grid-${weekdayCode.code}-${slot.code}-${customer.code}`}>{customer.name}</Grid>
                          ))
                        : ''}
                      {editCustomer && (
                        <Checkbox
                          checked={contains}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            onCheckFunc(event.target.checked, weekdayCode.code, slot.code, editCustomer.code);
                          }}
                        />
                      )}
                    </TableCell>
                  );
                }
                let contains = false;
                if (editCustomer && `${weekdayCode.code}_${slot.code}_${editCustomer.code}` in weeklyPlanUpdate) {
                  contains = weeklyPlanUpdate[`${weekdayCode.code}_${slot.code}_${editCustomer.code}`].status;
                }
                return (
                  <TableCell key={`cell-${weekdayCode.code}-${slot.code}`}>
                    {editCustomer && (
                      <Checkbox
                        checked={contains}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          onCheckFunc(event.target.checked, weekdayCode.code, slot.code, editCustomer.code);
                        }}
                      />
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default PlanTable;
