import React, { useContext, useState, useEffect } from 'react';
import { Backdrop, Button, CircularProgress, Dialog, DialogContent, Grid, TextField } from '@mui/material';
import { Print } from '@mui/icons-material';
import { AxiosError } from 'axios';

import type { ErrorMessages, InputFields } from '../types';
import type { PurchaseOrderHeader } from './types';
import type { AppUser } from '../account/types';
import { apiContext } from '../../hooks/call_apis';
import BackButton from '../back_button';

const PrintPurchaseOrderDialog: React.FC<{
  handleCloseDialog: () => void;
  open: boolean;
  printTarget: string;
}> = ({ handleCloseDialog, open, printTarget }) => {
  const ctx = useContext(apiContext);

  const [printInputs, setPrintInputs] = useState<InputFields>({});
  const [printInputsErrors, setPrintInputsErrors] = useState<ErrorMessages>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(false);

  const clearPrintInputsErrors = () => {
    setPrintInputsErrors({});
  };

  const print = () => {
    clearPrintInputsErrors();
    setLoading(true);
    ctx
      .getAPI(
        '/api/stock/print-purchase-order',
        {
          purchase_order_header: printTarget ?? '',
          date: (printInputs.date as string) ?? '',
          responsible: (printInputs.responsible as string) ?? '',
          customer: (printInputs.customer as string) ?? '',
          customer_FAX: (printInputs.customer_FAX as string) ?? '',
          return_FAX_1: (printInputs.return_FAX_1 as string) ?? '',
          return_FAX_2: (printInputs.return_FAX_2 as string) ?? '',
          store_in: (printInputs.store_in as string) ?? '',
          store_in_responsible: (printInputs.store_in_responsible as string) ?? '',
          store_in_postal: (printInputs.store_in_postal as string) ?? '',
          store_in_address: (printInputs.store_in_address as string) ?? '',
          store_in_tel: (printInputs.store_in_tel as string) ?? '',
        },
        'blob'
      )
      .then((data) => {
        const downloadUrl = window.URL.createObjectURL(data as Blob);
        window.open(downloadUrl, '_blank');
        handleCloseDialog();
      })
      .catch((e: AxiosError<Blob>) => {
        if (e.response) {
          const resBlob = e.response.data;
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener('abort', reject);
            reader.addEventListener('error', reject);
            reader.addEventListener('loadend', () => {
              resolve(reader.result);
            });
            reader.readAsText(resBlob);
          })
            .then((resText) => {
              const resData = JSON.parse(resText as string) as ErrorMessages;
              setPrintInputsErrors(resData);
            })
            .catch(() => {});
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClose = () => {
    handleCloseDialog();
  };

  useEffect(() => {
    clearPrintInputsErrors();
    setChanged(false);
    const printInitials: InputFields = { date: new Date().toISOString().substring(0, 10) };
    ctx
      .getRecord<PurchaseOrderHeader>('/api/purchase-order-headers', printTarget)
      .then((data) => {
        printInitials.customer = data.customer.name;
        printInitials.customer_FAX = data.customer.fax;
        printInitials.store_in = data.store_in.warehouse_name;
        printInitials.store_in_postal = data.store_in.warehouse_postal_number;
        printInitials.store_in_address = data.store_in.warehouse_address;
        printInitials.return_FAX_1 = data.store_in.warehouse_fax;
        printInitials.store_in_tel = data.store_in.warehouse_tel;
        ctx
          .getRecordList<AppUser>('/api/app-users', { username: ctx.username })
          .then((userData) => {
            if (userData.length === 1) {
              printInitials.responsible = userData[0].last_name;
            }
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            setPrintInputs(printInitials);
          });
      })
      .catch((e) => {
        console.error(e);
      });
  }, [ctx, printTarget, open]);

  return (
    <>
      <Dialog open={open} maxWidth="md">
        <DialogContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item>
                  <BackButton onClose={onClose} requireConfirm={changed} />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => print()}
                    sx={{ width: 150 }}
                    startIcon={<Print />}
                  >
                    発注書出力
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="date"
                    label="発注日"
                    type="date"
                    value={printInputs.date ?? ''}
                    onChange={(event) => {
                      setPrintInputs({ ...printInputs, date: event.target.value });
                      setChanged(true);
                    }}
                    size="small"
                    error={!!printInputsErrors.date}
                    helperText={printInputsErrors.date}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="responsible"
                    label="担当"
                    type="text"
                    value={printInputs.responsible ?? ''}
                    onChange={(event) => {
                      setPrintInputs({ ...printInputs, responsible: event.target.value });
                      setChanged(true);
                    }}
                    size="small"
                    error={!!printInputsErrors.responsible}
                    helperText={printInputsErrors.responsible}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="customer"
                    label="発注先名"
                    type="text"
                    value={printInputs.customer ?? ''}
                    onChange={(event) => {
                      setPrintInputs({ ...printInputs, customer: event.target.value });
                      setChanged(true);
                    }}
                    size="small"
                    error={!!printInputsErrors.customer}
                    helperText={printInputsErrors.customer}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="customer_FAX"
                    label="発注先FAX"
                    type="text"
                    value={printInputs.customer_FAX ?? ''}
                    onChange={(event) => {
                      setPrintInputs({ ...printInputs, customer_FAX: event.target.value });
                      setChanged(true);
                    }}
                    size="small"
                    error={!!printInputsErrors.customer_FAX}
                    helperText={printInputsErrors.customer_FAX}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="return_FAX_1"
                    label="返送先FAX1"
                    type="text"
                    value={printInputs.return_FAX_1 ?? ''}
                    onChange={(event) => {
                      setPrintInputs({ ...printInputs, return_FAX_1: event.target.value });
                      setChanged(true);
                    }}
                    size="small"
                    error={!!printInputsErrors.return_FAX_1}
                    helperText={printInputsErrors.return_FAX_1}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="return_FAX_2"
                    label="返送先FAX2"
                    type="text"
                    value={printInputs.return_FAX_2 ?? ''}
                    onChange={(event) => {
                      setPrintInputs({ ...printInputs, return_FAX_2: event.target.value });
                      setChanged(true);
                    }}
                    size="small"
                    error={!!printInputsErrors.return_FAX_2}
                    helperText={printInputsErrors.return_FAX_2}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="store_in"
                    label="納品先"
                    type="text"
                    value={printInputs.store_in ?? ''}
                    onChange={(event) => {
                      setPrintInputs({ ...printInputs, store_in: event.target.value });
                      setChanged(true);
                    }}
                    size="small"
                    error={!!printInputsErrors.store_in}
                    helperText={printInputsErrors.store_in}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="store_in_responsible"
                    label="納品先担当"
                    type="text"
                    value={printInputs.store_in_responsible ?? ''}
                    onChange={(event) => {
                      setPrintInputs({ ...printInputs, store_in_responsible: event.target.value });
                      setChanged(true);
                    }}
                    size="small"
                    error={!!printInputsErrors.store_in_responsible}
                    helperText={printInputsErrors.store_in_responsible}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="store_in_postal"
                    label="納品先郵便番号"
                    type="text"
                    value={printInputs.store_in_postal ?? ''}
                    onChange={(event) => {
                      setPrintInputs({ ...printInputs, store_in_postal: event.target.value });
                      setChanged(true);
                    }}
                    size="small"
                    error={!!printInputsErrors.store_in_postal}
                    helperText={printInputsErrors.store_in_postal}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="store_in_address"
                    label="納品先住所"
                    type="text"
                    value={printInputs.store_in_address ?? ''}
                    onChange={(event) => {
                      setPrintInputs({ ...printInputs, store_in_address: event.target.value });
                      setChanged(true);
                    }}
                    size="small"
                    error={!!printInputsErrors.store_in_address}
                    helperText={printInputsErrors.store_in_address}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="store_in_tel"
                    label="納品先電話番号"
                    type="text"
                    value={printInputs.store_in_tel ?? ''}
                    onChange={(event) => {
                      setPrintInputs({ ...printInputs, store_in_tel: event.target.value });
                      setChanged(true);
                    }}
                    size="small"
                    error={!!printInputsErrors.store_in_tel}
                    helperText={printInputsErrors.store_in_tel}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Backdrop sx={{ color: '#fff', zIndex: 9999 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default PrintPurchaseOrderDialog;
