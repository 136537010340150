import React, { useState, useContext, useEffect } from 'react';
import { Dialog, DialogContent, Grid, TextField } from '@mui/material';

import type { InputFields, ErrorMessages } from '../types';
import type { Berth, Warehouse, TempZone } from './types';
import MasterManageDialogHeaderButtons from '../master_manage_dialog_header_buttons';
import { apiContext } from '../../hooks/call_apis';
import { SelectBox } from '../auto_complete_select_box';

const BerthManageDialog: React.FC<{
  setAlertMessage: CallableFunction;
  handleCloseDialog: () => void;
  open: boolean;
  updateTargetID: string | null;
  setUpdateTargetID: CallableFunction;
}> = ({ setAlertMessage, handleCloseDialog, open, updateTargetID, setUpdateTargetID }) => {
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const [tempZones, setTempZones] = useState<TempZone[]>([]);

  const [warehouseVal, setWarehouseVal] = useState<Warehouse | null>(null);
  const [tempZoneVal, setTempZoneVal] = useState<TempZone | null>(null);
  const [berthInputs, setBerthInputs] = useState<InputFields>({});
  const [changed, setChanged] = useState<boolean>(false);

  const [berthError, setBerthError] = useState<ErrorMessages>({});

  const clearBerth = () => {
    setBerthInputs({
      berth_code: '',
      berth_name: '',
      temp_zone: '',
      warehouse: '',
    });
    setTempZoneVal(null);
    setWarehouseVal(null);
  };

  const clearBerthError = () => {
    setBerthError({
      berth_code: '',
      berth_name: '',
      temp_zone: '',
      warehouse: '',
    });
  };

  const ctx = useContext(apiContext);
  useEffect(() => {
    ctx
      .getRecordList<Warehouse>('/api/warehouses/', {})
      .then((data) => {
        setWarehouses(data);
      })
      .catch((e) => {
        console.error(e);
      });
    ctx
      .getRecordList<TempZone>('/api/temp-zones/', {})
      .then((data) => {
        setTempZones(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [ctx]);
  useEffect(() => {
    setChanged(false);
    if (updateTargetID) {
      ctx
        .getRecord<Berth>('/api/read-berths/', updateTargetID)
        .then((data) => {
          setBerthInputs({
            berth_code: data.berth_code,
            berth_name: data.berth_name,
            temp_zone: data.temp_zone.temp_code,
            warehouse: data.warehouse.warehouse_code,
          });
          setTempZoneVal(data.temp_zone);
          setWarehouseVal(data.warehouse);
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      clearBerth();
    }
    clearBerthError();
  }, [ctx, warehouses, tempZones, updateTargetID, open]);

  return (
    <Dialog open={open} fullWidth maxWidth="xl">
      <DialogContent>
        <Grid container spacing={6}>
          <MasterManageDialogHeaderButtons
            url="/api/write-berth/"
            object={berthInputs}
            clearObjFunc={clearBerth}
            clearErrorFunc={clearBerthError}
            handleCloseDialog={handleCloseDialog}
            setAlertMessage={setAlertMessage}
            setErrorFunc={setBerthError}
            updateTargetID={updateTargetID}
            setUpdateTargetID={setUpdateTargetID}
            backConfirm={changed}
          />
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item xs={3}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="berth_code-inputs"
                  label="バースコード"
                  type="text"
                  value={berthInputs.berth_code}
                  required
                  disabled={!!updateTargetID}
                  onChange={(event) => {
                    setBerthInputs({ ...berthInputs, berth_code: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={!!berthError.berth_code}
                  helperText={berthError.berth_code}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="berth_name-inputs"
                  label="バース名"
                  type="text"
                  value={berthInputs.berth_name}
                  required
                  onChange={(event) => {
                    setBerthInputs({ ...berthInputs, berth_name: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={!!berthError.berth_name}
                  helperText={berthError.berth_name}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <SelectBox
                  id="temp_zone-inputs"
                  items={tempZones}
                  labelKey="temp_name"
                  idKey="temp_code"
                  boxLabel="温度帯"
                  boxPlaceholder=""
                  itemVal={tempZoneVal}
                  setFunc={(value: TempZone) => {
                    setBerthInputs({ ...berthInputs, temp_zone: value?.temp_code ?? '' });
                    setTempZoneVal(value);
                    setChanged(true);
                  }}
                  errorMsg={berthError.temp_zone}
                />
              </Grid>
              <Grid item xs={3}>
                <SelectBox
                  id="warehouse-inputs"
                  items={warehouses}
                  labelKey="warehouse_name"
                  idKey="warehouse_code"
                  boxLabel="倉庫"
                  boxPlaceholder=""
                  itemVal={warehouseVal}
                  setFunc={(value: Warehouse) => {
                    setBerthInputs({ ...berthInputs, warehouse: value?.warehouse_code ?? '' });
                    setWarehouseVal(value);
                    setChanged(true);
                  }}
                  errorMsg={berthError.warehouse}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default BerthManageDialog;
