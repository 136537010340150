import React from 'react';
import { Grid, TextField } from '@mui/material';

export const StockUnitsSearchBox: React.FC<{
  nameVal: string | null;
  setNameVal: CallableFunction;
}> = ({ nameVal, setNameVal }) => (
  <Grid container direction="column" spacing={5}>
    <Grid item xs={12}>
      <TextField
        InputLabelProps={{ shrink: true }}
        id="name-search"
        label="在庫単位表示"
        type="text"
        value={nameVal ?? ''}
        onChange={(event) => {
          setNameVal(event.target.value);
        }}
        size="small"
        fullWidth
      />
    </Grid>
  </Grid>
);

export default StockUnitsSearchBox;
