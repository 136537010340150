import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import type { Warehouse } from './types';
import { SelectBox } from '../auto_complete_select_box';
import { apiContext } from '../../hooks/call_apis';

const ReservationSlotsSearchBox: React.FC<{
  warehouseVal: Warehouse | null;
  setWarehouseVal: CallableFunction;
}> = ({ warehouseVal, setWarehouseVal }) => {
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const ctx = useContext(apiContext);
  useEffect(() => {
    ctx
      .getRecordList<Warehouse>('/api/warehouses/', {})
      .then((data) => {
        setWarehouses(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [ctx]);
  return (
    <Grid container direction="column" spacing={5}>
      <Grid item xs={12}>
        <SelectBox
          id="warehouse-search"
          items={warehouses}
          labelKey="warehouse_name"
          idKey="warehouse_code"
          boxLabel="倉庫"
          boxPlaceholder="倉庫を選択"
          itemVal={warehouseVal}
          setFunc={setWarehouseVal}
        />
      </Grid>
    </Grid>
  );
};

export default ReservationSlotsSearchBox;
