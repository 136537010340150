import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Done, LocalShipping, ModeEdit } from '@mui/icons-material';
import { AxiosError } from 'axios';

import type { InputFields, ErrorMessages, Cell } from '../types';
import type { Berth, BerthReservation, Customer, DelayNotification } from './types';
import MasterManageDialogHeaderButtons from '../master_manage_dialog_header_buttons';
import { apiContext } from '../../hooks/call_apis';
import ConfirmationDialog from '../confirmation_dialog';
import { SelectBox } from '../auto_complete_select_box';
import { CommonTable } from '../common_table';
import { getTimeFormat } from '../get_format';

const ReservationManageDialog: React.FC<{
  setAlertMessage: CallableFunction;
  handleCloseDialog: () => void;
  open: boolean;
  updateTargetID: string | null;
  setUpdateTargetID: CallableFunction;
}> = ({ setAlertMessage, handleCloseDialog, open, updateTargetID, setUpdateTargetID }) => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [berths, setBerths] = useState<Berth[]>([]);

  const [customerVal, setCustomerVal] = useState<Customer | null>(null);
  const [berthVal, setBerthVal] = useState<Berth | null>(null);
  const [reservationInputs, setReservationInputs] = useState<InputFields>({});
  const [changed, setChanged] = useState<boolean>(false);

  const [reservationError, setReservationError] = useState<ErrorMessages>({});

  const [arriveTimeEditable, setArriveTimeEditable] = useState<boolean>(true);
  const [completeTimeEditable, setCompleteTimeEditable] = useState<boolean>(true);

  const columns = [{ header: '連絡時予約時刻' }, { header: '到着予定時刻' }];
  const [rows, setRows] = useState<Cell[][]>([]);

  const clearReservation = () => {
    setReservationInputs({
      code: null,
      reservation_date: null,
      berth_code: null,
      customer_code: null,
      start_time: null,
      end_time: null,
      arrived_at: null,
      completed_at: null,
      disabled: false,
      is_scheduled_for_delay_notification: null,
    });
    setBerthVal(null);
    setCustomerVal(null);
  };

  const clearReservationError = () => {
    setReservationError({
      general: null,
      code: null,
      reservation_date: null,
      berth_code: null,
      customer_code: null,
      start_time: null,
      end_time: null,
      arrived_at: null,
      completed_at: null,
    });
  };

  const ctx = useContext(apiContext);

  const getReservation = useCallback(() => {
    if (updateTargetID) {
      ctx
        .getRecord<BerthReservation>('/api/berth-reservations/', updateTargetID)
        .then((data) => {
          setReservationInputs({
            code: data.code,
            reservation_date: data.reservation_date,
            berth_code: data.berth.berth_code,
            customer_code: data.customer?.code ?? '',
            start_time: data.start_time,
            end_time: data.end_time,
            is_scheduled_for_delay_notification: data.is_scheduled_for_delay_notification,
            arrived_at: data.arrived_at,
            completed_at: data.completed_at,
            disabled: data.disabled,
            is_completed: data.is_completed,
            is_arrived: data.is_arrived,
          });

          setCustomerVal(data.customer ?? null);
          setBerthVal({
            berth_code: data.berth.berth_code,
            berth_name: `${data.berth.berth_name}（${data.berth.warehouse.warehouse_name}）`,
            warehouse: data.berth.warehouse,
            temp_zone: data.berth.temp_zone,
            is_valid: data.berth.is_valid,
          });
        })
        .catch((e) => {
          console.error(e);
        });
    }
    setArriveTimeEditable(true);
    setCompleteTimeEditable(true);
  }, [ctx, updateTargetID]);

  useEffect(() => {
    ctx
      .getRecordList<Berth>('/api/read-berths/', {})
      .then((data) => {
        const berthList: Berth[] = data.map((item) => ({
          berth_code: item.berth_code,
          berth_name: `${item.berth_name}（${item.warehouse.warehouse_name}）`,
          warehouse: item.warehouse,
          temp_zone: item.temp_zone,
          is_valid: item.is_valid,
        }));
        setBerths(berthList);
      })
      .catch((e) => {
        console.error(e);
      });
    ctx
      .getRecordList<Customer>('/api/customers/', {})
      .then((data) => {
        setCustomers(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [ctx]);
  useEffect(() => {
    setChanged(false);
    if (updateTargetID) {
      getReservation();
      ctx
        .getRecordList<DelayNotification>('/api/berth/delay-notifications', {
          berth_reservation: updateTargetID,
        })
        .then((data) => {
          setRows(
            data.map((delay) => [
              { display: getTimeFormat(delay.reserved_time.toString()), val: delay.code },
              { display: getTimeFormat(delay.arrival_time.toString()) },
            ])
          );
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      setRows([]);
      clearReservation();
    }
    clearReservationError();
  }, [ctx, getReservation, updateTargetID, open]);

  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const message = (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        到着時刻を登録すると予約情報が修正できなくなります。よろしいですか。
      </Grid>
    </Grid>
  );

  const onClickArrive = () => {
    setOpenConfirmDialog(true);
  };

  const submitArrive = () => {
    if (updateTargetID) {
      ctx
        .postAPI<{ berth_reservation: string }>('/api/berth/reservation-arrive', { berth_reservation: updateTargetID })
        .then(() => {
          getReservation();
          setAlertMessage({
            open: true,
            message: '到着時刻を記録しました。',
            severity: 'success',
          });
        })
        .catch((e: AxiosError<string>) => {
          setReservationError({ ...reservationError, general: e.response?.data ?? '' });
          setAlertMessage({
            open: true,
            message: '到着時刻が記録できませんでした。',
            severity: 'error',
          });
          console.error(e);
        });
      setOpenConfirmDialog(false);
    }
  };
  const onClickComplete = () => {
    if (updateTargetID) {
      ctx
        .postAPI<{ berth_reservation: string }>('/api/berth/reservation-complete', {
          berth_reservation: updateTargetID,
        })
        .then(() => {
          getReservation();
          setAlertMessage({
            open: true,
            message: '作業完了時刻を記録しました。',
            severity: 'success',
          });
        })
        .catch((e: AxiosError<string>) => {
          setReservationError({ ...reservationError, general: e.response?.data ?? '' });
          setAlertMessage({
            open: true,
            message: '作業完了時刻が記録できませんでした。',
            severity: 'error',
          });
          console.error(e);
        });
    }
  };
  const onClickModifyArriveTime = () => {
    setArriveTimeEditable(!arriveTimeEditable);
  };
  const onClickModifyCompleteTime = () => {
    setCompleteTimeEditable(!completeTimeEditable);
  };

  const extraButtons = [
    updateTargetID && !reservationInputs.disabled && !reservationInputs.is_arrived && (
      <Button
        color="success"
        variant="contained"
        onClick={onClickArrive}
        startIcon={<LocalShipping />}
        sx={{ width: 150 }}
        key="button-arrive"
      >
        トラック到着
      </Button>
    ),
    updateTargetID && !reservationInputs.disabled && reservationInputs.is_arrived && !reservationInputs.is_completed && (
      <Button
        color="success"
        variant="contained"
        onClick={onClickComplete}
        startIcon={<Done />}
        sx={{ width: 150 }}
        key="button-complete"
      >
        作業完了
      </Button>
    ),
  ];

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogContent>
          <Grid container spacing={6}>
            <MasterManageDialogHeaderButtons
              url="/api/berth-reservations/"
              object={reservationInputs}
              clearObjFunc={clearReservation}
              clearErrorFunc={clearReservationError}
              handleCloseDialog={handleCloseDialog}
              setAlertMessage={setAlertMessage}
              setErrorFunc={setReservationError}
              updateTargetID={updateTargetID}
              setUpdateTargetID={setUpdateTargetID}
              extraButtons={extraButtons}
              backConfirm={changed}
            />
            <Grid item xs={12}>
              <Grid container spacing={5}>
                {reservationError.general && (
                  <Grid item xs={12}>
                    <Typography color="red">{reservationError.general}</Typography>
                  </Grid>
                )}
                <Grid item xs={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="reservation_date-inputs"
                    label="予約日"
                    type="date"
                    value={reservationInputs.reservation_date ?? ''}
                    required
                    onChange={(event) => {
                      setReservationInputs({ ...reservationInputs, reservation_date: event.target.value });
                      setChanged(true);
                    }}
                    size="small"
                    error={!!reservationError.reservation_date}
                    helperText={reservationError.reservation_date}
                    fullWidth
                    disabled={reservationInputs.is_arrived as boolean}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="start_time-inputs"
                    label="開始時刻"
                    type="time"
                    value={reservationInputs.start_time ?? ''}
                    required
                    onChange={(event) => {
                      setReservationInputs({ ...reservationInputs, start_time: event.target.value });
                      setChanged(true);
                    }}
                    size="small"
                    error={!!reservationError.start_time}
                    helperText={reservationError.start_time}
                    fullWidth
                    disabled={reservationInputs.is_arrived as boolean}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="end_time-inputs"
                    label="終了時刻"
                    type="time"
                    value={reservationInputs.end_time ?? ''}
                    required
                    onChange={(event) => {
                      setReservationInputs({ ...reservationInputs, end_time: event.target.value });
                      setChanged(true);
                    }}
                    size="small"
                    error={!!reservationError.end_time}
                    helperText={reservationError.end_time}
                    fullWidth
                    disabled={reservationInputs.is_arrived as boolean}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SelectBox
                    id="customer-inputs"
                    items={customers}
                    labelKey="name"
                    idKey="code"
                    boxLabel="取引先"
                    boxPlaceholder=""
                    itemVal={customerVal}
                    setFunc={(value: Customer) => {
                      setReservationInputs({ ...reservationInputs, customer_code: value?.code ?? '' });
                      setCustomerVal(value);
                      setChanged(true);
                    }}
                    errorMsg={reservationError.customer_code}
                    disabled={(reservationInputs.disabled as boolean) || (reservationInputs.is_arrived as boolean)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SelectBox
                    id="berth-inputs"
                    items={berths}
                    labelKey="berth_name"
                    idKey="berth_code"
                    boxLabel="バース"
                    boxPlaceholder=""
                    itemVal={berthVal}
                    setFunc={(value: Berth) => {
                      setReservationInputs({ ...reservationInputs, berth_code: value?.berth_code ?? '' });
                      setBerthVal(value);
                      setChanged(true);
                    }}
                    errorMsg={reservationError.berth_code}
                    required
                    disabled={reservationInputs.is_arrived as boolean}
                  />
                </Grid>
                {!reservationInputs.is_arrived && (
                  <Grid item xs={4}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={(reservationInputs.disabled as boolean) ?? false}
                            onChange={(event) => {
                              setReservationInputs({ ...reservationInputs, disabled: event.target.checked });
                              setChanged(true);
                            }}
                          />
                        }
                        label="利用不可"
                      />
                    </FormGroup>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {updateTargetID && !reservationInputs.disabled && (
              <Grid item xs={12}>
                <Grid container spacing={5}>
                  <Grid item xs={4}>
                    {reservationInputs.is_arrived && (
                      <Grid container>
                        <Grid item xs={11}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            id="arrived_at-inputs"
                            label="トラック到着時刻"
                            type="time"
                            value={reservationInputs.arrived_at ?? ''}
                            onChange={(event) => {
                              setReservationInputs({ ...reservationInputs, arrived_at: event.target.value });
                              setChanged(true);
                            }}
                            size="small"
                            disabled={arriveTimeEditable}
                            error={!!reservationError.arrived_at}
                            helperText={reservationError.arrived_at}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <Button startIcon={<ModeEdit />} onClick={onClickModifyArriveTime} />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    {reservationInputs.is_completed && (
                      <Grid container spacing={1}>
                        <Grid item xs={11}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            id="completed_at-inputs"
                            label="作業完了時刻"
                            type="time"
                            value={reservationInputs.completed_at ?? ''}
                            onChange={(event) => {
                              setReservationInputs({ ...reservationInputs, completed_at: event.target.value });
                              setChanged(true);
                            }}
                            size="small"
                            disabled={completeTimeEditable}
                            error={!!reservationError.completed_at}
                            helperText={reservationError.completed_at}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <Button startIcon={<ModeEdit />} onClick={onClickModifyCompleteTime} />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {rows.length !== 0 && (
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h5">遅延連絡一覧</Typography>
                    {!reservationInputs.is_scheduled_for_delay_notification && (
                      <Typography color="red">遅延連絡に対して予定が調整できていません。</Typography>
                    )}
                    <CommonTable columns={columns} idColumn={0} rows={rows} />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        message={message}
        open={openConfirmDialog}
        setOpenFunc={setOpenConfirmDialog}
        submitFunc={submitArrive}
      />
    </>
  );
};

export default ReservationManageDialog;
