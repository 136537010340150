import React, { useContext, useEffect, useRef, useState } from 'react';
import { AxiosError } from 'axios';
import { Box, Button, Grid } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

import type { Cell } from '../../components/types';
import type { BerthReservation } from '../../components/berth/types';
import { CommonTable, Handler } from '../../components/common_table';
import { MessageSnackbar, AlertMessage } from '../../components/message_snackbar';
import Header from '../../components/header';
import CustomerReservationSearchBox from '../../components/berth/customer_reservation_search_box';
import { getDateFormat, getTimeFormat } from '../../components/get_format';
import ReserveDialog from '../../components/berth/reserve_dialog';
import DelayNotificationDialog from '../../components/berth/delay_notification_dialog';
import { apiContext } from '../../hooks/call_apis';

const Buttons: React.FC<{
  handleOpenDialogFunc: CallableFunction;
  search: CallableFunction;
}> = ({ handleOpenDialogFunc, search }) => {
  const handleOpenDialog = () => {
    handleOpenDialogFunc();
  };
  const handleClickSearch = () => {
    search();
  };
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button
          id="search-reservations"
          variant="contained"
          startIcon={<ManageSearchIcon />}
          onClick={handleClickSearch}
        >
          検索
        </Button>
      </Grid>
      <Grid item>
        <Button
          id="berth-reservation"
          variant="contained"
          color="success"
          startIcon={<AppRegistrationIcon />}
          onClick={handleOpenDialog}
        >
          新規予約
        </Button>
      </Grid>
    </Grid>
  );
};

const CustomerReservationListPage: React.FC = () => {
  const [openReservationDialog, setOpenReservationDialog] = useState<boolean>(false);
  const [openDelayDialog, setOpenDelayDialog] = useState<boolean>(false);
  const [rows, setRows] = useState<Cell[][]>([[]]);

  const ctx = useContext(apiContext);

  const tableRef = useRef({} as Handler);
  const today = getDateFormat(new Date());
  const [reservationDateQuery, setReservationDateQuery] = useState<string>(today);
  const [dateErrorMsg, setDateErrorMsg] = useState<string | null>(null);

  const [delayTarget, setDelayTarget] = useState<string | null>(null);

  const getReservationTable = () => {
    ctx
      .getRecordList<BerthReservation>('/api/berth/customer/reservations', {
        date: reservationDateQuery,
      })
      .then((data) => {
        setRows(
          data.map((reservation) => [
            { display: reservation.reservation_date.toString(), val: reservation.code },
            { display: reservation.berth.warehouse.warehouse_name },
            {
              display: `${getTimeFormat(reservation.start_time.toString())}-${getTimeFormat(
                reservation.end_time.toString()
              )}`,
            },
            { display: reservation.berth.berth_name },
            { display: '遅延連絡', val: reservation.code },
          ])
        );
        setDateErrorMsg(null);
      })
      .catch((e: AxiosError<{ date: string }>) => {
        setDateErrorMsg(e.response?.data.date ?? null);
        console.error(e);
      });
  };

  const search = () => {
    getReservationTable();
    tableRef.current?.initializePage();
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseDialog = () => {
    setOpenReservationDialog(false);
    setOpenDelayDialog(false);
    getReservationTable();
  };

  const handleOpenReserveDialog = () => {
    setOpenReservationDialog(true);
  };
  const handleOpenDelayDialog = (targetCode: string) => {
    setDelayTarget(targetCode);
    setOpenDelayDialog(true);
  };

  const [alertMessage, setAlertMessage] = useState<AlertMessage>({ open: false, message: '', severity: 'success' });

  const columns = [
    { header: '納品日' },
    { header: '納品先' },
    { header: '時刻' },
    { header: 'バース' },
    { header: '遅延連絡', columnType: 'Button', columnFunc: handleOpenDelayDialog },
  ];

  return (
    <>
      <MessageSnackbar alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
      <Header screenName="納品作業予約一覧" />
      <Box sx={{ margin: '2%' }}>
        <Grid container justifyContent="center" spacing={6}>
          <Grid item xs={12}>
            <Buttons handleOpenDialogFunc={handleOpenReserveDialog} search={search} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={6}>
              <Grid item xs={4}>
                <CustomerReservationSearchBox
                  reservationDateVal={reservationDateQuery}
                  setReservationDateVal={setReservationDateQuery}
                  dateErrorMsg={dateErrorMsg}
                />
              </Grid>
              <Grid item xs={8}>
                <CommonTable columns={columns} idColumn={0} rows={rows} ref={tableRef} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <ReserveDialog
        setAlertMessage={setAlertMessage}
        handleCloseDialog={() => handleCloseDialog()}
        open={openReservationDialog}
      />
      <DelayNotificationDialog
        setAlertMessage={setAlertMessage}
        handleCloseDialog={handleCloseDialog}
        open={openDelayDialog}
        targetID={delayTarget}
        setTargetID={setDelayTarget}
      />
    </>
  );
};

export default CustomerReservationListPage;
