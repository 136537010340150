import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

import type { Cell } from '../../components/types';
import type { Berth, Warehouse, TempZone } from '../../components/berth/types';
import { CommonTable, Handler } from '../../components/common_table';
import { MessageSnackbar, AlertMessage } from '../../components/message_snackbar';
import Header from '../../components/header';
import { BerthsSearchBox } from '../../components/berth/berths_search_box';
import BerthManageDialog from '../../components/berth/berth_manage_dialog';
import { apiContext } from '../../hooks/call_apis';

const Buttons: React.FC<{
  handleOpenDialogFunc: CallableFunction;
  search: CallableFunction;
}> = ({ handleOpenDialogFunc, search }) => {
  const handleOpenBerthDialog = () => {
    handleOpenDialogFunc();
  };
  const handleClickSearch = () => {
    search();
  };
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button id="search-berths" variant="contained" startIcon={<ManageSearchIcon />} onClick={handleClickSearch}>
          検索
        </Button>
      </Grid>
      <Grid item>
        <Button
          id="create-berth"
          variant="contained"
          color="success"
          startIcon={<AppRegistrationIcon />}
          onClick={handleOpenBerthDialog}
        >
          新規登録
        </Button>
      </Grid>
    </Grid>
  );
};

const BerthPage: React.FC = () => {
  const [openBerthDialog, setOpenBerthDialog] = useState<boolean>(false);
  const [updateBerth, setUpdateBerth] = useState<string | null>(null);

  const [rows, setRows] = useState<Cell[][]>([[]]);

  const ctx = useContext(apiContext);

  const [warehouseQuery, setWarehouseQuery] = useState<Warehouse[]>([]);
  const [tempZoneQuery, setTempZoneQuery] = useState<TempZone[]>([]);

  const tableRef = useRef({} as Handler);
  const getBerthTable = () => {
    ctx
      .getRecordList<Berth>('/api/read-berths/', {
        warehouse: warehouseQuery.map((w) => w.warehouse_code),
        temp_zone: tempZoneQuery.map((t) => t.temp_code),
      })
      .then((data) => {
        setRows(
          data.map((berth) => [
            { display: berth.berth_code, val: berth.berth_code },
            { display: berth.berth_name },
            { display: berth.temp_zone.temp_name },
            { display: berth.warehouse.warehouse_name },
          ])
        );
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const search = () => {
    getBerthTable();
    tableRef.current?.initializePage();
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseDialog = () => {
    setOpenBerthDialog(false);
    getBerthTable();
  };

  const handleOpenBerthDialog = (targetCode?: string) => {
    if (targetCode !== undefined) setUpdateBerth(targetCode);
    else setUpdateBerth(null);
    setOpenBerthDialog(true);
  };

  const [alertMessage, setAlertMessage] = useState<AlertMessage>({ open: false, message: '', severity: 'success' });

  const columns = [
    { header: 'バースコード', columnType: 'Button', columnFunc: handleOpenBerthDialog },
    { header: 'バース名' },
    { header: '温度帯' },
    { header: '倉庫' },
  ];

  return (
    <>
      <MessageSnackbar alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
      <Header screenName="バースマスタ" />
      <Box sx={{ margin: '2%' }}>
        <Grid container justifyContent="center" spacing={6}>
          <Grid item xs={12}>
            <Buttons handleOpenDialogFunc={handleOpenBerthDialog} search={search} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={6}>
              <Grid item xs={4}>
                <BerthsSearchBox
                  tempZoneVals={tempZoneQuery}
                  setTempZoneVals={setTempZoneQuery}
                  warehouseVals={warehouseQuery}
                  setWarehouseVals={setWarehouseQuery}
                />
              </Grid>
              <Grid item xs={8}>
                <CommonTable columns={columns} idColumn={0} rows={rows} ref={tableRef} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <BerthManageDialog
        setAlertMessage={setAlertMessage}
        handleCloseDialog={() => handleCloseDialog()}
        open={openBerthDialog}
        updateTargetID={updateBerth}
        setUpdateTargetID={setUpdateBerth}
      />
    </>
  );
};

export default BerthPage;
