import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { AppRegistration, DynamicFeed, ManageSearch, FileUpload } from '@mui/icons-material';

import type { InputFields, Cell, SelectBoxItem } from '../../components/types';
import type { Warehouse } from '../../components/berth/types';
import type { Office, Product, ReceivedOrderHeader, ReceivedOrderItem } from '../../components/stock/types';
import CommonPaginationTable, { RowsAndCount, Handler } from '../../components/common_pagination_table';
import { CommonTable } from '../../components/common_table';
import Header from '../../components/header';
import { MessageSnackbar, AlertMessage } from '../../components/message_snackbar';
import { apiContext } from '../../hooks/call_apis';
import AllocateStockDialog from '../../components/stock/allocate_stock_dialog';
import BulkAllocateStockDialog from '../../components/stock/bulk_allocate_stock_dialog';
import { ReceivedOrderSearchBox } from '../../components/stock/received_order_search_box';
import UploadReceivedOrderDialog from '../../components/stock/upload_received_order_dialog';
import ReceivedOrderManageDialog from '../../components/stock/received_order_manage_dialog';
import { getAmountFormat, getDateFormat } from '../../components/get_format';

const Buttons: React.FC<{
  handleOpenManageDialog: CallableFunction;
  setOpenUploadDialog: CallableFunction;
  setOpenBulkAllocateDialog: CallableFunction;
  search: CallableFunction;
}> = ({ handleOpenManageDialog, setOpenUploadDialog, setOpenBulkAllocateDialog, search }) => {
  const handleClickSearch = () => {
    search();
  };
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button variant="contained" onClick={handleClickSearch} startIcon={<ManageSearch />}>
          検索
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="success"
          onClick={() => {
            handleOpenManageDialog();
          }}
          startIcon={<AppRegistration />}
        >
          新規登録
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="success"
          onClick={() => {
            setOpenUploadDialog(true);
          }}
          startIcon={<FileUpload />}
        >
          CSVアップロード
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="success"
          onClick={() => {
            setOpenBulkAllocateDialog(true);
          }}
          startIcon={<DynamicFeed />}
        >
          一括引当
        </Button>
      </Grid>
    </Grid>
  );
};

const ReceivedOrderPage: React.FC = () => {
  const [alertMessage, setAlertMessage] = useState<AlertMessage>({ open: false, message: '', severity: 'success' });

  const [shipFromQueries, setShipFromQueries] = useState<Warehouse[]>([]);
  const [officeQueries, setOfficeQueries] = useState<Office[]>([]);
  const [productQueries, setProductQueries] = useState<Product[]>([]);
  const [dateSearchQueries, setDateSearchQueries] = useState<InputFields>({});
  const [isShippedQuery, setIsShippedQuery] = useState<SelectBoxItem | null>(null);
  const [isAllocatedQuery, setIsAllocatedQuery] = useState<SelectBoxItem | null>(null);

  const [openManageDialog, setOpenManageDialog] = useState<boolean>(false);
  const [openAllocateDialog, setOpenAllocateDialog] = useState<boolean>(false);
  const [openBulkAllocateDialog, setOpenBulkAllocateDialog] = useState<boolean>(false);
  const [openUploadDialog, setOpenUploadDialog] = useState<boolean>(false);
  const [targetReceivedOrder, setTargetReceivedOrder] = useState<string | null>(null);
  const [orderHeaders, setOrderHeaders] = useState<ReceivedOrderHeader[]>([]);

  const [headerRows, setHeaderRows] = useState<RowsAndCount>({ count: 0, rows: [] });
  const [itemRows, setItemRows] = useState<Cell[][]>([]);
  const ctx = useContext(apiContext);
  const paginationTableRef = useRef({} as Handler);

  const initializePagination = () => {
    paginationTableRef.current?.initializePagination();
  };

  const handleOpenManageDialog = (targetCode?: string) => {
    if (targetCode) {
      setTargetReceivedOrder(targetCode);
    } else {
      setTargetReceivedOrder(null);
    }
    setOpenManageDialog(true);
  };

  const handleCloseDialog = () => {
    paginationTableRef.current?.updatePagination();
    setItemRows([]);
    setOpenAllocateDialog(false);
    setOpenBulkAllocateDialog(false);
    setOpenUploadDialog(false);
    setOpenManageDialog(false);
  };

  const handleOpenAllocateDialog = (targetCode: string) => {
    setTargetReceivedOrder(targetCode);
    setOpenAllocateDialog(true);
  };

  const handleOpenBulkAllocateDialog = () => {
    setOpenBulkAllocateDialog(true);
  };

  const orderColumns = [
    { header: '伝票番号', columnType: 'Button', columnFunc: handleOpenManageDialog },
    { header: '納品希望日' },
    { header: '事業所' },
    { header: '部署' },
    { header: '出荷元' },
    { header: '引当', columnType: 'Button', columnFunc: handleOpenAllocateDialog },
  ];

  const itemColumns = [
    { header: '商品コード' },
    { header: '商品名', width: '30%' },
    { header: 'メーカー' },
    { header: '受注数' },
    { header: '納品数' },
    { header: '単位' },
    { header: '入数' },
    { header: '単価' },
    { header: '温度帯' },
  ];
  // CommonPaginationTableで呼び出すデータ取得
  const setHeaderRowsFunc = useCallback(
    (page: number, pageSize: number, params: { [name: string]: string | string[] }) => {
      ctx
        .getPaginationRecordList<ReceivedOrderHeader>('/api/received-order-headers/', page, pageSize, params)
        .then((data) => {
          setHeaderRows({
            count: data.count,
            rows: data.results.map((receivedOrder) => [
              {
                display: receivedOrder.order_number.toString(),
                val: receivedOrder.order_number.toString(),
              },
              { display: getDateFormat(new Date(receivedOrder.preferred_date)) },
              { display: receivedOrder.department.office.name },
              { display: receivedOrder.department.name },
              { display: receivedOrder.ship_from.warehouse_name },
              { display: receivedOrder.allocate_completed ? '引当済み' : '未引当', val: receivedOrder.order_number },
            ]),
          });
          setOrderHeaders(data.results);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    [ctx]
  );
  useEffect(() => {
    setHeaderRowsFunc(1, 5, {});
  }, [ctx, setHeaderRowsFunc]);

  const onClickHeader = (orderCode: string | null) => {
    if (orderCode) {
      ctx
        .getRecordList<ReceivedOrderItem>('/api/received-order-items/', { code: orderCode })
        .then((data) => {
          setItemRows(
            data.map((item) => [
              { display: item.item.code, val: item.code },
              { display: item.item.name },
              { display: item.item.manufacturer },
              { display: getAmountFormat(item.ordered_quantity.toString()) },
              { display: getAmountFormat(item.delivery_quantity.toString()) },
              { display: item.item.stock_unit.name },
              { display: item.item.quantity ? getAmountFormat(item.item.quantity.toString()) : '' },
              { display: item.item.unit_price ? getAmountFormat(item.item.unit_price.toString()) : '' },
              { display: item.item.temp_zone.temp_name },
            ])
          );
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const searchHeader = useCallback(
    (page: number, pageSize: number) => {
      setHeaderRowsFunc(page, pageSize, {
        product: productQueries.map((product) => product.code),
        ship_from: shipFromQueries.map((shipFrom) => shipFrom.warehouse_code),
        office: officeQueries.map((office) => office.code),
        preferred_date__gte: dateSearchQueries.preferred_date__gte as string,
        preferred_date__lte: dateSearchQueries.preferred_date__lte as string,
        is_shipped: isShippedQuery?.code ?? '',
        is_allocated: isAllocatedQuery?.code ?? '',
      });
      setItemRows([]);
    },
    [
      dateSearchQueries,
      officeQueries,
      productQueries,
      shipFromQueries,
      setHeaderRowsFunc,
      isShippedQuery,
      isAllocatedQuery,
    ]
  );

  return (
    <>
      <MessageSnackbar alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
      <Header screenName="受注/在庫引当一覧" />
      <Box sx={{ margin: '2%' }}>
        <Grid container justifyContent="center" spacing={6}>
          <Grid item xs={12}>
            <Buttons
              handleOpenManageDialog={handleOpenManageDialog}
              setOpenUploadDialog={setOpenUploadDialog}
              setOpenBulkAllocateDialog={handleOpenBulkAllocateDialog}
              search={initializePagination}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={6}>
              <Grid item xs={4}>
                <ReceivedOrderSearchBox
                  shipFromVals={shipFromQueries}
                  setShipFromVals={setShipFromQueries}
                  productVals={productQueries}
                  setProductVals={setProductQueries}
                  officeVals={officeQueries}
                  setOfficeVals={setOfficeQueries}
                  dateSearchVals={dateSearchQueries}
                  setDateSearchVals={setDateSearchQueries}
                  isAllocatedVal={isAllocatedQuery}
                  setIsAllocatedVal={setIsAllocatedQuery}
                  isShippedVal={isShippedQuery}
                  setIsShippedVal={setIsShippedQuery}
                />
              </Grid>
              <Grid item xs={8}>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Typography> 明細を確認する受注を選択してください。 </Typography>
                    <CommonPaginationTable
                      columns={orderColumns}
                      idColumn={0}
                      rowsAndCount={headerRows}
                      paginationAPIFunc={searchHeader}
                      onClickRowFunc={onClickHeader}
                      ref={paginationTableRef}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    {itemRows.length !== 0 && (
                      <Grid item>
                        <Grid container>
                          <Grid item>
                            <Typography variant="h4">受注明細一覧</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <CommonTable columns={itemColumns} idColumn={0} rows={itemRows} />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <ReceivedOrderManageDialog
        updateTargetID={targetReceivedOrder}
        setUpdateTargetID={setTargetReceivedOrder}
        setAlertMessage={setAlertMessage}
        handleCloseDialog={() => handleCloseDialog()}
        open={openManageDialog}
      />
      <AllocateStockDialog
        targetCode={targetReceivedOrder}
        setTargetCode={setTargetReceivedOrder}
        shipFromCode={
          orderHeaders.find((item) => item.order_number.toString() === targetReceivedOrder)?.ship_from.warehouse_code ??
          null
        }
        setAlertMessage={setAlertMessage}
        handleCloseDialog={() => handleCloseDialog()}
        open={openAllocateDialog}
      />
      <BulkAllocateStockDialog
        setAlertMessage={setAlertMessage}
        handleCloseDialog={() => handleCloseDialog()}
        open={openBulkAllocateDialog}
      />
      <UploadReceivedOrderDialog
        setAlertMessage={setAlertMessage}
        handleCloseDialog={() => handleCloseDialog()}
        open={openUploadDialog}
      />
    </>
  );
};

export default ReceivedOrderPage;
