import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { ArrowBackIosNew } from '@mui/icons-material';

import ConfirmationDialog from './confirmation_dialog';

interface Props {
  onClose: CallableFunction;
  requireConfirm?: boolean;
}

const BackButton = ({ onClose, requireConfirm = true }: Props) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const message = <Typography>入力・変更した内容は破棄されます。よろしいですか。</Typography>;

  const handleOnClose = () => {
    if (requireConfirm) {
      setOpenConfirmDialog(true);
    } else {
      onClose();
    }
  };

  return (
    <>
      <Button
        id="hide-dialog"
        onClick={handleOnClose}
        variant="outlined"
        startIcon={<ArrowBackIosNew />}
        sx={{ width: 50 }}
      />
      <ConfirmationDialog
        message={message}
        open={openConfirmDialog}
        setOpenFunc={setOpenConfirmDialog}
        submitFunc={onClose}
      />
    </>
  );
};

export default BackButton;
