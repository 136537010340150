import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, Dialog, DialogContent, FormControlLabel, FormGroup, Grid, TextField } from '@mui/material';

import type { InputFields, ErrorMessages } from '../types';
import type { Product, StockUnit } from './types';
import type { Customer, TempZone } from '../berth/types';
import MasterManageDialogHeaderButtons from '../master_manage_dialog_header_buttons';
import { apiContext } from '../../hooks/call_apis';
import { SelectBox } from '../auto_complete_select_box';
import { getAmountFormat } from '../get_format';

const ProductManageDialog: React.FC<{
  setAlertMessage: CallableFunction;
  handleCloseDialog: () => void;
  open: boolean;
  updateTargetID: string | null;
  setUpdateTargetID: CallableFunction;
}> = ({ setAlertMessage, handleCloseDialog, open, updateTargetID, setUpdateTargetID }) => {
  const ctx = useContext(apiContext);
  const [productInputs, setProductInputs] = useState<InputFields>({});
  const [customerVal, setCustomerVal] = useState<Customer | null>(null);
  const [tempZoneVal, setTempZoneVal] = useState<TempZone | null>(null);
  const [stockUnitVal, setStockUnitVal] = useState<StockUnit | null>(null);
  const [changed, setChanged] = useState<boolean>(false);

  const [customers, setCustomers] = useState<Customer[]>([]);
  const [tempZones, setTempZones] = useState<TempZone[]>([]);
  const [stockUnits, setStockUnits] = useState<StockUnit[]>([]);

  const [productInputsError, setProductInputsError] = useState<ErrorMessages>({});

  const clearProduct = () => {
    setProductInputs({
      code: '',
      name: '',
      kana: '',
      manufacturer: '',
      stock_unit_code: '',
      quantity: '',
      unit_price: '',
      temp_zone_code: '',
      customer_code: '',
      tax_rate_flg: false,
      alert_day: null,
    });
  };

  const clearProductError = () => {
    setProductInputsError({
      code: '',
      name: '',
      kana: '',
      manufacturer: '',
      stock_unit_code: '',
      quantity: '',
      unit_price: '',
      temp_zone_code: '',
      customer_code: '',
      tax_rate_flg: '',
      alert_day: '',
    });
    setCustomerVal(null);
    setTempZoneVal(null);
    setStockUnitVal(null);
  };

  useEffect(() => {
    ctx
      .getRecordList<Customer>('/api/customers/', {})
      .then((data) => {
        setCustomers(data);
      })
      .catch((e) => {
        console.error(e);
      });
    ctx
      .getRecordList<TempZone>('/api/temp-zones/', {})
      .then((data) => {
        setTempZones(data);
      })
      .catch((e) => {
        console.error(e);
      });
    ctx
      .getRecordList<StockUnit>('/api/stock-units/', {})
      .then((data) => {
        setStockUnits(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [ctx]);

  useEffect(() => {
    clearProduct();
    setChanged(false);
    if (updateTargetID) {
      ctx
        .getRecord<Product>('/api/products/', updateTargetID)
        .then((data) => {
          setProductInputs({
            code: data.code,
            name: data.name,
            kana: data.kana,
            manufacturer: data.manufacturer,
            stock_unit_code: data.stock_unit.name,
            quantity: getAmountFormat(data.quantity?.toString() ?? '0'),
            unit_price: getAmountFormat(data.unit_price?.toString() ?? '0'),
            temp_zone_code: data.temp_zone.temp_code,
            customer_code: data.customer.code,
            tax_rate_flg: data.tax_rate_flg,
            alert_day: getAmountFormat(data.alert_day?.toString() ?? '0'),
          });
          setCustomerVal(data.customer);
          setTempZoneVal(data.temp_zone);
          setStockUnitVal(data.stock_unit);
        })
        .catch((e) => {
          console.error(e);
        });
    }
    clearProductError();
  }, [ctx, updateTargetID, open]);

  return (
    <Dialog open={open} fullWidth maxWidth="xl">
      <DialogContent>
        <Grid container spacing={6}>
          <MasterManageDialogHeaderButtons
            url="/api/products/"
            object={productInputs}
            clearObjFunc={clearProduct}
            clearErrorFunc={clearProductError}
            handleCloseDialog={handleCloseDialog}
            setAlertMessage={setAlertMessage}
            setErrorFunc={setProductInputsError}
            updateTargetID={updateTargetID}
            setUpdateTargetID={setUpdateTargetID}
            backConfirm={changed}
          />
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item xs={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="code-inputs"
                  label="商品コード"
                  type="text"
                  value={productInputs.code ?? ''}
                  required
                  disabled={!!updateTargetID}
                  onChange={(event) => {
                    setProductInputs({ ...productInputs, code: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={!!productInputsError.code}
                  helperText={productInputsError.code}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <SelectBox
                  id="customer-inputs"
                  items={customers}
                  labelKey="name"
                  idKey="code"
                  boxLabel="取引先"
                  required
                  boxPlaceholder=""
                  itemVal={customerVal}
                  setFunc={(value: Customer) => {
                    setProductInputs({ ...productInputs, customer_code: value?.code ?? '' });
                    setCustomerVal(value);
                    setChanged(true);
                  }}
                  errorMsg={productInputsError.customer_code}
                />
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="name-inputs"
                  label="商品名"
                  type="text"
                  value={productInputs.name ?? ''}
                  required
                  onChange={(event) => {
                    setProductInputs({ ...productInputs, name: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={!!productInputsError.name}
                  helperText={productInputsError.name}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="kana-inputs"
                  label="商品名カナ"
                  type="text"
                  value={productInputs.kana ?? ''}
                  onChange={(event) => {
                    setProductInputs({ ...productInputs, kana: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={!!productInputsError.kana}
                  helperText={productInputsError.kana}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="manufacturer-inputs"
                  label="メーカー"
                  type="text"
                  value={productInputs.manufacturer ?? ''}
                  onChange={(event) => {
                    setProductInputs({ ...productInputs, manufacturer: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={!!productInputsError.manufacturer}
                  helperText={productInputsError.manufacturer}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <SelectBox
                  id="stock_unit-inputs"
                  items={stockUnits}
                  labelKey="name"
                  idKey="name"
                  boxLabel="在庫単位"
                  required
                  boxPlaceholder=""
                  itemVal={stockUnitVal}
                  setFunc={(value: StockUnit) => {
                    setProductInputs({ ...productInputs, stock_unit_code: value?.name ?? '' });
                    setStockUnitVal(value);
                    setChanged(true);
                  }}
                  errorMsg={productInputsError.stock_unit_code}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="quantity-inputs"
                  label="入数"
                  type="number"
                  value={productInputs.quantity ?? ''}
                  onChange={(event) => {
                    setProductInputs({ ...productInputs, quantity: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={!!productInputsError.quantity}
                  helperText={productInputsError.quantity}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="unit_price-inputs"
                  label="単価"
                  type="number"
                  value={productInputs.unit_price ?? ''}
                  onChange={(event) => {
                    setProductInputs({ ...productInputs, unit_price: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={!!productInputsError.unit_price}
                  helperText={productInputsError.unit_price}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <SelectBox
                  id="temp_zone-inputs"
                  items={tempZones}
                  labelKey="temp_name"
                  idKey="temp_code"
                  boxLabel="温度帯"
                  required
                  boxPlaceholder=""
                  itemVal={tempZoneVal}
                  setFunc={(value: TempZone) => {
                    setProductInputs({ ...productInputs, temp_zone_code: value?.temp_code ?? '' });
                    setTempZoneVal(value);
                    setChanged(true);
                  }}
                  errorMsg={productInputsError.temp_zone_code}
                />
              </Grid>
              <Grid item xs={4}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={(productInputs.tax_rate_flg as boolean) ?? false}
                        onChange={(event) => {
                          setProductInputs({ ...productInputs, tax_rate_flg: event.target.checked });
                          setChanged(true);
                        }}
                      />
                    }
                    label="軽減税率対象フラグ"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="alert_day-inputs"
                  label="基準日"
                  type="number"
                  value={productInputs.alert_day ?? ''}
                  onChange={(event) => {
                    setProductInputs({ ...productInputs, alert_day: event.target.value ? event.target.value : null });
                    setChanged(true);
                  }}
                  size="small"
                  error={!!productInputsError.alert_day}
                  helperText={productInputsError.alert_day}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ProductManageDialog;
