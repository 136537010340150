import React, { Fragment, useCallback, useContext, useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, Grid, TextField, Typography } from '@mui/material';
import { AppRegistration, ManageSearch } from '@mui/icons-material';
import { AxiosError } from 'axios';
import type { ShipmentHeader } from './types';
import type { ErrorMessages } from '../types';
import type { Warehouse } from '../berth/types';
import { apiContext } from '../../hooks/call_apis';
import { CommonTable } from '../common_table';
import { SelectBox } from '../auto_complete_select_box';
import ConfirmationDialog from '../confirmation_dialog';
import { getAmountFormat } from '../get_format';
import BackButton from '../back_button';

const BulkShipmentDialog: React.FC<{
  setAlertMessage: CallableFunction;
  handleCloseDialog: () => void;
  open: boolean;
}> = ({ setAlertMessage, handleCloseDialog, open }) => {
  const [shipmentHeaders, setShipmentHeaders] = useState<ShipmentHeader[]>([]);
  const [shipFroms, setShipFroms] = useState<Warehouse[]>([]);

  const [preferredDateVal, setPreferredDateVal] = useState<string | null>(null);
  const [shipmentDateVal, setShipmentDateVal] = useState<string | null>(null);
  const [shipFromVal, setShipFromVal] = useState<Warehouse | null>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);

  const [shipmentDateError, setShipmentDateError] = useState<string | null>(null);

  const ctx = useContext(apiContext);
  const onClose = () => {
    handleCloseDialog();
  };

  const onSubmit = () => {
    setOpenConfirmDialog(true);
  };

  const columns = [{ header: '商品コード' }, { header: '商品名' }, { header: '納品数' }, { header: '引当数' }];
  const tableList = shipmentHeaders.map((header) => {
    const rows = header.received_order.received_order_items.map((order_item) => {
      const warningColor = order_item.allocated_quantity !== Number(order_item.delivery_quantity) ? 'red' : '';
      return [
        { display: order_item.item.code, val: order_item.code, color: warningColor },
        { display: order_item.item.name, color: warningColor },
        { display: getAmountFormat(order_item.delivery_quantity.toString()), color: warningColor },
        { display: order_item.allocated_quantity, color: warningColor },
      ];
    });
    return (
      <Fragment key={header.code}>
        <Grid item xs={12}>
          <Typography>{`${header.received_order.order_number} ${header.received_order.department.office.name} ${header.received_order.department.name}`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <CommonTable columns={columns} idColumn={0} rows={rows} pagination={false} />
        </Grid>
      </Fragment>
    );
  });

  useEffect(() => {
    setPreferredDateVal(null);
    setShipmentDateVal(null);
    setShipFromVal(null);
    setShipmentHeaders([]);
    setShipmentDateError(null);
    setMessage(null);
    ctx
      .getRecordList<Warehouse>('/api/warehouses/', {})
      .then((data) => {
        setShipFroms(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [ctx, open]);

  const getShipmentHeaders = useCallback(() => {
    if (preferredDateVal && shipFromVal) {
      ctx
        .getRecordList<ShipmentHeader>('/api/stock/bulk-shipments', {
          ship_from: shipFromVal.warehouse_code,
          date: preferredDateVal,
        })
        .then((data) => {
          setShipmentHeaders(data);
        })
        .catch((e: AxiosError<ErrorMessages>) => {
          if (e.response) {
            setMessage(e.response.data.general);
          }
          setAlertMessage({
            open: true,
            message: '出荷情報の取得に失敗しました。',
            severity: 'error',
          });
          console.error(e);
          setShipmentHeaders([]);
        });
    }
  }, [ctx, preferredDateVal, shipFromVal, setAlertMessage]);

  const onClickSearch = () => {
    setMessage(null);
    getShipmentHeaders();
  };

  const submitShipment = () => {
    setShipmentDateError(null);
    setMessage(null);
    ctx
      .postAPI('/api/stock/bulk-shipments', {
        shipment_headers: shipmentHeaders.map((header) => header.code),
        shipment_date: shipmentDateVal,
      })
      .then(() => {
        handleCloseDialog();
        setAlertMessage({
          open: true,
          message: '出荷を確定しました。',
          severity: 'success',
        });
      })
      .catch((e: AxiosError<ErrorMessages>) => {
        if (e.response) {
          setShipmentDateError(e.response.data.shipment_date);
          if (e.response.data.general) {
            setMessage(e.response.data.general);
            getShipmentHeaders();
          }
        }
        setAlertMessage({
          open: true,
          message: '出荷を確定できませんでした。',
          severity: 'error',
        });
        console.error(e);
      });
    setOpenConfirmDialog(false);
  };

  return (
    <>
      <Dialog open={open} maxWidth="xl">
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item>
                  <BackButton onClose={onClose} requireConfirm={shipmentHeaders.length > 0} />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={onClickSearch}
                    startIcon={<ManageSearch />}
                    sx={{ width: 150 }}
                    disabled={!shipFromVal || !preferredDateVal}
                  >
                    検索
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={onSubmit}
                    startIcon={<AppRegistration />}
                    sx={{ width: 150 }}
                    disabled={shipmentHeaders.length === 0}
                  >
                    出荷確定
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography>一括出荷を行う出荷元と納品希望日を選択して検索してください。</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={5}>
                <Grid item xs={4}>
                  <SelectBox
                    id="ship_from-inputs"
                    items={shipFroms}
                    labelKey="warehouse_name"
                    idKey="warehouse_code"
                    boxLabel="出荷元"
                    boxPlaceholder=""
                    itemVal={shipFromVal}
                    setFunc={(value: Warehouse) => setShipFromVal(value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label="納品希望日"
                    type="date"
                    value={preferredDateVal ?? ''}
                    onChange={(event) => setPreferredDateVal(event.target.value)}
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label="出荷日"
                    type="date"
                    value={shipmentDateVal ?? ''}
                    onChange={(event) => setShipmentDateVal(event.target.value)}
                    size="small"
                    error={!!shipmentDateError}
                    helperText={shipmentDateError}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ color: 'red' }}>{message}</Typography>
            </Grid>
            {tableList}
          </Grid>
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        message={<Typography>確定した出荷内容は変更できません。出荷内容を確定しますか。</Typography>}
        open={openConfirmDialog}
        setOpenFunc={setOpenConfirmDialog}
        submitFunc={submitShipment}
      />
    </>
  );
};

export default BulkShipmentDialog;
