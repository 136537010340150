import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

import type { Cell } from '../../components/types';
import type { StockUnit } from '../../components/stock/types';
import { CommonTable, Handler } from '../../components/common_table';
import { MessageSnackbar, AlertMessage } from '../../components/message_snackbar';
import Header from '../../components/header';
import { StockUnitsSearchBox } from '../../components/stock/stock_units_search_box';
import StockUnitManageDialog from '../../components/stock/stock_unit_manage_dialog';
import { apiContext } from '../../hooks/call_apis';

const Buttons: React.FC<{
  handleOpenDialogFunc: CallableFunction;
  search: CallableFunction;
}> = ({ handleOpenDialogFunc, search }) => {
  const handleOpenStockUnitDialog = () => {
    handleOpenDialogFunc();
  };
  const handleClickSearch = () => {
    search();
  };
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button id="search-stockUnits" variant="contained" startIcon={<ManageSearchIcon />} onClick={handleClickSearch}>
          検索
        </Button>
      </Grid>
      <Grid item>
        <Button
          id="create-stockUnit"
          variant="contained"
          color="success"
          startIcon={<AppRegistrationIcon />}
          onClick={handleOpenStockUnitDialog}
        >
          新規登録
        </Button>
      </Grid>
    </Grid>
  );
};

const StockUnitPage: React.FC = () => {
  const [openStockUnitDialog, setOpenStockUnitDialog] = useState<boolean>(false);
  const [updateStockUnit, setUpdateStockUnit] = useState<string | null>(null);

  const [rows, setRows] = useState<Cell[][]>([[]]);

  const ctx = useContext(apiContext);

  const [nameQuery, setNameQuery] = useState<string | null>(null);

  const tableRef = useRef({} as Handler);
  const getStockUnitTable = () => {
    ctx
      .getRecordList<StockUnit>('/api/stock-units/', {
        name: nameQuery ?? '',
      })
      .then((data) => {
        setRows(data.map((stockUnit) => [{ display: stockUnit.name, val: stockUnit.name }]));
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const search = () => {
    getStockUnitTable();
    tableRef.current?.initializePage();
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseDialog = () => {
    setOpenStockUnitDialog(false);
    getStockUnitTable();
  };

  const handleOpenStockUnitDialog = (targetCode?: string) => {
    if (targetCode !== undefined) setUpdateStockUnit(targetCode);
    else setUpdateStockUnit(null);
    setOpenStockUnitDialog(true);
  };

  const [alertMessage, setAlertMessage] = useState<AlertMessage>({ open: false, message: '', severity: 'success' });

  const columns = [{ header: '在庫単位表示', columnType: 'Button', columnFunc: handleOpenStockUnitDialog }];

  return (
    <>
      <MessageSnackbar alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
      <Header screenName="在庫単位マスタ" />
      <Box sx={{ margin: '2%' }}>
        <Grid container justifyContent="center" spacing={6}>
          <Grid item xs={12}>
            <Buttons handleOpenDialogFunc={handleOpenStockUnitDialog} search={search} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={6}>
              <Grid item xs={4}>
                <StockUnitsSearchBox nameVal={nameQuery} setNameVal={setNameQuery} />
              </Grid>
              <Grid item xs={8}>
                <CommonTable columns={columns} idColumn={0} rows={rows} ref={tableRef} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <StockUnitManageDialog
        setAlertMessage={setAlertMessage}
        handleCloseDialog={() => handleCloseDialog()}
        open={openStockUnitDialog}
        updateTargetID={updateStockUnit}
        setUpdateTargetID={setUpdateStockUnit}
      />
    </>
  );
};

export default StockUnitPage;
