import React, { useContext, useEffect, useState } from 'react';
import { Grid, TextField } from '@mui/material';

import type { Customer, TempZone } from '../berth/types';
import { SelectBox } from '../auto_complete_select_box';
import { apiContext } from '../../hooks/call_apis';

export const ProductsSearchBox: React.FC<{
  customerVal: Customer | null;
  setCustomerVal: CallableFunction;
  nameVal: string | null;
  setNameVal: CallableFunction;
  kanaVal: string | null;
  setKanaVal: CallableFunction;
  tempZoneVal: TempZone | null;
  setTempZoneVal: CallableFunction;
}> = ({ customerVal, setCustomerVal, nameVal, setNameVal, kanaVal, setKanaVal, tempZoneVal, setTempZoneVal }) => {
  const ctx = useContext(apiContext);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [tempZones, setTempZones] = useState<TempZone[]>([]);

  useEffect(() => {
    ctx
      .getRecordList<Customer>('/api/customers/', {})
      .then((data) => {
        setCustomers(data);
      })
      .catch((e) => {
        console.error(e);
      });
    ctx
      .getRecordList<TempZone>('/api/temp-zones/', {})
      .then((data) => {
        setTempZones(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [ctx]);

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <SelectBox
          id="customer-search"
          items={customers}
          labelKey="name"
          idKey="code"
          boxLabel="取引先"
          boxPlaceholder="取引先を選択"
          itemVal={customerVal}
          setFunc={setCustomerVal}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          InputLabelProps={{ shrink: true }}
          id="name-search"
          label="商品名"
          type="text"
          value={nameVal ?? ''}
          onChange={(event) => {
            setNameVal(event.target.value);
          }}
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          InputLabelProps={{ shrink: true }}
          id="kana-search"
          label="商品名カナ"
          type="text"
          value={kanaVal ?? ''}
          onChange={(event) => {
            setKanaVal(event.target.value);
          }}
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <SelectBox
          id="temp_zone-search"
          items={tempZones}
          labelKey="temp_name"
          idKey="temp_code"
          boxLabel="温度帯"
          boxPlaceholder="温度帯を選択"
          itemVal={tempZoneVal}
          setFunc={setTempZoneVal}
        />
      </Grid>
    </Grid>
  );
};

export default ProductsSearchBox;
