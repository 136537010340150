import React, { useContext, useEffect, useState } from 'react';
import { Button, Dialog, DialogContent, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { AppRegistration } from '@mui/icons-material';
import { AxiosError } from 'axios';

import type { ErrorMessages } from '../types';
import type { Product } from './types';
import type { Warehouse } from '../berth/types';
import { apiContext } from '../../hooks/call_apis';
import ConfirmationDialog from '../confirmation_dialog';
import BackButton from '../back_button';

const AddInventoryDialog: React.FC<{
  handleCloseDialog: () => void;
  targetProduct: Product;
  targetWarehouse: Warehouse;
  open: boolean;
  setAlertMessage: CallableFunction;
}> = ({ handleCloseDialog, targetProduct, targetWarehouse, open, setAlertMessage }) => {
  const [expirationDateVal, setExpirationDateVal] = useState<string | null>(null);
  const [quantityVal, setQuantityVal] = useState<number | null>(null);
  const [remarksVal, setRemarksVal] = useState<string | null>(null);
  const [changed, setChanged] = useState<boolean>(false);

  const [quantityError, setQuantityError] = useState<string | null>(null);
  const [expirationDateError, setExpirationDateError] = useState<string | null>(null);
  const [remarksError, setRemarksError] = useState<string | null>(null);
  const onClose = () => {
    handleCloseDialog();
  };
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  const message = <Typography>登録した棚卸内容は変更できません。棚卸内容を登録しますか。</Typography>;

  const onSubmit = () => {
    setOpenConfirmDialog(true);
  };
  useEffect(() => {
    setQuantityError(null);
    setQuantityVal(null);
    setRemarksError(null);
    setRemarksVal(null);
    setExpirationDateError(null);
    setExpirationDateVal(null);
    setChanged(false);
  }, [open]);

  const ctx = useContext(apiContext);
  const submitInventoryNewStock = () => {
    setExpirationDateError(null);
    setQuantityError(null);
    setRemarksError(null);
    ctx
      .create('/api/stock/inventory-new-stock', {
        product: targetProduct.code,
        place: targetWarehouse.warehouse_code,
        expiration_date: expirationDateVal,
        quantity: quantityVal,
        remarks: remarksVal,
      })
      .then(() => {
        setAlertMessage({
          open: true,
          message: '棚卸の登録に成功しました。',
          severity: 'success',
        });
        handleCloseDialog();
      })
      .catch((e: AxiosError<ErrorMessages>) => {
        if (e.response) {
          setQuantityError(e.response.data.quantity);
          setRemarksError(e.response.data.remarks);
          setExpirationDateError(e.response.data.expiration_date);
        }
        setAlertMessage({
          open: true,
          message: '棚卸の登録に失敗しました。',
          severity: 'error',
        });
        console.error(e);
      });
    setOpenConfirmDialog(false);
  };

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="md">
        <DialogContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item>
                  <BackButton onClose={onClose} requireConfirm={changed} />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={onSubmit}
                    startIcon={<AppRegistration />}
                    sx={{ width: 150 }}
                  >
                    追加
                  </Button>
                </Grid>
                <Grid item>
                  <Typography variant="h6">
                    {`${targetWarehouse.warehouse_name} ー ${targetProduct.name}（${targetProduct.code}）`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={5}>
                <Grid item xs={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label="賞味期限/消費期限"
                    type="date"
                    value={expirationDateVal ?? ''}
                    onChange={(event) => {
                      setExpirationDateVal(event.target.value);
                      setChanged(true);
                    }}
                    size="small"
                    error={!!expirationDateError}
                    helperText={expirationDateError}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label="棚卸数量"
                    type="number"
                    value={quantityVal ?? ''}
                    onChange={(event) => {
                      setQuantityVal(Number(event.target.value));
                      setChanged(true);
                    }}
                    size="small"
                    error={!!quantityError}
                    helperText={quantityError}
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="start">{targetProduct.stock_unit.name}</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label="備考"
                    value={remarksVal ?? ''}
                    onChange={(event) => {
                      setRemarksVal(event.target.value);
                      setChanged(true);
                    }}
                    size="small"
                    error={!!remarksError}
                    helperText={remarksError}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        message={message}
        open={openConfirmDialog}
        setOpenFunc={setOpenConfirmDialog}
        submitFunc={submitInventoryNewStock}
      />
    </>
  );
};

export default AddInventoryDialog;
