import React, { useContext, useEffect, useState } from 'react';

import type { Warehouse } from './types';
import { MultiSelectBox } from '../auto_complete_select_box';
import { apiContext } from '../../hooks/call_apis';

const BerthReservationsSearchBox: React.FC<{
  warehouseVals: Warehouse[];
  setWarehouseFunc: CallableFunction;
}> = ({ warehouseVals, setWarehouseFunc }) => {
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const ctx = useContext(apiContext);
  useEffect(() => {
    ctx
      .getRecordList<Warehouse>('/api/warehouses/', {})
      .then((data) => {
        setWarehouses(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [ctx]);
  return (
    <MultiSelectBox
      id="warehouse-search"
      items={warehouses}
      labelKey="warehouse_name"
      idKey="warehouse_code"
      boxLabel="倉庫"
      boxPlaceholder="倉庫を選択"
      itemVals={warehouseVals}
      setFunc={setWarehouseFunc}
      helperText="表示する倉庫を選択してください。"
    />
  );
};

export default BerthReservationsSearchBox;
