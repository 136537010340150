import React, { useContext } from 'react';
import { Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { Navbar, Nav, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './mystyle.css';
import { apiContext } from '../hooks/call_apis';
import logo from '../static/jm_slide_rogo.png';
import MainMenu from './main_menu';

const Header: React.FC<{
  screenName: string;
}> = ({ screenName }) => {
  const { username, logout } = useContext(apiContext);

  const onLogout = () => {
    logout();
  };
  return (
    <>
      <Navbar bg="custom" variant="custom">
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} width="50" height="50" alt="Company logo" className="d-inline-block align-top" />
          </Navbar.Brand>
          <Typography variant="h3" sx={{ padding: 2, fontWeight: 'lighter' }}>
            {screenName}
          </Typography>
          <Nav className="mr-auto">
            {username && (
              <Typography variant="h6" sx={{ paddingTop: 1 }} id="username">
                {username}でログイン中...
              </Typography>
            )}
            <Nav.Link href="/login" onClick={onLogout} id="logout">
              <Typography variant="h6">
                LOGOUT
                <LogoutIcon />
              </Typography>
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <MainMenu />
    </>
  );
};
export default Header;
