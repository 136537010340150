import { styled } from '@mui/material/styles';
import { TableRow, TableCell } from '@mui/material';

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const StyledTableCell = styled(TableCell)(() => ({
  '&.MuiTableCell-root': {
    border: '2px solid rgba(200, 200, 200, 1)',
  },
  fontSize: 18,
  padding: 4,
  width: 150,
  height: 30,
}));

export const StyledHeaderCell = styled(TableCell)(() => ({
  '&.MuiTableCell-root': {
    border: '2px solid rgba(200, 200, 200, 1)',
  },
  fontSize: 18,
  fontWeight: 'bold',
  padding: 4,
  width: 150,
}));
