import React, { useContext, useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, Grid, TextField, Typography } from '@mui/material';
import { AppRegistration, Print } from '@mui/icons-material';

import type { Cell, ErrorMessages } from '../types';
import type { PurchaseOrderHeader, PurchaseOrderItem } from './types';
import type { Customer, Warehouse } from '../berth/types';
import { CommonTable } from '../common_table';
import { SelectBox } from '../auto_complete_select_box';
import AddPurchaseOrderItemDialog from './add_purchase_order_item_dialog';
import PrintPurchaseOrderDialog from './print_purchase_order_dialog';
import { apiContext } from '../../hooks/call_apis';
import { getAmountFormat } from '../get_format';
import MasterManageDialogHeaderButtons from '../master_manage_dialog_header_buttons';

const PurchaseOrderManageDialog: React.FC<{
  setAlertMessage: CallableFunction;
  handleCloseDialog: () => void;
  open: boolean;
  updateTargetID: string | null;
  setUpdateTargetID: CallableFunction;
}> = ({ setAlertMessage, handleCloseDialog, open, updateTargetID, setUpdateTargetID }) => {
  const ctx = useContext(apiContext);

  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);

  const [storeInVal, setStoreInVal] = useState<Warehouse | null>(null);
  const [estimatedTimeOfArrivalVal, setEstimatedTimeOfArrivalVal] = useState<string | null>();
  const [customerVal, setCustomerVal] = useState<Customer | null>(null);
  const [orderItemList, setOrderItemList] = useState<PurchaseOrderItem[]>([]);
  const [changed, setChanged] = useState<boolean>(false);

  const [purchaseOrderHeaderError, setPurchaseOrderHeaderError] = useState<ErrorMessages>({});

  const purchaseOrderHeaderInputs = {
    store_in_code: storeInVal?.warehouse_code ?? '',
    customer_code: customerVal?.code ?? '',
    estimated_date_of_arrival: estimatedTimeOfArrivalVal ?? '',
    purchase_order_items: orderItemList.map((item) => ({
      update_code: item.code ?? null,
      item_code: item.item.code,
      quantity: item.quantity,
    })),
  };

  const clearInputs = () => {
    setStoreInVal(null);
    setCustomerVal(null);
    setEstimatedTimeOfArrivalVal(null);
    setOrderItemList([]);
  };

  const clearPurchaseOrderHeaderError = () => {
    setPurchaseOrderHeaderError({
      customer_code: null,
      store_in_code: null,
      estimated_date_of_arrival: null,
      purchase_order_items: null,
    });
  };

  const includesArrival = orderItemList.find((item) => item.arrival_quantity);

  useEffect(() => {
    clearPurchaseOrderHeaderError();
    setChanged(false);
    if (updateTargetID) {
      ctx
        .getRecord<PurchaseOrderHeader>('/api/purchase-order-headers/', updateTargetID)
        .then((data) => {
          setCustomerVal(data.customer);
          setStoreInVal(data.store_in);
          setEstimatedTimeOfArrivalVal(data.estimated_date_of_arrival);
          setOrderItemList(data.purchase_order_items);
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      clearInputs();
    }
  }, [ctx, updateTargetID, open]);

  const [openAddPurchaseOrderItemDialog, setOpenAddPurchaseOrderItemDialog] = useState<boolean>(false);
  const [openPrintPurchaseOrderDialog, setOpenPrintPurchaseOrderDialog] = useState<boolean>(false);

  const deleteItem = (targetIndex: number) => {
    setOrderItemList(orderItemList.filter((item, index) => index !== targetIndex));
    setChanged(true);
  };

  const setQuantityToInputs = (val: number, rowIndex: number) => {
    setOrderItemList(orderItemList.map((item, index) => (index === rowIndex ? { ...item, quantity: val } : item)));
    setChanged(true);
  };

  const headers = [
    { header: '商品コード' },
    { header: '商品名', width: '30%' },
    { header: 'メーカー' },
    { header: '数量', columnType: 'TextField-number', columnFunc: setQuantityToInputs },
    { header: '単位' },
    { header: '入数' },
    { header: '単価' },
    { header: '温度帯' },
    { header: '削除', columnType: 'Button', columnFunc: deleteItem },
  ];

  const rows: Cell[][] = orderItemList.map((item, index) => [
    { display: item.item.code, val: item.code ?? index },
    { display: item.item.name },
    { display: item.item.manufacturer },
    { display: '', val: getAmountFormat(item.quantity.toString()) },
    { display: item.item.stock_unit.name },
    { display: item.item.quantity ? getAmountFormat(item.item.quantity.toString()) : '' },
    { display: item.item.unit_price ? getAmountFormat(item.item.unit_price.toString()) : '' },
    { display: item.item.temp_zone.temp_name },
    { display: item.arrival_quantity ? '-' : '削除', val: index, disabled: !!item.arrival_quantity },
  ]);

  const extraButtons = [
    <Button
      variant="contained"
      color="success"
      onClick={() => setOpenAddPurchaseOrderItemDialog(true)}
      sx={{ width: 150 }}
      disabled={!customerVal}
      startIcon={<AppRegistration />}
    >
      商品追加
    </Button>,
    <Button
      variant="contained"
      color="success"
      onClick={() => setOpenPrintPurchaseOrderDialog(true)}
      sx={{ width: 150 }}
      disabled={!updateTargetID}
      startIcon={<Print />}
    >
      発注書出力
    </Button>,
  ];

  useEffect(() => {
    ctx
      .getRecordList<Warehouse>('/api/warehouses/', {})
      .then((data) => {
        setWarehouses(data);
      })
      .catch((e) => {
        console.error(e);
      });
    ctx
      .getRecordList<Customer>('/api/stock/product-suppliers', {})
      .then((data) => {
        setCustomers(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [ctx]);

  const itemErrors = () => {
    if (Array.isArray(purchaseOrderHeaderError.purchase_order_items)) {
      return purchaseOrderHeaderError.purchase_order_items
        .filter((item: { quantity: string }) => item.quantity)
        .map((item: { quantity: string }, index) => (
          <Grid item xs={12}>
            <Typography color="red">{`${index + 1}行目（数量）:${item.quantity}`}</Typography>
          </Grid>
        ));
    }
    return [];
  };
  return (
    <>
      <Dialog open={open} maxWidth="xl">
        <DialogContent>
          <Grid container spacing={6}>
            <MasterManageDialogHeaderButtons
              url="/api/purchase-order-headers/"
              object={purchaseOrderHeaderInputs}
              clearObjFunc={clearInputs}
              clearErrorFunc={clearPurchaseOrderHeaderError}
              handleCloseDialog={handleCloseDialog}
              setAlertMessage={setAlertMessage}
              setErrorFunc={setPurchaseOrderHeaderError}
              updateTargetID={updateTargetID}
              setUpdateTargetID={setUpdateTargetID}
              deleteDisabled={!!includesArrival}
              extraButtons={extraButtons}
              backConfirm={changed}
            />
            <Grid item xs={12}>
              <Grid container spacing={5}>
                <Grid item xs={4}>
                  <SelectBox
                    id="customer-inputs"
                    items={customers}
                    labelKey="name"
                    idKey="code"
                    boxLabel="取引先"
                    boxPlaceholder=""
                    itemVal={customerVal}
                    setFunc={(value: Customer) => {
                      setCustomerVal(value);
                      setChanged(true);
                    }}
                    errorMsg={purchaseOrderHeaderError.customer_code}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SelectBox
                    id="store_in-inputs"
                    items={warehouses}
                    labelKey="warehouse_name"
                    idKey="warehouse_code"
                    boxLabel="入荷先"
                    boxPlaceholder=""
                    itemVal={storeInVal}
                    setFunc={(value: Warehouse) => {
                      setStoreInVal(value);
                      setChanged(true);
                    }}
                    errorMsg={purchaseOrderHeaderError.store_in_code}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="estimated_date_of_arrival"
                    label="入荷予定日"
                    type="date"
                    value={estimatedTimeOfArrivalVal ?? ''}
                    onChange={(event) => {
                      setEstimatedTimeOfArrivalVal(event.target.value);
                      setChanged(true);
                    }}
                    size="small"
                    error={!!purchaseOrderHeaderError.estimated_date_of_arrival}
                    helperText={purchaseOrderHeaderError.estimated_date_of_arrival}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {itemErrors()}
                <Grid item xs={12}>
                  <Typography color="red">{purchaseOrderHeaderError.no_item}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">発注明細</Typography>
                </Grid>
                <Grid item xs={12}>
                  <CommonTable columns={headers} idColumn={0} rows={rows} emptyMsg="明細が登録されていません。" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <AddPurchaseOrderItemDialog
        orderItemList={orderItemList}
        setOrderItemList={(itemList: PurchaseOrderItem[]) => {
          setOrderItemList(itemList);
          setChanged(true);
        }}
        handleCloseDialog={() => setOpenAddPurchaseOrderItemDialog(false)}
        customer={customerVal}
        open={openAddPurchaseOrderItemDialog}
      />
      {updateTargetID && (
        <PrintPurchaseOrderDialog
          handleCloseDialog={() => setOpenPrintPurchaseOrderDialog(false)}
          open={openPrintPurchaseOrderDialog}
          printTarget={updateTargetID}
        />
      )}
    </>
  );
};

export default PurchaseOrderManageDialog;
