import React from 'react';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface SelectBoxProps<T> {
  id: string;
  items: T[];
  labelKey: keyof T;
  idKey: keyof T;
  boxLabel: string;
  boxPlaceholder: string;
  itemVal: T | null;
  setFunc: CallableFunction;
  helperText?: string | null;
  errorMsg?: string | null;
  disabled?: boolean;
  required?: boolean;
  widthVal?: string;
}

export const SelectBox = <T,>({
  id,
  items,
  labelKey,
  idKey,
  boxLabel,
  boxPlaceholder,
  itemVal,
  setFunc,
  helperText = null,
  errorMsg = null,
  disabled = false,
  required = false,
  widthVal = '1',
}: SelectBoxProps<T>) => (
  <Autocomplete
    options={items}
    id={id}
    value={itemVal}
    disabled={disabled}
    /* eslint-disable @typescript-eslint/no-unsafe-return */
    onChange={(event, value: T | null) => setFunc(value)}
    size="small"
    sx={{ width: widthVal }}
    getOptionLabel={(option: T) => option[labelKey] as string}
    isOptionEqualToValue={(option, v) => option[idKey] === v[idKey]}
    renderOption={(props, option) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <li {...props} key={option[idKey] as string}>
        {option[labelKey] as string}
      </li>
    )}
    renderInput={(params) => (
      <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...params}
        label={boxLabel}
        placeholder={boxPlaceholder}
        InputLabelProps={{ shrink: true }}
        error={!!errorMsg}
        helperText={errorMsg ?? helperText}
        required={required}
      />
    )}
  />
);

interface MultiSelectBoxProps<T> {
  id: string;
  items: T[];
  labelKey: keyof T;
  idKey: keyof T;
  boxLabel: string;
  boxPlaceholder: string;
  itemVals: T[];
  setFunc: CallableFunction;
  helperText?: string | null;
  errorMsg?: string | null;
  disabled?: boolean;
  required?: boolean;
  widthVal?: string;
}

export const MultiSelectBox = <T,>({
  id,
  items,
  labelKey,
  idKey,
  boxLabel,
  boxPlaceholder,
  itemVals,
  setFunc,
  helperText = null,
  errorMsg = null,
  disabled = false,
  required = false,
  widthVal = '1',
}: MultiSelectBoxProps<T>) => (
  <Autocomplete
    multiple
    id={id}
    options={items}
    disabled={disabled}
    value={itemVals}
    sx={{ width: widthVal }}
    size="small"
    getOptionLabel={(option: T) => option[labelKey] as string}
    isOptionEqualToValue={(option, v) => option[idKey] === v[idKey]}
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    onChange={(_, value: T[]) => setFunc(value)}
    renderOption={(props, option: T, { selected }) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <li {...props} key={option[idKey] as string}>
        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
        {option[labelKey] as string}
      </li>
    )}
    style={{ width: widthVal }}
    renderInput={(params) => (
      <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...params}
        label={boxLabel}
        placeholder={boxPlaceholder}
        error={!!errorMsg}
        required={required}
        helperText={errorMsg ?? helperText}
      />
    )}
  />
);
