export const getDateFormat = (date: Date) =>
  `${date.getFullYear()}-${`0${(date.getMonth() + 1).toString()}`.slice(-2)}-${`0${date.getDate().toString()}`.slice(
    -2
  )}`;
export const getTimeFormat = (time: string) => `${time.slice(0, 5)}`;
export const getDatetimeFormat = (datetime: Date) =>
  `${datetime.getFullYear()}-${
    datetime.getMonth() + 1
  }-${datetime.getDate()} ${datetime.getHours()}:${datetime.getMinutes()}`;
export const getNumberFormat = (num: string) => `00000000000000${num}`.slice(-14);
export const getAmountFormat = (amount: string) => (amount.split('.')[1] === '00' ? amount.split('.')[0] : amount);
