import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

import type { Cell } from '../../components/types';
import type { Product } from '../../components/stock/types';
import type { Customer, TempZone } from '../../components/berth/types';
import { CommonTable, Handler } from '../../components/common_table';
import { MessageSnackbar, AlertMessage } from '../../components/message_snackbar';
import Header from '../../components/header';
import { ProductsSearchBox } from '../../components/stock/products_search_box';
import ProductManageDialog from '../../components/stock/product_manage_dialog';
import { apiContext } from '../../hooks/call_apis';
import { getAmountFormat } from '../../components/get_format';

const Buttons: React.FC<{
  handleOpenDialogFunc: CallableFunction;
  search: CallableFunction;
}> = ({ handleOpenDialogFunc, search }) => {
  const handleOpenProductDialog = () => {
    handleOpenDialogFunc();
  };
  const handleClickSearch = () => {
    search();
  };
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button id="search-products" variant="contained" startIcon={<ManageSearchIcon />} onClick={handleClickSearch}>
          検索
        </Button>
      </Grid>
      <Grid item>
        <Button
          id="create-product"
          variant="contained"
          color="success"
          startIcon={<AppRegistrationIcon />}
          onClick={handleOpenProductDialog}
        >
          新規登録
        </Button>
      </Grid>
    </Grid>
  );
};

const ProductPage: React.FC = () => {
  const [openProductDialog, setOpenProductDialog] = useState<boolean>(false);
  const [updateProduct, setUpdateProduct] = useState<string | null>(null);

  const [rows, setRows] = useState<Cell[][]>([[]]);

  const ctx = useContext(apiContext);

  const [nameQuery, setNameQuery] = useState<string | null>(null);
  const [kanaQuery, setKanaQuery] = useState<string | null>(null);
  const [customerQuery, setCustomerQuery] = useState<Customer | null>(null);
  const [tempZoneQuery, setTempZoneQuery] = useState<TempZone | null>(null);

  const tableRef = useRef({} as Handler);
  const getProductTable = () => {
    ctx
      .getRecordList<Product>('/api/products/', {
        customer: customerQuery?.code ?? '',
        name: nameQuery ?? '',
        kana: kanaQuery ?? '',
        temp_zone: tempZoneQuery?.temp_code ?? '',
      })
      .then((data) => {
        setRows(
          data.map((product) => [
            { display: product.code, val: product.code },
            { display: product.name },
            { display: product.kana },
            { display: product.manufacturer },
            { display: product.stock_unit.name },
            { display: getAmountFormat(product.quantity?.toString() ?? '0') },
            { display: getAmountFormat(product.unit_price?.toString() ?? '0') },
            { display: product.temp_zone.temp_name },
            { display: product.customer.name },
            { display: product.tax_rate_flg ? '対象' : '' },
            { display: product.alert_day },
          ])
        );
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const search = () => {
    getProductTable();
    tableRef.current?.initializePage();
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseDialog = () => {
    setOpenProductDialog(false);
    getProductTable();
  };

  const handleOpenProductDialog = (targetCode?: string) => {
    if (targetCode !== undefined) setUpdateProduct(targetCode);
    else setUpdateProduct(null);
    setOpenProductDialog(true);
  };

  const [alertMessage, setAlertMessage] = useState<AlertMessage>({ open: false, message: '', severity: 'success' });

  const columns = [
    { header: '商品コード', columnType: 'Button', columnFunc: handleOpenProductDialog },
    { header: '商品名', width: '30%' },
    { header: '商品名カナ' },
    { header: 'メーカー' },
    { header: '在庫単位' },
    { header: '入数' },
    { header: '単価' },
    { header: '温度帯' },
    { header: '取引先' },
    { header: '軽減税率対象' },
    { header: '基準日' },
  ];

  return (
    <>
      <MessageSnackbar alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
      <Header screenName="商品マスタ" />
      <Box sx={{ margin: '2%' }}>
        <Grid container justifyContent="center" spacing={6}>
          <Grid item xs={12}>
            <Buttons handleOpenDialogFunc={handleOpenProductDialog} search={search} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={6}>
              <Grid item xs={4}>
                <ProductsSearchBox
                  customerVal={customerQuery}
                  setCustomerVal={setCustomerQuery}
                  nameVal={nameQuery}
                  setNameVal={setNameQuery}
                  kanaVal={kanaQuery}
                  setKanaVal={setKanaQuery}
                  tempZoneVal={tempZoneQuery}
                  setTempZoneVal={setTempZoneQuery}
                />
              </Grid>
              <Grid item xs={8}>
                <CommonTable columns={columns} idColumn={0} rows={rows} width={1750} ref={tableRef} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <ProductManageDialog
        setAlertMessage={setAlertMessage}
        handleCloseDialog={() => handleCloseDialog()}
        open={openProductDialog}
        updateTargetID={updateProduct}
        setUpdateTargetID={setUpdateProduct}
      />
    </>
  );
};

export default ProductPage;
