import React, { useContext, useEffect, useState } from 'react';
import { Grid, TextField } from '@mui/material';

import type { TempZone, Warehouse } from '../berth/types';
import { SelectBox } from '../auto_complete_select_box';
import { apiContext } from '../../hooks/call_apis';

const StockSearchBox: React.FC<{
  placeVal: Warehouse | null;
  setPlaceVal: CallableFunction;
  dateVal: string | null;
  setDateVal: CallableFunction;
  productNameVal: string | null;
  setProductNameVal: CallableFunction;
  tempZoneVal: TempZone | null;
  setTempZoneVal: CallableFunction;
  placeError: string | null;
}> = ({
  placeVal,
  setPlaceVal,
  dateVal,
  setDateVal,
  productNameVal,
  setProductNameVal,
  tempZoneVal,
  setTempZoneVal,
  placeError,
}) => {
  const [places, setPlaces] = useState<Warehouse[]>([]);
  const [tempZones, setTempZones] = useState<TempZone[]>([]);

  const ctx = useContext(apiContext);
  useEffect(() => {
    ctx
      .getRecordList<Warehouse>('/api/warehouses/', {})
      .then((data) => {
        setPlaces(data);
      })
      .catch((e) => {
        console.error(e);
      });
    ctx
      .getRecordList<TempZone>('/api/temp-zones/', {})
      .then((data) => {
        setTempZones(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [ctx]);
  return (
    <Grid container direction="column" spacing={5}>
      <Grid item xs={12}>
        <SelectBox
          id="place-search"
          items={places}
          labelKey="warehouse_name"
          idKey="warehouse_code"
          boxLabel="倉庫"
          boxPlaceholder="倉庫を選択"
          itemVal={placeVal}
          setFunc={setPlaceVal}
          required
          errorMsg={placeError}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          InputLabelProps={{ shrink: true }}
          label="基準日"
          helperText="設定された基準日までの入荷予定数量が論理在庫に加算されます"
          value={dateVal ?? ''}
          type="date"
          onChange={(event) => {
            setDateVal(event.target.value);
          }}
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          InputLabelProps={{ shrink: true }}
          label="商品名"
          value={productNameVal ?? ''}
          type="text"
          onChange={(event) => {
            setProductNameVal(event.target.value);
          }}
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <SelectBox
          id="temp_zone-search"
          items={tempZones}
          labelKey="temp_name"
          idKey="temp_code"
          boxLabel="温度帯"
          boxPlaceholder="温度帯を選択"
          itemVal={tempZoneVal}
          setFunc={setTempZoneVal}
        />
      </Grid>
    </Grid>
  );
};

export default StockSearchBox;
