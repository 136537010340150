import React, { useContext, useEffect, useState } from 'react';
import { Dialog, DialogContent, Grid, Button, ButtonGroup, Typography, TextField, Badge } from '@mui/material';

import type { InputFields, ErrorMessages } from '../types';
import type { ReservationSlot, Warehouse } from './types';
import { apiContext } from '../../hooks/call_apis';
import { SelectBox } from '../auto_complete_select_box';
import { getTimeFormat } from '../get_format';
import ConfirmationDialog from '../confirmation_dialog';
import BackButton from '../back_button';

const Buttons: React.FC<{
  slots: ReservationSlot[];
  reservationInputs: InputFields;
  setReservationInputs: CallableFunction;
  setOpenConfirmFunc: CallableFunction;
}> = ({ slots, reservationInputs, setReservationInputs, setOpenConfirmFunc }) => {
  const handleClickTimeSlot = (slot: ReservationSlot) => {
    setReservationInputs({ ...reservationInputs, start_time: slot.start_time, end_time: slot.end_time });
    setOpenConfirmFunc(true);
  };
  const slotsLists = slots.reduce(
    (newarr, _, i) => (i % 10 ? newarr : [...newarr, slots.slice(i, i + 10)]),
    [] as ReservationSlot[][]
  );

  const buttonGroupList = slotsLists.map((slotlist) => (
    <Grid item xs={4} key={`grid-${slotlist[0].code}`}>
      <ButtonGroup
        orientation="vertical"
        aria-label="vertical contained button group"
        key={`buttonGroup-${slotlist[0].code}`}
      >
        {slotlist.map((slot) => {
          if (slot.status === '推奨')
            return (
              <Badge badgeContent="推奨" color="success" key={`badge-${slot.code}`}>
                <Button
                  key={`button-${slot.code}`}
                  size="large"
                  color="success"
                  onClick={() => handleClickTimeSlot(slot)}
                >
                  {getTimeFormat(slot.start_time.toString())} ~ {getTimeFormat(slot.end_time.toString())}
                </Button>
              </Badge>
            );
          if (slot.status === '利用不可') {
            return (
              <Badge badgeContent="予約不可" color="error" key={`badge-${slot.code}`}>
                <Button
                  disabled
                  key={`button-${slot.code}`}
                  size="large"
                  color="error"
                  onClick={() => handleClickTimeSlot(slot)}
                >
                  {getTimeFormat(slot.start_time.toString())} ~ {getTimeFormat(slot.end_time.toString())}
                </Button>
              </Badge>
            );
          }
          return (
            <Badge key={`badge-${slot.code}`}>
              <Button key={`button-${slot.code}`} size="large" onClick={() => handleClickTimeSlot(slot)}>
                {getTimeFormat(slot.start_time.toString())} ~ {getTimeFormat(slot.end_time.toString())}
              </Button>
            </Badge>
          );
        })}
      </ButtonGroup>
    </Grid>
  ));

  return (
    <Grid container spacing={3}>
      {buttonGroupList}
    </Grid>
  );
};

const ReserveDialog: React.FC<{
  setAlertMessage: CallableFunction;
  handleCloseDialog: CallableFunction;
  open: boolean;
}> = ({ setAlertMessage, handleCloseDialog, open }) => {
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const [warehouseVal, setWarehouseVal] = useState<Warehouse | null>(null);
  const [changed, setChanged] = useState<boolean>(false);

  const [reservationSlots, setReservationSlots] = useState<ReservationSlot[]>([]);

  const [reservationInputs, setReservationInputs] = useState<InputFields>({});
  const [reservationError, setReservationError] = useState<ErrorMessages>({});

  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  const message = (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        以下の内容で予約します。よろしいですか。
      </Grid>
      <Grid item xs={12}>
        {`納品日：${reservationInputs.reservation_date?.toString() ?? ''}`}
      </Grid>
      <Grid item xs={12}>
        {`納品先：${warehouseVal?.warehouse_name ?? ''}`}
      </Grid>
      <Grid item xs={12}>
        {`時間帯：${reservationInputs.start_time?.toString() ?? ''} - ${reservationInputs.end_time?.toString() ?? ''} `}
      </Grid>
    </Grid>
  );

  const ctx = useContext(apiContext);
  useEffect(() => {
    if (reservationInputs.reservation_date && reservationInputs.warehouse) {
      ctx
        .getRecordList<ReservationSlot>('/api/berth/customer/reservation-slots', {
          date: reservationInputs.reservation_date.toString(),
          warehouse: reservationInputs.warehouse.toString(),
        })
        .then((data) => {
          setReservationSlots(data);
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      setReservationSlots([]);
    }
  }, [ctx, reservationInputs]);

  useEffect(() => {
    ctx
      .getRecordList<Warehouse>('/api/warehouses/', {})
      .then((data) => {
        setWarehouses(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [ctx]);

  const clearReservationInputs = () => {
    setReservationInputs({
      warehouse: null,
      reservation_date: null,
      start_time: null,
      end_time: null,
    });
    setWarehouseVal(null);
    setReservationSlots([]);
  };

  const clearReservationError = () => {
    setReservationError({
      warehouse: null,
      reservation_date: null,
      start_time: null,
      end_time: null,
    });
  };

  useEffect(() => {
    clearReservationInputs();
    clearReservationError();
    setChanged(false);
  }, [open]);

  const onSucceedSubmit = () => {
    clearReservationInputs();
    clearReservationError();
    handleCloseDialog();
  };

  const handleCloseReserveDialog = () => {
    handleCloseDialog();
  };

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <BackButton onClose={handleCloseReserveDialog} requireConfirm={changed} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" gutterBottom>
                納品作業時刻予約
              </Typography>
              <Grid container spacing={6}>
                <Grid item xs={4}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <SelectBox
                        id="warehouse-inputs"
                        items={warehouses}
                        labelKey="warehouse_name"
                        idKey="warehouse_code"
                        boxLabel="納品先"
                        boxPlaceholder=""
                        itemVal={warehouseVal}
                        setFunc={(value: Warehouse) => {
                          setReservationInputs({ ...reservationInputs, warehouse: value?.warehouse_code ?? '' });
                          setWarehouseVal(value);
                          setChanged(true);
                        }}
                        errorMsg={reservationError.warehouse}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id="reservation_date-inputs"
                        label="納品日"
                        type="date"
                        value={reservationInputs.reservation_date ?? ''}
                        required
                        onChange={(event) => {
                          setReservationInputs({ ...reservationInputs, reservation_date: event.target.value });
                          setChanged(true);
                        }}
                        size="small"
                        error={!!reservationError.reservation_date}
                        helperText={reservationError.reservation_date}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={8}>
                  {reservationSlots.length !== 0 ? (
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          業務効率化にご協力いただける方は「推奨」の付いた時間帯をご予約ください。
                        </Typography>
                        <Typography variant="body1" color="red">
                          {reservationError.start_time}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Buttons
                          slots={reservationSlots}
                          reservationInputs={reservationInputs}
                          setReservationInputs={setReservationInputs}
                          setOpenConfirmFunc={setOpenConfirmDialog}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Typography variant="body1">
                      {reservationInputs.reservation_date && reservationInputs.warehouse
                        ? '指定された納品先は予約できません。'
                        : '納品先と納品日を指定してください。'}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        inputs={reservationInputs}
        url="/api/berth/customer/reservations"
        message={message}
        onSucceedFunc={onSucceedSubmit}
        setErrorFunc={setReservationError}
        open={openConfirmDialog}
        setAlertMessage={setAlertMessage}
        setOpenFunc={setOpenConfirmDialog}
      />
    </>
  );
};

export default ReserveDialog;
