import React, { useContext, useRef, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { ManageSearch } from '@mui/icons-material';
import { AxiosError } from 'axios';

import type { TempZone, Warehouse } from '../../components/berth/types';
import type { Product, Stock } from '../../components/stock/types';
import type { Column, ErrorMessages } from '../../components/types';
import { MessageSnackbar, AlertMessage } from '../../components/message_snackbar';
import { CommonTable, Handler } from '../../components/common_table';
import { apiContext } from '../../hooks/call_apis';
import Header from '../../components/header';
import InventoryDialog from '../../components/stock/inventory_dialog';
import WasteDialog from '../../components/stock/waste_dialog';
import StockHistoryDialog from '../../components/stock/stock_history_dialog';
import StockSearchBox from '../../components/stock/stocks_search_box';
import { getAmountFormat } from '../../components/get_format';

const Buttons: React.FC<{
  search: CallableFunction;
}> = ({ search }) => {
  const handleClickSearch = () => {
    search();
  };
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button variant="contained" onClick={handleClickSearch} startIcon={<ManageSearch />}>
          検索
        </Button>
      </Grid>
    </Grid>
  );
};

const StockPage: React.FC = () => {
  const WAREHOUSE_USER = 'warehouse_user';

  const [placeQuery, setPlaceQuery] = useState<Warehouse | null>(null);
  const [dateQuery, setDateQuery] = useState<string | null>(null);
  const [productNameQuery, setProductNameQuery] = useState<string | null>(null);
  const [tempZoneQuery, setTempZoneQuery] = useState<TempZone | null>(null);

  const [openInventoryDialog, setOpenInventoryDialog] = useState<boolean>(false);
  const [openWasteDialog, setOpenWasteDialog] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertMessage>({ open: false, message: '', severity: 'success' });
  const [openHistoryDialog, setOpenHistoryDialog] = useState<boolean>(false);
  const [targetProduct, setTargetProduct] = useState<Product | null>(null);
  const [targetStock, setTargetStock] = useState<Stock | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [stocks, setStocks] = useState<Stock[]>([]);

  const [placeQueryError, setPlaceQueryError] = useState<string | null>(null);

  const ctx = useContext(apiContext);

  const handleOpenInventroyDialog = () => {
    setOpenInventoryDialog(true);
  };
  const handleOpenWasteDialog = (targetCode: string) => {
    setTargetStock(stocks.find((item) => item.code === targetCode) ?? null);
    setOpenWasteDialog(true);
  };
  const handleOpenHistoryDialog = () => {
    setOpenHistoryDialog(true);
  };
  const productColumns: Column[] = [
    { header: '商品コード' },
    { header: '商品名', width: '30%' },
    { header: '実在庫数' },
    { header: '論理在庫数' },
    { header: 'メーカー' },
    { header: '単位' },
    { header: '入数' },
    { header: '単価' },
    { header: '温度帯' },
  ];
  if (ctx.authorities.includes(WAREHOUSE_USER)) {
    productColumns.push({ header: '在庫履歴', columnType: 'Button', columnFunc: handleOpenHistoryDialog });
    productColumns.push({ header: '棚卸', columnType: 'Button', columnFunc: handleOpenInventroyDialog });
  }
  const stockColumns: Column[] = [
    { header: 'ロット' },
    { header: '賞味期限/消費期限' },
    { header: '在庫数' },
    { header: '引当可能数' },
  ];
  if (ctx.authorities.includes(WAREHOUSE_USER)) {
    stockColumns.push({ header: '廃棄', columnType: 'Button', columnFunc: handleOpenWasteDialog });
  }

  const productRows = products.map((product) => {
    const row = [
      { display: product.code, val: product.code },
      { display: product.name },
      { display: getAmountFormat(product.physical_stock_quantity?.toString() ?? '0') },
      { display: getAmountFormat(product.logical_stock_quantity?.toString() ?? '0') },
      { display: product.manufacturer },
      { display: product.stock_unit.name },
      { display: getAmountFormat(product.quantity?.toString() ?? '0') },
      { display: getAmountFormat(product.unit_price?.toString() ?? '0') },
      { display: product.temp_zone.temp_name },
    ];
    if (ctx.authorities.includes(WAREHOUSE_USER)) {
      row.push({ display: '在庫履歴' });
      row.push({ display: '棚卸', val: product.code });
    }
    return row;
  });

  const tableRef = useRef({} as Handler);
  const getProducts = () => {
    setStocks([]);
    setTargetProduct(null);
    setPlaceQueryError(null);
    ctx
      .getRecordList<Product>('/api/stock/product-stocks', {
        date: dateQuery ?? '',
        place: placeQuery?.warehouse_code ?? '',
        name: productNameQuery ?? '',
        temp_zone: tempZoneQuery?.temp_code ?? '',
      })
      .then((data) => {
        setProducts(data);
      })
      .catch((e: AxiosError<ErrorMessages>) => {
        if (e.response) {
          setPlaceQueryError(e.response.data.place);
        }
        console.error(e);
      });
  };

  const search = () => {
    getProducts();
    tableRef.current?.initializePage();
  };

  const onClickProduct = (productCode: string | null) => {
    if (productCode && placeQuery) {
      ctx
        .getRecordList<Stock>('/api/stocks/', {
          product: productCode,
          place: placeQuery.warehouse_code,
        })
        .then((data) => {
          setStocks(data);
        })
        .catch((e) => {
          console.error(e);
        });
      setTargetProduct(products.find((item) => item.code === productCode) ?? null);
    }
  };
  const handleCloseDialog = () => {
    setOpenHistoryDialog(false);
    setOpenInventoryDialog(false);
    setOpenWasteDialog(false);
    getProducts();
    tableRef.current?.clearSelect();
  };

  const stockRows = stocks.map((stock) => {
    const row = [
      { display: stock.lot.code, val: stock.lot.code },
      { display: stock.lot.expiration_date },
      { display: getAmountFormat(stock.quantity?.toString() ?? '0') },
      { display: getAmountFormat(stock.allocatable_quantity?.toString() ?? '0') },
    ];
    if (ctx.authorities.includes(WAREHOUSE_USER)) {
      row.push({ display: '廃棄', val: stock.code });
    }
    return row;
  });

  return (
    <>
      <MessageSnackbar alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
      <Header screenName="在庫一覧" />
      <Box sx={{ margin: '2%' }}>
        <Grid container justifyContent="center" spacing={6}>
          <Grid item xs={12}>
            <Buttons search={search} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={6}>
              <Grid item xs={4}>
                <StockSearchBox
                  placeVal={placeQuery}
                  setPlaceVal={setPlaceQuery}
                  dateVal={dateQuery}
                  setDateVal={setDateQuery}
                  productNameVal={productNameQuery}
                  setProductNameVal={setProductNameQuery}
                  tempZoneVal={tempZoneQuery}
                  setTempZoneVal={setTempZoneQuery}
                  placeError={placeQueryError}
                />
              </Grid>
              <Grid item xs={8}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={12}>
                    <Typography> ロット別在庫を確認する商品を選択してください。 </Typography>
                    <CommonTable
                      columns={productColumns}
                      idColumn={0}
                      rows={productRows}
                      width={1750}
                      onClickRowFunc={onClickProduct}
                      ref={tableRef}
                    />
                  </Grid>
                  {targetProduct && (
                    <>
                      <Grid item>
                        <Typography variant="h4">ロット別在庫一覧</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h6"> {`${targetProduct.name}（${targetProduct.code}）`} </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <CommonTable
                          columns={stockColumns}
                          idColumn={0}
                          rows={stockRows}
                          emptyMsg="在庫が存在しません。"
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {ctx.authorities.includes(WAREHOUSE_USER) && targetProduct && placeQuery && (
        <InventoryDialog
          handleCloseDialog={() => handleCloseDialog()}
          open={openInventoryDialog}
          targetProduct={targetProduct}
          targetWarehouse={placeQuery}
          setAlertMessage={setAlertMessage}
        />
      )}
      {ctx.authorities.includes(WAREHOUSE_USER) && targetStock && (
        <WasteDialog
          handleCloseDialog={() => handleCloseDialog()}
          open={openWasteDialog}
          targetStock={targetStock}
          setAlertMessage={setAlertMessage}
        />
      )}
      {ctx.authorities.includes(WAREHOUSE_USER) && targetProduct && placeQuery && (
        <StockHistoryDialog
          handleCloseDialog={() => handleCloseDialog()}
          open={openHistoryDialog}
          targetProduct={targetProduct}
          targetWarehouse={placeQuery}
        />
      )}
    </>
  );
};

export default StockPage;
