import React, { useState, useContext, useEffect } from 'react';
import { Dialog, DialogContent, Grid, TextField } from '@mui/material';

import type { InputFields, ErrorMessages } from '../types';
import type { Group, AppUser } from './types';
import type { Customer } from '../berth/types';
import MasterManageDialogHeaderButtons from '../master_manage_dialog_header_buttons';
import { apiContext } from '../../hooks/call_apis';
import { MultiSelectBox, SelectBox } from '../auto_complete_select_box';

const UserManageDialog: React.FC<{
  setAlertMessage: CallableFunction;
  handleCloseDialog: () => void;
  open: boolean;
  updateTargetID: string | null;
  setUpdateTargetID: CallableFunction;
}> = ({ setAlertMessage, handleCloseDialog, open, updateTargetID, setUpdateTargetID }) => {
  const [groups, setGroups] = useState<Group[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);

  const [groupVals, setGroupVals] = useState<Group[]>([]);
  const [customerVal, setCustomerVal] = useState<Customer | null>(null);
  const [userInputs, setUserInputs] = useState<InputFields>({});
  const [changed, setChanged] = useState<boolean>(false);

  const [userError, setUserError] = useState<ErrorMessages>({});

  const clearUser = () => {
    setUserInputs({
      username: '',
      password: '',
      first_name: '',
      last_name: '',
      email: '',
      customer_code: '',
      groups_code: [],
    });
    setCustomerVal(null);
    setGroupVals([]);
  };

  const clearUserError = () => {
    setUserError({
      username: '',
      password: '',
      first_name: '',
      last_name: '',
      email: '',
      customer_code: '',
      groups_code: '',
    });
  };

  const ctx = useContext(apiContext);
  useEffect(() => {
    ctx
      .getRecordList<Group>('/api/groups/', {})
      .then((data) => {
        setGroups(data);
      })
      .catch((e) => {
        console.error(e);
      });
    ctx
      .getRecordList<Customer>('/api/customers/', {})
      .then((data) => {
        setCustomers(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [ctx]);
  useEffect(() => {
    setChanged(false);
    if (updateTargetID) {
      ctx
        .getRecord<AppUser>('/api/app-users/', updateTargetID)
        .then((data) => {
          setUserInputs({
            username: data.username,
            password: '',
            first_name: data.first_name,
            last_name: data.last_name,
            customer_code: data.customer?.code ?? '',
            groups_code: data.groups.map((group) => group.pk),
            email: data.email,
          });
          setCustomerVal(data.customer);
          setGroupVals(data.groups);
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      clearUser();
    }
    clearUserError();
  }, [ctx, groups, customers, updateTargetID, open]);

  return (
    <Dialog open={open} fullWidth maxWidth="xl">
      <DialogContent>
        <Grid container spacing={6}>
          <MasterManageDialogHeaderButtons
            url="/api/app-users/"
            object={userInputs}
            clearObjFunc={clearUser}
            clearErrorFunc={clearUserError}
            handleCloseDialog={handleCloseDialog}
            setAlertMessage={setAlertMessage}
            setErrorFunc={setUserError}
            updateTargetID={updateTargetID}
            setUpdateTargetID={setUpdateTargetID}
            backConfirm={changed}
          />
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="username-inputs"
                  label="ユーザID"
                  type="text"
                  value={userInputs.username ?? ''}
                  disabled={!!updateTargetID}
                  required
                  onChange={(event) => {
                    setUserInputs({ ...userInputs, username: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={!!userError.username}
                  helperText={userError.username}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="password-inputs"
                  label="パスワード"
                  type="password"
                  value={userInputs.password ?? ''}
                  required={!updateTargetID}
                  onChange={(event) => {
                    setUserInputs({ ...userInputs, password: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={!!userError.password}
                  helperText={
                    userError.password
                      ? userError.password
                      : 'パスワードを変更する場合のみ入力してください。（新規登録時は必須です。）'
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="last_name-inputs"
                  label="氏名（姓）"
                  type="text"
                  value={userInputs.last_name ?? ''}
                  onChange={(event) => {
                    setUserInputs({ ...userInputs, last_name: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={!!userError.last_name}
                  helperText={userError.last_name}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="first_name-inputs"
                  label="氏名（名）"
                  type="text"
                  value={userInputs.first_name ?? ''}
                  onChange={(event) => {
                    setUserInputs({ ...userInputs, first_name: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={!!userError.first_name}
                  helperText={userError.first_name}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="email-inputs"
                  label="メールアドレス"
                  type="text"
                  value={userInputs.email ?? ''}
                  onChange={(event) => {
                    setUserInputs({ ...userInputs, email: event.target.value });
                    setChanged(true);
                  }}
                  size="small"
                  error={!!userError.email}
                  helperText={userError.email ? userError.email : 'トラック予約時のメール送信先となります。'}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <MultiSelectBox<Group>
                  id="group-inputs"
                  items={groups}
                  labelKey="verbose_name"
                  idKey="name"
                  boxLabel="権限グループ"
                  boxPlaceholder=""
                  required
                  itemVals={groupVals}
                  setFunc={(value: Group[]) => {
                    setUserInputs({ ...userInputs, groups_code: value.map((val) => val.pk) });
                    setGroupVals(value);
                    setChanged(true);
                  }}
                  errorMsg={userError.groups_code}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectBox
                  id="customer-inputs"
                  items={customers}
                  labelKey="name"
                  idKey="code"
                  boxLabel="取引先"
                  boxPlaceholder=""
                  itemVal={customerVal}
                  setFunc={(value: Customer) => {
                    setUserInputs({ ...userInputs, customer_code: value?.code ?? '' });
                    setCustomerVal(value);
                    setChanged(true);
                  }}
                  errorMsg={userError.customer_code}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default UserManageDialog;
