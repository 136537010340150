import React from 'react';

import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import type { CalendarEvent, CalendarResource } from './types';

const ReservationCalendar: React.FC<{
  events: CalendarEvent[];
  resources: CalendarResource[];
  setCalendarDate: CallableFunction;
  handleOpenDialogFunc: CallableFunction;
  minTime: string | null;
  maxTime: string | null;
}> = ({ events, resources, setCalendarDate, handleOpenDialogFunc, minTime, maxTime }) => {
  const calendarRef = React.createRef<FullCalendar>();

  const DEFAULT_MIN = '09:00';
  const DEFAULT_MAX = '18:00';

  const controlCalendarDate = (method: string) => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      if (method === 'today') {
        calendarApi.today();
      } else if (method === 'prev') {
        calendarApi.prev();
      } else if (method === 'next') {
        calendarApi.next();
      }
      setCalendarDate(calendarApi.getDate());
    }
  };

  return (
    <FullCalendar
      locale="ja"
      timeZone="local"
      schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
      initialView="resourceTimelineDay"
      plugins={[resourceTimelinePlugin]}
      events={events}
      resourceAreaHeaderContent="ロケーション"
      slotMinWidth={50}
      slotMinTime={minTime ?? DEFAULT_MIN}
      slotMaxTime={maxTime ?? DEFAULT_MAX}
      ref={calendarRef}
      customButtons={{
        customToday: {
          text: '今日',
          click: () => {
            controlCalendarDate('today');
          },
        },
        customPrev: {
          text: '<',
          click: () => {
            controlCalendarDate('prev');
          },
        },
        customNext: {
          text: '>',
          click: () => {
            controlCalendarDate('next');
          },
        },
      }}
      headerToolbar={{
        right: 'customPrev,customToday,customNext',
      }}
      resourceAreaColumns={[
        {
          group: true,
          field: 'warehouse',
          headerContent: '倉庫',
        },
        {
          field: 'berth',
          headerContent: 'バース',
        },
      ]}
      eventClick={(info) => {
        handleOpenDialogFunc(info.event.id);
      }}
      contentHeight="auto"
      resources={resources}
    />
  );
};

export default ReservationCalendar;
