import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

import type { Product, PurchaseOrderHeader, PurchaseOrderItem } from '../../components/stock/types';
import type { Customer, Warehouse } from '../../components/berth/types';
import type { InputFields, Cell, SelectBoxItem } from '../../components/types';
import CommonPaginationTable, { RowsAndCount, Handler } from '../../components/common_pagination_table';
import { CommonTable } from '../../components/common_table';
import { MessageSnackbar, AlertMessage } from '../../components/message_snackbar';
import Header from '../../components/header';
import PurchaseOrderManageDialog from '../../components/stock/purchase_order_manage_dialog';
import ArrivalManageDialog from '../../components/stock/arrival_manage_dialog';
import PurchaseOrderSearchBox from '../../components/stock/purchase_order_search_box';
import { apiContext } from '../../hooks/call_apis';
import { getAmountFormat, getDatetimeFormat, getNumberFormat } from '../../components/get_format';

const Buttons: React.FC<{
  setOpenManageDialog: CallableFunction;
  search: CallableFunction;
}> = ({ setOpenManageDialog, search }) => {
  const handleOpenCreateDialog = () => {
    setOpenManageDialog();
  };
  const handleClickSearch = () => {
    search();
  };
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button variant="contained" onClick={handleClickSearch} startIcon={<ManageSearchIcon />}>
          検索
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="success"
          onClick={handleOpenCreateDialog}
          startIcon={<AppRegistrationIcon />}
        >
          新規登録
        </Button>
      </Grid>
    </Grid>
  );
};

const PurchaseOrderPage: React.FC = () => {
  const [alertMessage, setAlertMessage] = useState<AlertMessage>({ open: false, message: '', severity: 'success' });

  const [storeInQuery, setStoreInQuery] = useState<Warehouse[]>([]);
  const [customerQuery, setCustomerQuery] = useState<Customer[]>([]);
  const [productQuery, setProductQuery] = useState<Product[]>([]);
  const [dateSearchQueries, setDateSearchQueries] = useState<InputFields>({});
  const [arrivalCompletedQuery, setArrivalCompletedQuery] = useState<SelectBoxItem | null>(null);

  const [openManageDialog, setOpenManageDialog] = useState<boolean>(false);
  const [openArrivalManageDialog, setOpenArrivalManageDialog] = useState<boolean>(false);
  const [targetPurchaseOrder, setTargetPurchaseOrder] = useState<string | null>(null);
  const [itemRows, setItemRows] = useState<Cell[][]>([]);
  const [purchaseOrderHeaders, setPurchaseOrderHeaders] = useState<PurchaseOrderHeader[]>([]);
  const [headerRows, setHeaderRows] = useState<RowsAndCount>({ count: 0, rows: [] });
  const ctx = useContext(apiContext);

  const handleOpenPurchaseOrderDialog = (targetCode?: string) => {
    if (targetCode) {
      setTargetPurchaseOrder(targetCode);
    } else {
      setTargetPurchaseOrder(null);
    }
    setOpenManageDialog(true);
  };

  const handleOpenArrivalManageDialog = (targetCode: string) => {
    setTargetPurchaseOrder(targetCode);
    setOpenArrivalManageDialog(true);
  };

  const onClickHeader = (orderCode: string | null) => {
    if (orderCode) {
      ctx
        .getRecordList<PurchaseOrderItem>('/api/purchase-order-items/', { code: orderCode })
        .then((data) => {
          setItemRows(
            data.map((item) => [
              { display: item.item.code, val: item.code },
              { display: item.item.name },
              { display: item.item.customer.name },
              { display: getAmountFormat(item.quantity.toString()) },
              { display: item.item.stock_unit.name },
              { display: item.item.quantity ? getAmountFormat(item.item.quantity.toString()) : '' },
              { display: item.item.quantity ? getAmountFormat(item.item.unit_price?.toString() ?? '0') : '' },
              { display: item.item.temp_zone.temp_name },
            ])
          );
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const orderColumns = [
    { header: '伝票番号', columnType: 'Button', columnFunc: handleOpenPurchaseOrderDialog },
    { header: '発注日時' },
    { header: '取引先' },
    { header: '入荷先' },
    { header: '入荷予定日' },
    { header: '入荷', columnType: 'Button', columnFunc: handleOpenArrivalManageDialog },
  ];

  const itemColumns = [
    { header: '商品コード' },
    { header: '商品名', width: '30%' },
    { header: 'メーカー' },
    { header: '数量' },
    { header: '単位' },
    { header: '入数' },
    { header: '単価' },
    { header: '温度帯' },
  ];

  const paginationTableRef = useRef({} as Handler);
  // CommonPaginationTableで呼び出すデータ取得
  const setHeaderRowsFunc = useCallback(
    (page: number, pageSize: number, params: { [name: string]: string | string[] }) => {
      ctx
        .getPaginationRecordList<PurchaseOrderHeader>('/api/purchase-order-headers', page, pageSize, params)
        .then((data) => {
          setHeaderRows({
            count: data.count,
            rows: data.results.map((purchaseOrder) => [
              {
                display: getNumberFormat(purchaseOrder.order_number),
                val: purchaseOrder.order_number.toString(),
                disabled: purchaseOrder.arrival_completed,
              },
              { display: getDatetimeFormat(new Date(purchaseOrder.order_datetime)) },
              { display: purchaseOrder.customer.name },
              { display: purchaseOrder.store_in.warehouse_name },
              { display: purchaseOrder.estimated_date_of_arrival },
              { display: purchaseOrder.arrival_completed ? '入荷済み' : '未入荷', val: purchaseOrder.order_number },
            ]),
          });
          setPurchaseOrderHeaders(data.results);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    [ctx]
  );

  const searchHeader = useCallback(
    (page: number, pageSize: number) => {
      setHeaderRowsFunc(page, pageSize, {
        store_in: storeInQuery.map((storeIn) => storeIn.warehouse_code),
        customer: customerQuery.map((customer) => customer.code),
        product: productQuery.map((product) => product.code),
        order_datetime__gte: dateSearchQueries.order_datetime__gte as string,
        order_datetime__lte: dateSearchQueries.order_datetime__lte as string,
        estimated_date_of_arrival__gte: dateSearchQueries.estimated_date_of_arrival__gte as string,
        estimated_date_of_arrival__lte: dateSearchQueries.estimated_date_of_arrival__lte as string,
        arrival_completed: arrivalCompletedQuery?.code ?? '',
      });
      setItemRows([]);
    },
    [arrivalCompletedQuery, customerQuery, productQuery, storeInQuery, setHeaderRowsFunc, dateSearchQueries]
  );

  const initializePagination = () => {
    paginationTableRef.current?.initializePagination();
  };

  const handleCloseDialog = () => {
    paginationTableRef.current?.updatePagination();
    setItemRows([]);
    setOpenManageDialog(false);
    setOpenArrivalManageDialog(false);
  };

  useEffect(() => {
    setHeaderRowsFunc(1, 5, {});
  }, [ctx, setHeaderRowsFunc]);

  return (
    <>
      <MessageSnackbar alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
      <Header screenName="発注/入荷一覧" />
      <Box sx={{ margin: '2%' }}>
        <Grid container justifyContent="center" spacing={6}>
          <Grid item xs={12}>
            <Buttons setOpenManageDialog={handleOpenPurchaseOrderDialog} search={initializePagination} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={6}>
              <Grid item xs={4}>
                <PurchaseOrderSearchBox
                  storeInVals={storeInQuery}
                  setStoreInVals={setStoreInQuery}
                  CustomerVals={customerQuery}
                  setCustomerVals={setCustomerQuery}
                  productVals={productQuery}
                  setProductVals={setProductQuery}
                  dateSearchVals={dateSearchQueries}
                  setDateSearchVals={setDateSearchQueries}
                  arrivalCompletedVal={arrivalCompletedQuery}
                  setArrivalCompletedVal={setArrivalCompletedQuery}
                />
              </Grid>
              <Grid item xs={8}>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Typography> 明細を確認する発注を選択してください。 </Typography>
                    <CommonPaginationTable
                      columns={orderColumns}
                      idColumn={0}
                      rowsAndCount={headerRows}
                      paginationAPIFunc={searchHeader}
                      onClickRowFunc={onClickHeader}
                      ref={paginationTableRef}
                    />
                  </Grid>
                  {itemRows.length !== 0 && (
                    <Grid item>
                      <Grid container>
                        <Grid item>
                          <Typography variant="h4">発注明細一覧</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <CommonTable columns={itemColumns} idColumn={0} rows={itemRows} />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <PurchaseOrderManageDialog
        updateTargetID={targetPurchaseOrder}
        setUpdateTargetID={setTargetPurchaseOrder}
        setAlertMessage={setAlertMessage}
        handleCloseDialog={() => handleCloseDialog()}
        open={openManageDialog}
      />
      <ArrivalManageDialog
        targetID={targetPurchaseOrder}
        setTargetID={setTargetPurchaseOrder}
        handleCloseDialog={() => handleCloseDialog()}
        open={openArrivalManageDialog}
        arrivalCompleted={
          purchaseOrderHeaders.find((header) => header.order_number === targetPurchaseOrder)?.arrival_completed ?? false
        }
        setAlertMessage={setAlertMessage}
      />
    </>
  );
};

export default PurchaseOrderPage;
