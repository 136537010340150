import React from 'react';

import { TextField } from '@mui/material';

const CustomerReservationSearchBox: React.FC<{
  reservationDateVal: string;
  setReservationDateVal: CallableFunction;
  dateErrorMsg: string | null;
}> = ({ reservationDateVal, setReservationDateVal, dateErrorMsg }) => (
  <TextField
    InputLabelProps={{ shrink: true }}
    id="reservation_date-inputs"
    label="納品日"
    type="date"
    value={reservationDateVal}
    required
    onChange={(event) => {
      setReservationDateVal(event.target.value);
    }}
    size="small"
    fullWidth
    error={!!dateErrorMsg}
    helperText={dateErrorMsg}
  />
);
export default CustomerReservationSearchBox;
