import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

import type { Cell } from '../../components/types';
import type { Office } from '../../components/stock/types';
import { CommonTable, Handler } from '../../components/common_table';
import { MessageSnackbar, AlertMessage } from '../../components/message_snackbar';
import Header from '../../components/header';
import { OfficesSearchBox } from '../../components/stock/offices_search_box';
import OfficeManageDialog from '../../components/stock/office_manage_dialog';
import { apiContext } from '../../hooks/call_apis';

const Buttons: React.FC<{
  handleOpenDialogFunc: CallableFunction;
  search: CallableFunction;
}> = ({ handleOpenDialogFunc, search }) => {
  const handleOpenOfficeDialog = () => {
    handleOpenDialogFunc();
  };
  const handleClickSearch = () => {
    search();
  };
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button id="search-offices" variant="contained" startIcon={<ManageSearchIcon />} onClick={handleClickSearch}>
          検索
        </Button>
      </Grid>
      <Grid item>
        <Button
          id="create-office"
          variant="contained"
          color="success"
          startIcon={<AppRegistrationIcon />}
          onClick={handleOpenOfficeDialog}
        >
          新規登録
        </Button>
      </Grid>
    </Grid>
  );
};

const OfficePage: React.FC = () => {
  const [openOfficeDialog, setOpenOfficeDialog] = useState<boolean>(false);
  const [updateOffice, setUpdateOffice] = useState<string | null>(null);

  const [rows, setRows] = useState<Cell[][]>([[]]);

  const ctx = useContext(apiContext);

  const [nameQuery, setNameQuery] = useState<string | null>(null);
  const [kanaQuery, setKanaQuery] = useState<string | null>(null);

  const tableRef = useRef({} as Handler);
  const getOfficeTable = () => {
    ctx
      .getRecordList<Office>('/api/offices/', {
        name: nameQuery ?? '',
        kana: kanaQuery ?? '',
      })
      .then((data) => {
        setRows(
          data.map((office) => [
            { display: office.code, val: office.code },
            { display: office.name },
            { display: office.kana },
            { display: office.postal_number },
            { display: office.address1 },
            { display: office.address1_kana },
            { display: office.address2 },
            { display: office.address2_kana },
            { display: office.tel },
            { display: office.fax },
          ])
        );
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const search = () => {
    getOfficeTable();
    tableRef.current?.initializePage();
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseDialog = () => {
    setOpenOfficeDialog(false);
    getOfficeTable();
  };

  const handleOpenOfficeDialog = (targetCode?: string) => {
    if (targetCode !== undefined) setUpdateOffice(targetCode);
    else setUpdateOffice(null);
    setOpenOfficeDialog(true);
  };

  const [alertMessage, setAlertMessage] = useState<AlertMessage>({ open: false, message: '', severity: 'success' });

  const columns = [
    { header: '事業所コード', columnType: 'Button', columnFunc: handleOpenOfficeDialog },
    { header: '事業所名' },
    { header: '事業所名カナ' },
    { header: '郵便番号' },
    { header: '住所1' },
    { header: '住所1カナ' },
    { header: '住所2' },
    { header: '住所2カナ' },
    { header: '電話番号' },
    { header: 'FAX番号' },
  ];

  return (
    <>
      <MessageSnackbar alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
      <Header screenName="事業所マスタ" />
      <Box sx={{ margin: '2%' }}>
        <Grid container justifyContent="center" spacing={6}>
          <Grid item xs={12}>
            <Buttons handleOpenDialogFunc={handleOpenOfficeDialog} search={search} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={6}>
              <Grid item xs={4}>
                <OfficesSearchBox
                  nameVal={nameQuery}
                  setNameVal={setNameQuery}
                  kanaVal={kanaQuery}
                  setKanaVal={setKanaQuery}
                />
              </Grid>
              <Grid item xs={8}>
                <CommonTable columns={columns} idColumn={0} rows={rows} width={1250} ref={tableRef} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <OfficeManageDialog
        setAlertMessage={setAlertMessage}
        handleCloseDialog={() => handleCloseDialog()}
        open={openOfficeDialog}
        updateTargetID={updateOffice}
        setUpdateTargetID={setUpdateOffice}
      />
    </>
  );
};

export default OfficePage;
