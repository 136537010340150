import React, { useContext, useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, Grid, TextField, Typography } from '@mui/material';
import { AppRegistration } from '@mui/icons-material';

import type { Cell, ErrorMessages } from '../types';
import type { Department, Office, ReceivedOrderHeader, ReceivedOrderItem } from './types';
import type { Warehouse } from '../berth/types';
import { CommonTable } from '../common_table';
import { SelectBox } from '../auto_complete_select_box';
import AddReceivedOrderItemDialog from './add_received_order_item_dialog';
import { apiContext } from '../../hooks/call_apis';
import { getAmountFormat } from '../get_format';
import MasterManageDialogHeaderButtons from '../master_manage_dialog_header_buttons';

const ReceivedOrderManageDialog: React.FC<{
  setAlertMessage: CallableFunction;
  handleCloseDialog: () => void;
  open: boolean;
  updateTargetID: string | null;
  setUpdateTargetID: CallableFunction;
}> = ({ setAlertMessage, handleCloseDialog, open, updateTargetID, setUpdateTargetID }) => {
  const ctx = useContext(apiContext);

  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const [offices, setOffices] = useState<Office[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);

  const [orderNumberVal, setOrderNumberVal] = useState<string | null>(null);
  const [shipFromVal, setShipFromVal] = useState<Warehouse | null>(null);
  const [preferredDateVal, setPreferredDateVal] = useState<string | null>();
  const [officeVal, setOfficeVal] = useState<Office | null>(null);
  const [departmentVal, setDepartmentVal] = useState<Department | null>(null);
  const [orderItemList, setOrderItemList] = useState<ReceivedOrderItem[]>([]);
  const [changed, setChanged] = useState<boolean>(false);

  const [receivedOrderHeaderError, setReceivedOrderHeaderError] = useState<ErrorMessages>({});

  const receivedOrderHeaderInputs = {
    order_number: orderNumberVal ?? '',
    preferred_date: preferredDateVal ?? '',
    department_code: departmentVal?.code ?? '',
    ship_from_code: shipFromVal?.warehouse_code ?? '',
    received_order_items: orderItemList.map((item) => ({
      update_code: item.code ?? null,
      ordered_quantity: item.ordered_quantity,
      delivery_quantity: item.delivery_quantity,
      item_code: item.item.code,
    })),
  };

  const clearInputs = () => {
    setOrderNumberVal(null);
    setPreferredDateVal(null);
    setOfficeVal(null);
    setDepartmentVal(null);
    setShipFromVal(null);
    setOrderItemList([]);
  };

  const clearReceivedOrderHeaderError = () => {
    setReceivedOrderHeaderError({
      order_number: null,
      preferred_date: null,
      office_code: null,
      department_code: null,
      ship_from_code: null,
      received_order_items: null,
    });
  };

  const setReceivedOrderHeaderErrorWithOffice = (data: ErrorMessages) => {
    setReceivedOrderHeaderError({ ...data, office_code: !officeVal ? 'この項目は空にできません。' : null });
  };

  useEffect(() => {
    clearReceivedOrderHeaderError();
    setChanged(false);
    if (updateTargetID) {
      ctx
        .getRecord<ReceivedOrderHeader>('/api/received-order-headers/', updateTargetID)
        .then((data) => {
          setOrderNumberVal(data.order_number.toString());
          setPreferredDateVal(data.preferred_date.toString());
          setOfficeVal(data.department.office);
          setDepartmentVal(data.department);
          setShipFromVal(data.ship_from);
          setOrderItemList(data.received_order_items);
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      clearInputs();
    }
  }, [ctx, updateTargetID, open]);

  const [openAddReceivedOrderItemDialog, setOpenAddReceivedOrderItemDialog] = useState<boolean>(false);

  const deleteItem = (targetIndex: number) => {
    setOrderItemList(orderItemList.filter((item, index) => index !== targetIndex));
    setChanged(true);
  };

  const setOrdredQuantityToInputs = (val: number, rowIndex: number) => {
    setOrderItemList(
      orderItemList.map((item, index) => (index === rowIndex ? { ...item, ordered_quantity: val } : item))
    );
    setChanged(true);
  };

  const setDeliveryQuantityToInputs = (val: number, rowIndex: number) => {
    setOrderItemList(
      orderItemList.map((item, index) => (index === rowIndex ? { ...item, delivery_quantity: val } : item))
    );
    setChanged(true);
  };

  const headers = [
    { header: '商品コード' },
    { header: '商品名', width: '30%' },
    { header: 'メーカー' },
    { header: '受注数', columnType: 'TextField-number', columnFunc: setOrdredQuantityToInputs },
    { header: '納品数', columnType: 'TextField-number', columnFunc: setDeliveryQuantityToInputs },
    { header: '単位' },
    { header: '入数' },
    { header: '単価' },
    { header: '温度帯' },
    { header: '削除', columnType: 'Button', columnFunc: deleteItem },
  ];

  const rows: Cell[][] = orderItemList.map((item, index) => [
    { display: item.item.code, val: item.code ?? index },
    { display: item.item.name },
    { display: item.item.manufacturer },
    { display: '', val: getAmountFormat(item.ordered_quantity.toString()), disabled: item.is_shipped },
    { display: '', val: getAmountFormat(item.delivery_quantity.toString()), disabled: item.is_shipped },
    { display: item.item.stock_unit.name },
    { display: item.item.quantity ? getAmountFormat(item.item.quantity.toString()) : '' },
    { display: item.item.unit_price ? getAmountFormat(item.item.unit_price.toString()) : '' },
    { display: item.item.temp_zone.temp_name },
    { display: item.allocated_quantity ? '-' : '削除', val: index, disabled: !!item.allocated_quantity },
  ]);

  const includeAllocated = orderItemList.find((item) => !!item.allocated_quantity);
  const shippedExists = orderItemList.filter((item) => item.is_shipped).length > 0;

  const extraButtons = [
    <Button
      variant="contained"
      color="success"
      onClick={() => setOpenAddReceivedOrderItemDialog(true)}
      sx={{ width: 150 }}
      startIcon={<AppRegistration />}
    >
      商品追加
    </Button>,
  ];

  useEffect(() => {
    ctx
      .getRecordList<Warehouse>('/api/warehouses/', {})
      .then((data) => {
        setWarehouses(data);
      })
      .catch((e) => {
        console.error(e);
      });
    ctx
      .getRecordList<Office>('/api/offices/', {})
      .then((data) => {
        setOffices(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [ctx]);

  useEffect(() => {
    if (officeVal) {
      ctx
        .getRecordList<Department>('/api/departments/', { office: officeVal.code })
        .then((data) => {
          setDepartments(data);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [ctx, officeVal]);

  const itemErrors = () => {
    if (Array.isArray(receivedOrderHeaderError.received_order_items)) {
      const fields: { [key: string]: string } = {
        ordered_quantity: '受注数',
        delivery_quantity: '納品数',
      };
      return receivedOrderHeaderError.received_order_items.map((item: ErrorMessages, index) =>
        Object.keys(item).map((key) => (
          <Grid item xs={12}>
            <Typography color="red">{`${index + 1}行目（${fields[key]}）:${item[key] ?? ''}`}</Typography>
          </Grid>
        ))
      );
    }
    return [];
  };
  return (
    <>
      <Dialog open={open} maxWidth="xl">
        <DialogContent>
          <Grid container spacing={6}>
            <MasterManageDialogHeaderButtons
              url="/api/received-order-headers/"
              object={receivedOrderHeaderInputs}
              clearObjFunc={clearInputs}
              clearErrorFunc={clearReceivedOrderHeaderError}
              handleCloseDialog={handleCloseDialog}
              setAlertMessage={setAlertMessage}
              setErrorFunc={setReceivedOrderHeaderErrorWithOffice}
              updateTargetID={updateTargetID}
              setUpdateTargetID={setUpdateTargetID}
              extraButtons={extraButtons}
              deleteDisabled={!!includeAllocated}
              backConfirm={changed}
            />
            <Grid item xs={12}>
              <Grid container spacing={5}>
                <Grid item xs={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label="伝票番号"
                    value={orderNumberVal ?? ''}
                    onChange={(event) => {
                      setOrderNumberVal(event.target.value);
                      setChanged(true);
                    }}
                    size="small"
                    error={!!receivedOrderHeaderError.order_number}
                    helperText={receivedOrderHeaderError.order_number}
                    disabled={!!updateTargetID}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <SelectBox
                    id="office-inputs"
                    items={offices}
                    labelKey="name"
                    idKey="code"
                    boxLabel="事業所"
                    boxPlaceholder=""
                    itemVal={officeVal}
                    setFunc={(value: Office) => {
                      setDepartments([]);
                      setOfficeVal(value);
                      setDepartmentVal(null);
                      setChanged(true);
                    }}
                    disabled={shippedExists}
                    errorMsg={receivedOrderHeaderError.office_code}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SelectBox
                    id="department-inputs"
                    items={departments}
                    labelKey="name"
                    idKey="code"
                    boxLabel="部署"
                    boxPlaceholder=""
                    itemVal={departmentVal}
                    setFunc={(value: Department) => {
                      setDepartmentVal(value);
                      setChanged(true);
                    }}
                    disabled={shippedExists}
                    errorMsg={receivedOrderHeaderError.department_code}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SelectBox
                    id="ship_from-inputs"
                    items={warehouses}
                    labelKey="warehouse_name"
                    idKey="warehouse_code"
                    boxLabel="出荷元"
                    boxPlaceholder=""
                    itemVal={shipFromVal}
                    setFunc={(value: Warehouse) => {
                      setShipFromVal(value);
                      setChanged(true);
                    }}
                    disabled={shippedExists}
                    errorMsg={receivedOrderHeaderError.ship_from_code}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label="納品希望日"
                    type="date"
                    value={preferredDateVal ?? ''}
                    onChange={(event) => {
                      setPreferredDateVal(event.target.value);
                      setChanged(true);
                    }}
                    size="small"
                    error={!!receivedOrderHeaderError.preferred_date}
                    helperText={receivedOrderHeaderError.preferred_date}
                    fullWidth
                    disabled={shippedExists}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {itemErrors()}
                <Grid item xs={12}>
                  <Typography color="red">{receivedOrderHeaderError.no_item}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">受注明細</Typography>
                </Grid>
                <Grid item xs={12}>
                  <CommonTable columns={headers} idColumn={0} rows={rows} emptyMsg="明細が登録されていません。" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <AddReceivedOrderItemDialog
        orderItemList={orderItemList}
        setOrderItemList={(itemList: ReceivedOrderItem[]) => {
          setOrderItemList(itemList);
          setChanged(true);
        }}
        handleCloseDialog={() => setOpenAddReceivedOrderItemDialog(false)}
        open={openAddReceivedOrderItemDialog}
      />
    </>
  );
};

export default ReceivedOrderManageDialog;
