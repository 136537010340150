import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

import type { Cell } from '../../components/types';
import type { Warehouse } from '../../components/berth/types';
import { CommonTable, Handler } from '../../components/common_table';
import { MessageSnackbar, AlertMessage } from '../../components/message_snackbar';
import Header from '../../components/header';
import { WarehouseSearchBox } from '../../components/berth/warehouses_search_box';
import WarehouseManageDialog from '../../components/berth/warehouse_manage_dialog';
import { apiContext } from '../../hooks/call_apis';

const Buttons: React.FC<{
  handleOpenDialogFunc: CallableFunction;
  search: CallableFunction;
}> = ({ handleOpenDialogFunc, search }) => {
  const handleOpenWarehouseDialog = () => {
    handleOpenDialogFunc();
  };
  const handleClickSearch = () => {
    search();
  };
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button id="search-warehouses" variant="contained" onClick={handleClickSearch} startIcon={<ManageSearchIcon />}>
          検索
        </Button>
      </Grid>
      <Grid item>
        <Button
          id="create-warehouse"
          variant="contained"
          color="success"
          onClick={handleOpenWarehouseDialog}
          startIcon={<AppRegistrationIcon />}
        >
          新規登録
        </Button>
      </Grid>
    </Grid>
  );
};

const WarehousePage: React.FC = () => {
  const [alertMessage, setAlertMessage] = useState<AlertMessage>({ open: false, message: '', severity: 'success' });

  const [warehouseNameQuery, setWarehouseNameQuery] = useState<string>('');

  const [openWarehouseDialog, setOpenWarehouseDialog] = useState<boolean>(false);
  const [rows, setRows] = useState<Cell[][]>([[]]);
  const [updateWarehouse, setUpdateWarehouse] = useState<string | null>(null);
  const ctx = useContext(apiContext);

  const tableRef = useRef({} as Handler);
  const getWarehouseTable = () => {
    ctx
      .getRecordList<Warehouse>('/api/warehouses/', {
        warehouse_name: warehouseNameQuery,
      })
      .then((data) => {
        setRows(
          data.map((warehouse) => [
            { display: warehouse.warehouse_code, val: warehouse.warehouse_code },
            { display: warehouse.warehouse_name },
            { display: warehouse.warehouse_address },
            { display: warehouse.warehouse_tel },
            { display: warehouse.warehouse_fax },
          ])
        );
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const search = () => {
    getWarehouseTable();
    tableRef.current?.initializePage();
  };

  const handleCloseDialog = () => {
    setOpenWarehouseDialog(false);
    getWarehouseTable();
  };
  const handleOpenWarehouseDialog = (targetCode?: string) => {
    if (targetCode) {
      setUpdateWarehouse(targetCode);
    } else {
      setUpdateWarehouse(null);
    }
    setOpenWarehouseDialog(true);
  };
  const columns = [
    { header: '倉庫コード', columnType: 'Button', columnFunc: handleOpenWarehouseDialog },
    { header: '倉庫名' },
    { header: '住所' },
    { header: '電話番号' },
    { header: 'Fax番号' },
  ];

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MessageSnackbar alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
      <Header screenName="倉庫マスタ" />
      <Box sx={{ margin: '2%' }}>
        <Grid container justifyContent="center" spacing={6}>
          <Grid item xs={12}>
            <Buttons handleOpenDialogFunc={handleOpenWarehouseDialog} search={search} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={6}>
              <Grid item xs={4}>
                <WarehouseSearchBox warehouseNameVal={warehouseNameQuery} setWarehouseNameVal={setWarehouseNameQuery} />
              </Grid>
              <Grid item xs={8}>
                <CommonTable columns={columns} idColumn={0} rows={rows} ref={tableRef} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <WarehouseManageDialog
        setAlertMessage={setAlertMessage}
        handleCloseDialog={() => handleCloseDialog()}
        open={openWarehouseDialog}
        updateTargetID={updateWarehouse}
        setUpdateTargetID={setUpdateWarehouse}
      />
    </>
  );
};

export default WarehousePage;
