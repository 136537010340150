import React, { useContext, useState, KeyboardEvent } from 'react';
import { Grid, TextField, Button, Box, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Logo from '../../components/logo';
import { apiContext } from '../../hooks/call_apis';

const LoginPage: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const ctx = useContext(apiContext);
  const navigate = useNavigate();

  const onChangeUsername = (value: string) => {
    setUsername(value);
  };
  const onChangePassword = (value: string) => {
    setPassword(value);
  };
  const onClickLogin = () => {
    ctx
      .login(username, password)
      .then(() => {
        navigate('/');
      })
      .catch(() => {
        setMessage('ログインIDまたはパスワードが違います。');
      });
  };

  const keyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Enter') {
      onClickLogin();
    }
  };

  return (
    <Box sx={{ bgcolor: 'beige' }}>
      <Container>
        <Box
          alignItems="center"
          bgcolor="background.paper"
          borderRadius="12px"
          boxShadow="10"
          fontWeight="bold"
          margin="250px"
          width="650px"
        >
          <Grid container spacing={3} p="30px 70px">
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item>
                  <Typography variant="h5"> 在庫管理・トラック予約受付システム </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Logo />
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h4">Sign in</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="username"
                        label="ログインID"
                        type="text"
                        onChange={(e) => onChangeUsername(e.target.value)}
                        value={username}
                        InputLabelProps={{ shrink: true }}
                        onKeyPress={(e) => keyPress(e)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="password"
                        label="パスワード"
                        type="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => onChangePassword(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        onKeyPress={(e) => keyPress(e)}
                      />
                    </Grid>
                    <Grid item xs={12} container justifyContent="flex-end">
                      <Button variant="contained" onClick={onClickLogin} id="login">
                        ログイン
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                {message && (
                  <Grid item id="message" xs={12} mt="10px">
                    {message}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default LoginPage;
