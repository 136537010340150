import React, { useState, useCallback, useContext, useEffect } from 'react';
import { Button, Dialog, DialogContent, Grid, TextField, Typography } from '@mui/material';

import type { InputFields, ErrorMessages, Cell } from '../types';
import type { BerthReservation, DelayNotification } from './types';
import { apiContext } from '../../hooks/call_apis';
import { getTimeFormat } from '../get_format';
import ConfirmationDialog from '../confirmation_dialog';
import { CommonTable } from '../common_table';
import BackButton from '../back_button';

const DelayNotificationDialog: React.FC<{
  setAlertMessage: CallableFunction;
  handleCloseDialog: () => void;
  open: boolean;
  targetID: string | null;
  setTargetID: CallableFunction;
}> = ({ setAlertMessage, handleCloseDialog, open, targetID, setTargetID }) => {
  const [target, setTarget] = useState<BerthReservation | null>(null);

  const [delayInputs, setDelayInputs] = useState<InputFields>({});
  const [changed, setChanged] = useState<boolean>(false);

  const [delayError, setDelayError] = useState<ErrorMessages>({});

  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  const columns = [{ header: '連絡時予約時刻' }, { header: '到着予定時刻' }];
  const [rows, setRows] = useState<Cell[][]>([]);

  const message = (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        以下の内容で予約します。よろしいですか。
      </Grid>
      <Grid item xs={12}>
        {`到着予定時刻：${delayInputs.arrival_time?.toString() ?? ''}`}
      </Grid>
    </Grid>
  );

  const clearDelay = () => {
    setDelayInputs({
      code: '',
      arrival_time: '',
    });
  };

  const clearDelayError = () => {
    setDelayError({
      code: '',
      arrival_time: '',
    });
  };

  const onOpenConfirmationDialog = () => {
    setOpenConfirmDialog(true);
  };

  const ctx = useContext(apiContext);
  const getDelayNotifications = useCallback(() => {
    if (targetID) {
      ctx
        .getRecordList<DelayNotification>('/api/berth/delay-notifications', { berth_reservation: targetID })
        .then((data) => {
          setRows(
            data.map((delay) => [
              { display: getTimeFormat(delay.reserved_time.toString()), val: delay.code },
              { display: getTimeFormat(delay.arrival_time.toString()) },
            ])
          );
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [ctx, targetID]);

  const getTargetReservation = useCallback(() => {
    if (targetID) {
      ctx
        .getRecord<BerthReservation>('/api/berth-reservations/', targetID)
        .then((data) => {
          setDelayInputs({
            berth_reservation: data.code,
          });
          setTarget(data);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [ctx, targetID]);

  const onSucceedSubmit = () => {
    clearDelay();
    clearDelayError();
    setTargetID(null);
    handleCloseDialog();
  };

  const onFailureSubmit = () => {
    getTargetReservation();
    getDelayNotifications();
  };

  useEffect(() => {
    setChanged(false);
    if (targetID) {
      getTargetReservation();
      getDelayNotifications();
    } else {
      clearDelay();
      setRows([]);
    }
    clearDelayError();
  }, [ctx, targetID, open, getDelayNotifications, getTargetReservation]);

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="md">
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <BackButton onClose={handleCloseDialog} requireConfirm={changed} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3">遅延連絡</Typography>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h5">対象予約</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="red">{delayError.berth_reservation}</Typography>
                </Grid>
                <Grid item xs={12}>
                  納品先：{target?.berth.warehouse.warehouse_name}
                </Grid>
                <Grid item xs={12}>
                  納品日：{target?.reservation_date.toString()}
                </Grid>
                <Grid item xs={12}>
                  予定時刻：{getTimeFormat(target?.start_time.toString() ?? '')} ~{' '}
                  {getTimeFormat(target?.end_time.toString() ?? '')}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h5">到着予定時刻入力</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="arrival_time-inputs"
                    label="到着予定時刻"
                    type="time"
                    value={delayInputs.arrival_time ?? ''}
                    required
                    onChange={(event) => {
                      setDelayInputs({ ...delayInputs, arrival_time: event.target.value });
                      setChanged(true);
                    }}
                    size="small"
                    error={!!delayError.arrival_time}
                    helperText={delayError.arrival_time}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="success"
                    disabled={!delayInputs.arrival_time}
                    variant="contained"
                    onClick={onOpenConfirmationDialog}
                  >
                    申請
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {rows.length !== 0 && (
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h5">遅延連絡一覧</Typography>
                    {!target?.is_scheduled_for_delay_notification && (
                      <Typography color="red">遅延連絡に対して予定が調整できていません。</Typography>
                    )}
                    <CommonTable columns={columns} idColumn={0} rows={rows} />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        inputs={delayInputs}
        url="/api/berth/delay-notifications"
        message={message}
        onSucceedFunc={onSucceedSubmit}
        onFailureFunc={onFailureSubmit}
        setErrorFunc={setDelayError}
        open={openConfirmDialog}
        setAlertMessage={setAlertMessage}
        setOpenFunc={setOpenConfirmDialog}
      />
    </>
  );
};

export default DelayNotificationDialog;
