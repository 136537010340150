import React, { useContext } from 'react';
import { Dialog, DialogContent, Grid, Button } from '@mui/material';
import { AxiosError } from 'axios';

import type { ErrorMessages, InputFields } from './types';
import { apiContext } from '../hooks/call_apis';

const ConfirmationDialog: React.FC<{
  open: boolean;
  setOpenFunc: CallableFunction;
  message: React.ReactNode;
  inputs?: InputFields | InputFields[];
  url?: string;
  onSucceedFunc?: CallableFunction;
  onFailureFunc?: CallableFunction;
  setErrorFunc?: CallableFunction;
  setAlertMessage?: CallableFunction;
  submitFunc?: CallableFunction;
}> = ({
  open,
  setOpenFunc,
  message,
  inputs = null,
  url = null,
  onSucceedFunc = null,
  onFailureFunc = null,
  setErrorFunc = null,
  setAlertMessage = null,
  submitFunc = null,
}) => {
  const ctx = useContext(apiContext);

  const handleCloseConfirmDialog = () => {
    setOpenFunc(false);
  };
  const onClickSubmit = () => {
    if (submitFunc) {
      submitFunc();
    } else if (inputs && url) {
      ctx
        .create<InputFields>(url, inputs)
        .then(() => {
          if (setAlertMessage) {
            setAlertMessage({
              open: true,
              message: '登録に成功しました。',
              severity: 'success',
            });
          }
          if (onSucceedFunc) {
            onSucceedFunc();
          }
          handleCloseConfirmDialog();
        })
        .catch((e: AxiosError<ErrorMessages>) => {
          if (e.response) {
            const d = e.response.data;
            if (setErrorFunc) {
              setErrorFunc(d);
            }
            if (setAlertMessage) {
              setAlertMessage({
                open: true,
                message: '登録に失敗しました。',
                severity: 'error',
              });
            }
          }
          if (onFailureFunc) {
            onFailureFunc();
          }
          handleCloseConfirmDialog();
          console.error(e);
        });
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={handleCloseConfirmDialog}>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {message}
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Button onClick={onClickSubmit}>はい</Button>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={handleCloseConfirmDialog}>いいえ</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationDialog;
