import React, { useContext, useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, Grid, InputAdornment, TextField } from '@mui/material';
import { AppRegistration } from '@mui/icons-material';

import type { InputFields } from '../types';
import type { ShipmentItem, AllocateItem } from './types';
import { apiContext } from '../../hooks/call_apis';
import { SelectBox } from '../auto_complete_select_box';
import { getAmountFormat } from '../get_format';
import BackButton from '../back_button';

const AddShipmentItemDialog: React.FC<{
  shipmentInputs: { shipmentItem: ShipmentItem; input: InputFields }[];
  setShipmentInputs: CallableFunction;
  handleCloseDialog: () => void;
  orderItemCode: string | null;
  open: boolean;
}> = ({ shipmentInputs, setShipmentInputs, handleCloseDialog, orderItemCode, open }) => {
  const [allocateItems, setAllocateItems] = useState<{ name: string; code: string; item: AllocateItem }[]>([]);

  const [allocateItemVal, setAllocateItemVal] = useState<AllocateItem | null>(null);
  const [quantityVal, setQuantityVal] = useState<number | null>(null);
  const [changed, setChanged] = useState<boolean>(false);

  const [allocateItemError, setAllocateItemError] = useState<string | null>(null);
  const [quantityError, setQuantityError] = useState<string | null>(null);
  const onClose = () => {
    handleCloseDialog();
  };

  const onSubmit = () => {
    setQuantityError(null);
    setAllocateItemError(null);

    let quantityErrorStr = null;
    let allocateItemErrorStr = null;
    if (quantityVal === null) {
      quantityErrorStr = 'この項目は必須です。';
    } else if (quantityVal <= 0) {
      quantityErrorStr = 'この値は0より大きくしてください。';
    }
    if (!allocateItemVal) {
      allocateItemErrorStr = 'この項目は必須です。';
    } else if (!!quantityVal && allocateItemVal.quantity < quantityVal) {
      quantityErrorStr = 'この値は引当数以下にしてください。';
    }
    if (allocateItemErrorStr || quantityErrorStr) {
      setQuantityError(quantityErrorStr);
      setAllocateItemError(allocateItemErrorStr);
      return;
    }

    setShipmentInputs([
      ...shipmentInputs,
      {
        shipmentItem: {
          allocate_item: allocateItemVal,
        },
        input: {
          allocate_item_code: allocateItemVal?.code,
          quantity: quantityVal,
        },
      },
    ]);
    setAllocateItemVal(null);
    setQuantityVal(null);
    handleCloseDialog();
  };

  const ctx = useContext(apiContext);
  useEffect(() => {
    setQuantityError(null);
    setAllocateItemError(null);
    setAllocateItemVal(null);
    setQuantityVal(null);
    setChanged(false);
    if (orderItemCode && open) {
      ctx
        .getRecordList<AllocateItem>('/api/stock/allocate-items-shipment', {
          code: orderItemCode,
        })
        .then((data) => {
          const allocateItemsInShipmentItem = shipmentInputs.map((inputs) => inputs.input.allocate_item_code);
          const allocateItemChoices = data.filter(
            (allocateItem) => allocateItem.code && !allocateItemsInShipmentItem.includes(allocateItem.code)
          );
          setAllocateItems(
            allocateItemChoices.map((allocateItem) => ({
              name: allocateItem.stock.lot.code,
              code: allocateItem.code,
              item: allocateItem,
            }))
          );
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [ctx, open, orderItemCode, shipmentInputs]);

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogContent>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item>
                <BackButton onClose={onClose} requireConfirm={changed} />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="success"
                  onClick={onSubmit}
                  startIcon={<AppRegistration />}
                  sx={{ width: 150 }}
                >
                  追加
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <SelectBox
                  id="allocate-inputs"
                  items={allocateItems}
                  labelKey="name"
                  idKey="code"
                  boxLabel="引当明細"
                  boxPlaceholder=""
                  itemVal={
                    allocateItemVal
                      ? { name: allocateItemVal?.stock.lot.code, code: allocateItemVal?.code, item: allocateItemVal }
                      : null
                  }
                  setFunc={(value: { name: string; code: string; item: AllocateItem }) => {
                    setAllocateItemVal(value.item);
                    setChanged(true);
                  }}
                  helperText={`引当数：${getAmountFormat(allocateItemVal?.quantity?.toString() ?? '')}`}
                  errorMsg={allocateItemError}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="出荷数"
                  type="number"
                  value={quantityVal ?? ''}
                  onChange={(event) => {
                    setQuantityVal(Number(event.target.value));
                    setChanged(true);
                  }}
                  size="small"
                  error={!!quantityError}
                  helperText={quantityError}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        {allocateItemVal?.stock.lot.product?.stock_unit.name}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddShipmentItemDialog;
