import React, { useContext, useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import { AppRegistration } from '@mui/icons-material';

import type { ArrivalItem, PurchaseOrderItem } from './types';
import type { ErrorMessages, InputFields, Cell } from '../types';
import { CommonTable } from '../common_table';
import { apiContext } from '../../hooks/call_apis';
import AddArrivalItemDialog from './add_arrival_item_dialog';
import { getAmountFormat } from '../get_format';
import ConfirmationDialog from '../confirmation_dialog';
import BackButton from '../back_button';

const ArrivalManageDialog: React.FC<{
  handleCloseDialog: () => void;
  open: boolean;
  setTargetID: CallableFunction;
  targetID: string | null;
  arrivalCompleted: boolean;
  setAlertMessage: CallableFunction;
}> = ({ handleCloseDialog, open, targetID, setTargetID, arrivalCompleted, setAlertMessage }) => {
  const [arrivalItems, setArrivalItems] = useState<ArrivalItem[]>([]);

  const [arrivalInputs, setArrivalInputs] = useState<{ orderItem: PurchaseOrderItem; input: InputFields }[]>([]);
  const [arrivalInputsErrors, setArrivalInputsErrors] = useState<ErrorMessages[]>([]);
  const [changed, setChanged] = useState<boolean>(false);

  const arrivalRows = arrivalItems.map((item) => [
    { display: item.lot.code, val: item.code },
    { display: item.order_item.item.code },
    { display: item.order_item.item.name },
    { display: item.lot.expiration_date },
    { display: getAmountFormat(item.order_item.quantity.toString()) },
    { display: getAmountFormat(item.order_item.arrival_quantity?.toString() ?? '0') },
    { display: getAmountFormat(item.quantity?.toString() ?? '') },
  ]);

  const inputRows: Cell[][] =
    arrivalInputs?.map((input, index) => [
      { display: input.orderItem.item.code },
      { display: input.orderItem.item.name },
      { display: '', val: input.input.expiration_date?.toString() },
      { display: getAmountFormat(input.orderItem.quantity.toString()) },
      { display: getAmountFormat(input.orderItem.arrival_quantity?.toString() ?? '0') },
      { display: '', val: getAmountFormat(input.input.quantity?.toString() ?? '') },
      { display: '削除', val: index },
    ]) ?? [];

  const setQuantityToInputs = (val: number, rowIndex: number) => {
    setArrivalInputs(
      arrivalInputs.map((input, index) =>
        index === rowIndex ? { ...input, input: { ...input.input, quantity: val } } : input
      )
    );
    setChanged(true);
  };

  const setExpirationToInputs = (val: number, rowIndex: number) => {
    setArrivalInputs(
      arrivalInputs.map((input, index) =>
        index === rowIndex ? { ...input, input: { ...input.input, expiration_date: val } } : input
      )
    );
    setChanged(true);
  };

  const deleteItem = (targetIndex: number) => {
    setArrivalInputs(arrivalInputs.filter((input, index) => index !== targetIndex));
    setChanged(true);
  };

  const inputColumns = [
    { header: '商品コード' },
    { header: '商品名', width: '30%' },
    { header: '賞味期限/消費期限', columnType: 'TextField-date', columnFunc: setExpirationToInputs },
    { header: '注文数' },
    { header: '入荷済み数' },
    { header: '入荷数', columnType: 'TextField-number', columnFunc: setQuantityToInputs },
    { header: '削除', columnType: 'Button', columnFunc: deleteItem },
  ];

  const arrivalColumns = [
    { header: 'ロット' },
    { header: '商品コード' },
    { header: '商品名', width: '30%' },
    { header: '賞味期限/消費期限' },
    { header: '注文数' },
    { header: '入荷済み数' },
    { header: '入荷数' },
  ];

  const onClose = () => {
    handleCloseDialog();
  };

  const ctx = useContext(apiContext);

  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  const message = <Typography>登録した入荷内容は変更できません。入荷内容を登録しますか。</Typography>;

  const onSucceedSubmit = () => {
    setTargetID(null);
    handleCloseDialog();
  };

  useEffect(() => {
    setArrivalInputsErrors([]);
    setChanged(false);
    if (targetID) {
      ctx
        .getRecordList<PurchaseOrderItem>('/api/purchase-order-items/', { code: targetID })
        .then((orderData) => {
          ctx
            .getRecordList<ArrivalItem>('/api/stock/arrival-items', { code: targetID })
            .then((arrivalData) => {
              setArrivalItems(arrivalData);
              setArrivalInputs(
                orderData
                  .filter((order) => order.quantity > order.arrival_quantity)
                  .map((order) => ({
                    orderItem: order,
                    input: {
                      item_code: order.code,
                      expiration_date: '',
                      quantity: order.quantity - order.arrival_quantity,
                    },
                  }))
              );
            })
            .catch((e) => {
              console.error(e);
            });
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [ctx, targetID, open]);

  const onSubmit = () => {
    setOpenConfirmDialog(true);
  };

  const itemErrors = arrivalInputsErrors.map((item, index) => {
    if (typeof item === 'string') {
      return (
        <Grid item xs={12}>
          <Typography color="red">{item}</Typography>
        </Grid>
      );
    }
    const fields: { [key: string]: string } = {
      quantity: '数量',
      expiration_date: '賞味期限/消費期限',
      item_code: '明細',
    };
    return Object.keys(item).map((key) => (
      <Grid item xs={12}>
        <Typography color="red">{`${index + 1}行目（${fields[key]}）:${item[key] as string}`}</Typography>
      </Grid>
    ));
  });

  const [openAddArrivalItemDialog, setOpenAddArrivalItemDialog] = useState<boolean>(false);

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item>
                  <BackButton onClose={onClose} requireConfirm={changed} />
                </Grid>
                {!arrivalCompleted && (
                  <>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={onSubmit}
                        startIcon={<AppRegistration />}
                        sx={{ width: 150 }}
                      >
                        入荷登録
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => setOpenAddArrivalItemDialog(true)}
                        startIcon={<AppRegistration />}
                        sx={{ width: 150 }}
                      >
                        明細追加
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            {!arrivalCompleted && (
              <>
                {itemErrors}
                <Grid item xs={12}>
                  <CommonTable columns={inputColumns} idColumn={6} rows={inputRows} emptyMsg="明細を追加して下さい。" />
                </Grid>
              </>
            )}
            {arrivalRows.length !== 0 && (
              <Grid item xs={12}>
                <Typography variant="h5">入荷済明細一覧</Typography>
                <CommonTable columns={arrivalColumns} idColumn={0} rows={arrivalRows} />
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
      <AddArrivalItemDialog
        arrivalInputs={arrivalInputs}
        setArrivalInputs={(inputs: { orderItem: PurchaseOrderItem; input: InputFields }[]) => {
          setArrivalInputs(inputs);
          setChanged(true);
        }}
        handleCloseDialog={() => setOpenAddArrivalItemDialog(false)}
        order_number={targetID}
        open={openAddArrivalItemDialog}
      />
      <ConfirmationDialog
        inputs={arrivalInputs.map((input) => input.input)}
        url="/api/stock/arrival-items"
        message={message}
        onSucceedFunc={onSucceedSubmit}
        setErrorFunc={setArrivalInputsErrors}
        open={openConfirmDialog}
        setAlertMessage={setAlertMessage}
        setOpenFunc={setOpenConfirmDialog}
      />
    </>
  );
};

export default ArrivalManageDialog;
