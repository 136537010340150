import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { AppRegistration, ManageSearch } from '@mui/icons-material';

import type { Cell, InputFields, SelectBoxItem } from '../../components/types';
import type { Warehouse } from '../../components/berth/types';
import type { Office, Product, ShipmentHeader } from '../../components/stock/types';
import CommonPaginationTable, { RowsAndCount, Handler } from '../../components/common_pagination_table';
import { CommonTable } from '../../components/common_table';
import Header from '../../components/header';
import { apiContext } from '../../hooks/call_apis';
import { getAmountFormat, getDateFormat } from '../../components/get_format';
import ShipmentCompleteDialog from '../../components/stock/shipment_complete_dialog';
import BulkShipmentDialog from '../../components/stock/bulk_shipment_dialog';
import ShipmentsSearchBox from '../../components/stock/shipments_search_box';
import { MessageSnackbar, AlertMessage } from '../../components/message_snackbar';

const Buttons: React.FC<{
  setOpenShipmentDialog: CallableFunction;
  setOpenBulkShipmentDialog: CallableFunction;
  search: CallableFunction;
}> = ({ setOpenShipmentDialog, setOpenBulkShipmentDialog, search }) => {
  const handleOpenShipmentDialog = () => {
    setOpenShipmentDialog();
  };
  const handleOpenBulkShipmentDialog = () => {
    setOpenBulkShipmentDialog();
  };
  const handleClickSearch = () => {
    search();
  };
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button variant="contained" onClick={handleClickSearch} startIcon={<ManageSearch />}>
          検索
        </Button>
      </Grid>
      <Grid item>
        <Button variant="contained" color="success" onClick={handleOpenShipmentDialog} startIcon={<AppRegistration />}>
          追加出荷登録
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="success"
          onClick={handleOpenBulkShipmentDialog}
          startIcon={<AppRegistration />}
        >
          一括出荷登録
        </Button>
      </Grid>
    </Grid>
  );
};
const ShipmentPage: React.FC = () => {
  const [alertMessage, setAlertMessage] = useState<AlertMessage>({ open: false, message: '', severity: 'success' });
  const [shipFromQueries, setShipFromQueries] = useState<Warehouse[]>([]);
  const [officeQueries, setOfficeQueries] = useState<Office[]>([]);
  const [productQueries, setProductQueries] = useState<Product[]>([]);
  const [dateSearchQueries, setDateSearchQueries] = useState<InputFields>({});
  const [isShippedQuery, setIsShippedQuery] = useState<SelectBoxItem | null>(null);
  const [targetShipmentHeader, setTargetShipmentHeader] = useState<string | null>(null);

  const [openShipmentDialog, setOpenShipmentDialog] = useState<boolean>(false);
  const [openBulkShipmentDialog, setOpenBulkShipmentDialog] = useState<boolean>(false);
  const [rows, setRows] = useState<RowsAndCount>({ count: 0, rows: [] });
  const [shipmentRows, setShipmentRows] = useState<Cell[][]>([]);
  const [shipments, setShipments] = useState<ShipmentHeader[]>([]);
  const paginationTableRef = useRef({} as Handler);
  const ctx = useContext(apiContext);

  const initializePagination = () => {
    paginationTableRef.current?.initializePagination();
  };

  const handleCloseDialog = () => {
    paginationTableRef.current?.updatePagination();
    setOpenShipmentDialog(false);
    setOpenBulkShipmentDialog(false);
  };

  const handleOpenDialog = (val?: string) => {
    if (val) {
      setTargetShipmentHeader(val);
    } else {
      setTargetShipmentHeader(null);
    }

    setOpenShipmentDialog(true);
  };

  const handleOpenBulkDialog = () => {
    setOpenBulkShipmentDialog(true);
  };

  const columns = [
    { header: '伝票番号' },
    { header: '出荷日' },
    { header: '事業所' },
    { header: '部署' },
    { header: '出荷元' },
    { header: '出荷確定状況', columnType: 'Button', columnFunc: handleOpenDialog },
  ];

  const shipmentColumns = [
    { header: '商品コード' },
    { header: '商品名', width: '30%' },
    { header: 'ロット' },
    { header: '引当数' },
    { header: '出荷数' },
  ];

  // CommonPaginationTableで呼び出すデータ取得
  const setRowsFunc = useCallback(
    (page: number, pageSize: number, params: { [name: string]: string | string[] }) => {
      setShipmentRows([]);
      ctx
        .getPaginationRecordList<ShipmentHeader>('/api/shipment-headers/', page, pageSize, params)
        .then((data) => {
          setRows({
            count: data.count,
            rows: data.results.map((shipmentHeader) => [
              {
                display: shipmentHeader.received_order.order_number.toString(),
                val: shipmentHeader.code.toString(),
              },
              { display: shipmentHeader.date ? getDateFormat(new Date(shipmentHeader.date)) : '' },
              { display: shipmentHeader.received_order.department.office.name },
              { display: shipmentHeader.received_order.department.name },
              { display: shipmentHeader.received_order.ship_from.warehouse_name },
              { display: shipmentHeader.is_shipped ? '確定済み' : '未確定', val: shipmentHeader.code },
            ]),
          });
          setShipments(data.results);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    [ctx]
  );

  const onClickHeader = (targetCode: string | null) => {
    if (targetCode) {
      ctx
        .getRecord<ShipmentHeader>('/api/shipment-headers', targetCode)
        .then((data) => {
          if (data.is_shipped) {
            setShipmentRows(
              data.shipment_items.map((item) => [
                { display: item.allocate_item.stock.lot.product.code },
                { display: item.allocate_item.stock.lot.product.name },
                { display: item.allocate_item.stock.lot.code },
                { display: getAmountFormat(item.allocate_item.quantity?.toString() ?? '0') },
                { display: getAmountFormat(item.quantity?.toString() ?? '0') },
              ])
            );
          } else {
            setShipmentRows([]);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    setRowsFunc(1, 5, {});
  }, [ctx, setRowsFunc]);

  const searchHeader = useCallback(
    (page: number, pageSize: number) => {
      setRowsFunc(page, pageSize, {
        product: productQueries.map((product) => product.code),
        ship_from: shipFromQueries.map((shipFrom) => shipFrom.warehouse_code),
        office: officeQueries.map((office) => office.code),
        date__gte: dateSearchQueries.date__gte as string,
        date__lte: dateSearchQueries.date__lte as string,
        is_shipped: isShippedQuery?.code ?? '',
      });
    },
    [dateSearchQueries, officeQueries, productQueries, shipFromQueries, isShippedQuery, setRowsFunc]
  );

  return (
    <>
      <MessageSnackbar alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
      <Header screenName="出荷一覧" />
      <Box sx={{ margin: '2%' }}>
        <Grid container justifyContent="center" spacing={6}>
          <Grid item xs={12}>
            <Buttons
              search={initializePagination}
              setOpenShipmentDialog={handleOpenDialog}
              setOpenBulkShipmentDialog={handleOpenBulkDialog}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={6}>
              <Grid item xs={4}>
                <ShipmentsSearchBox
                  shipFromVals={shipFromQueries}
                  setShipFromVals={setShipFromQueries}
                  productVals={productQueries}
                  setProductVals={setProductQueries}
                  officeVals={officeQueries}
                  setOfficeVals={setOfficeQueries}
                  dateSearchVals={dateSearchQueries}
                  setDateSearchVals={setDateSearchQueries}
                  isShippedVal={isShippedQuery}
                  setIsShippedVal={setIsShippedQuery}
                />
              </Grid>
              <Grid item xs={8}>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Typography> 明細を確認する出荷を選択してください。 </Typography>
                    <CommonPaginationTable
                      columns={columns}
                      idColumn={0}
                      rowsAndCount={rows}
                      paginationAPIFunc={searchHeader}
                      onClickRowFunc={onClickHeader}
                      ref={paginationTableRef}
                    />
                  </Grid>

                  {shipmentRows.length !== 0 && (
                    <Grid item>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="h4">出荷明細一覧</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <CommonTable columns={shipmentColumns} idColumn={0} rows={shipmentRows} />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <ShipmentCompleteDialog
        targetCode={targetShipmentHeader}
        setTargetCode={setTargetShipmentHeader}
        setAlertMessage={setAlertMessage}
        handleCloseDialog={() => handleCloseDialog()}
        open={openShipmentDialog}
        is_shipped={shipments.find((item) => item.code === targetShipmentHeader)?.is_shipped ?? false}
      />
      <BulkShipmentDialog
        setAlertMessage={setAlertMessage}
        handleCloseDialog={() => handleCloseDialog()}
        open={openBulkShipmentDialog}
      />
    </>
  );
};

export default ShipmentPage;
