import React from 'react';

import { TextField } from '@mui/material';

export const WarehouseSearchBox: React.FC<{
  warehouseNameVal: string;
  setWarehouseNameVal: (val: string) => void;
}> = ({ warehouseNameVal, setWarehouseNameVal }) => (
  <TextField
    InputLabelProps={{ shrink: true }}
    id="warehouse_name-search"
    label="倉庫名"
    type="text"
    value={warehouseNameVal}
    onChange={(event) => setWarehouseNameVal(event.target.value)}
    size="small"
    fullWidth
  />
);

export default WarehouseSearchBox;
