import React, { useContext, useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, Grid, InputAdornment, TextField } from '@mui/material';
import { AppRegistration } from '@mui/icons-material';

import type { InputFields } from '../types';
import type { AllocateItem, ReceivedOrderItem, Stock } from './types';
import { apiContext } from '../../hooks/call_apis';
import { SelectBox } from '../auto_complete_select_box';
import { getAmountFormat } from '../get_format';
import BackButton from '../back_button';

const AddAllocateItemDialog: React.FC<{
  allocateInputs: { allocateItem: AllocateItem; input: InputFields }[];
  setAllocateInputs: CallableFunction;
  handleCloseDialog: () => void;
  orderItem: ReceivedOrderItem | null;
  placeCode: string | null;
  open: boolean;
}> = ({ allocateInputs, setAllocateInputs, handleCloseDialog, orderItem, placeCode, open }) => {
  const [stocks, setStocks] = useState<Stock[]>([]);

  const [stockVal, setStockVal] = useState<Stock | null>(null);
  const [quantityVal, setQuantityVal] = useState<number | null>(null);
  const [changed, setChanged] = useState<boolean>(false);

  const [stockError, setStockError] = useState<string | null>(null);
  const [quantityError, setQuantityError] = useState<string | null>(null);
  const onClose = () => {
    handleCloseDialog();
  };

  const onSubmit = () => {
    setQuantityError(null);
    setStockError(null);

    let quantityErrorStr = null;
    let stockErrorStr = null;
    if (quantityVal === null) {
      quantityErrorStr = 'この項目は必須です。';
    } else if (quantityVal <= 0) {
      quantityErrorStr = 'この値は0より大きくしてください。';
    }
    if (!stockVal) {
      stockErrorStr = 'この項目は必須です。';
    } else if (!!quantityVal && stockVal.allocatable_quantity < quantityVal) {
      quantityErrorStr = 'この値は引当可能数以下にしてください。';
    }
    if (stockErrorStr || quantityErrorStr) {
      setQuantityError(quantityErrorStr);
      setStockError(stockErrorStr);
      return;
    }

    setAllocateInputs([
      ...allocateInputs,
      {
        allocateItem: {
          stock: stockVal,
        },
        input: {
          update_code: null,
          stock_code: stockVal?.code,
          order_item_code: orderItem?.code ?? '',
          quantity: quantityVal,
        },
      },
    ]);
    handleCloseDialog();
  };

  const ctx = useContext(apiContext);
  useEffect(() => {
    setQuantityError(null);
    setStockError(null);
    setStockVal(null);
    setQuantityVal(null);
    setChanged(false);
    if (orderItem && placeCode) {
      ctx
        .getRecordList<Stock>('/api/stocks/', {
          product: orderItem.item.code,
          place: placeCode,
        })
        .then((data) => {
          const stocksInItem = allocateInputs
            .filter((inputs) => !inputs.input.deleted)
            .map((inputs) => inputs.input.stock_code);
          const stockChoices = data.filter((stock) => !stocksInItem.includes(stock.code));
          setStocks(stockChoices);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [ctx, orderItem, placeCode, allocateInputs, open]);

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogContent>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item>
                <BackButton onClose={onClose} requireConfirm={changed} />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="success"
                  onClick={onSubmit}
                  startIcon={<AppRegistration />}
                  sx={{ width: 150 }}
                >
                  追加
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <SelectBox
                  id="stock-inputs"
                  items={stocks}
                  labelKey="name"
                  idKey="code"
                  boxLabel="在庫ロット"
                  boxPlaceholder=""
                  itemVal={stockVal}
                  setFunc={(value: Stock) => {
                    setStockVal(value);
                    setChanged(true);
                  }}
                  helperText={`引当可能数：${getAmountFormat(stockVal?.allocatable_quantity?.toString() ?? '')}`}
                  errorMsg={stockError}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="引当数"
                  type="number"
                  value={quantityVal ?? ''}
                  onChange={(event) => {
                    setQuantityVal(Number(event.target.value));
                    setChanged(true);
                  }}
                  size="small"
                  error={!!quantityError}
                  helperText={quantityError}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">{stockVal?.lot.product?.stock_unit.name}</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddAllocateItemDialog;
