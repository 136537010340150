import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

import type { Cell } from '../../components/types';
import type { AppUser } from '../../components/account/types';
import type { Customer } from '../../components/berth/types';
import { CommonTable, Handler } from '../../components/common_table';
import { MessageSnackbar, AlertMessage } from '../../components/message_snackbar';
import Header from '../../components/header';
import { UsersSearchBox } from '../../components/account/users_search_box';
import UserManageDialog from '../../components/account/user_manage_dialog';
import { apiContext } from '../../hooks/call_apis';

const Buttons: React.FC<{
  handleOpenDialogFunc: CallableFunction;
  search: CallableFunction;
}> = ({ handleOpenDialogFunc, search }) => {
  const handleOpenUserDialog = () => {
    handleOpenDialogFunc();
  };
  const handleClickSearch = () => {
    search();
  };
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button id="search-users" variant="contained" startIcon={<ManageSearchIcon />} onClick={handleClickSearch}>
          検索
        </Button>
      </Grid>
      <Grid item>
        <Button
          id="create-user"
          variant="contained"
          color="success"
          startIcon={<AppRegistrationIcon />}
          onClick={handleOpenUserDialog}
        >
          新規登録
        </Button>
      </Grid>
    </Grid>
  );
};

const UserPage: React.FC = () => {
  const [openUserDialog, setOpenUserDialog] = useState<boolean>(false);
  const [updateUser, setUpdateUser] = useState<string | null>(null);

  const [rows, setRows] = useState<Cell[][]>([[]]);

  const ctx = useContext(apiContext);

  const [customerQuery, setCustomerQuery] = useState<Customer | null>(null);
  const [lastNameQuery, setLastNameQuery] = useState<string | null>(null);
  const [firstNameQuery, setFirstNameQuery] = useState<string | null>(null);

  const tableRef = useRef({} as Handler);
  const getUserTable = () => {
    ctx
      .getRecordList<AppUser>('/api/app-users/', {
        customer: customerQuery?.code ?? '',
        last_name: lastNameQuery ?? '',
        first_name: firstNameQuery ?? '',
      })
      .then((data) => {
        setRows(
          data.map((user) => [
            { display: user.username, val: user.pk },
            { display: `${user.last_name} ${user.first_name}` },
            {
              display: user.groups.map((group) => group.verbose_name).join('、'),
            },
            { display: user.email },
            { display: user.customer?.name ?? '' },
          ])
        );
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const search = () => {
    getUserTable();
    tableRef.current?.initializePage();
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseDialog = () => {
    setOpenUserDialog(false);
    getUserTable();
  };

  const handleOpenUserDialog = (targetCode?: string) => {
    if (targetCode !== undefined) setUpdateUser(targetCode);
    else setUpdateUser(null);
    setOpenUserDialog(true);
  };

  const [alertMessage, setAlertMessage] = useState<AlertMessage>({ open: false, message: '', severity: 'success' });

  const columns = [
    { header: 'ユーザーID', columnType: 'Button', columnFunc: handleOpenUserDialog },
    { header: '氏名' },
    { header: '権限グループ' },
    { header: 'E-Mailアドレス' },
    { header: '取引先' },
  ];

  return (
    <>
      <MessageSnackbar alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
      <Header screenName="ユーザーマスタ" />
      <Box sx={{ margin: '2%' }}>
        <Grid container justifyContent="center" spacing={6}>
          <Grid item xs={12}>
            <Buttons handleOpenDialogFunc={handleOpenUserDialog} search={search} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={6}>
              <Grid item xs={4}>
                <UsersSearchBox
                  lastNameVal={lastNameQuery}
                  setLastNameVal={setLastNameQuery}
                  firstNameVal={firstNameQuery}
                  setFirstNameVal={setFirstNameQuery}
                  customerVal={customerQuery}
                  setCustomerVal={setCustomerQuery}
                />
              </Grid>
              <Grid item xs={8}>
                <CommonTable columns={columns} idColumn={0} rows={rows} ref={tableRef} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <UserManageDialog
        setAlertMessage={setAlertMessage}
        handleCloseDialog={() => handleCloseDialog()}
        open={openUserDialog}
        updateTargetID={updateUser}
        setUpdateTargetID={setUpdateUser}
      />
    </>
  );
};

export default UserPage;
