import React, { useContext } from 'react';
import { AxiosError } from 'axios';
import { Grid, Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import type { Customer, WeeklyPlanDict, WeeklyPlanUpdate, Warehouse } from './types';
import { SelectBox } from '../auto_complete_select_box';
import { apiContext } from '../../hooks/call_apis';

export const PlanEditBox: React.FC<{
  customers: Customer[];
  customerVal: Customer | null;
  weeklyPlanDict: WeeklyPlanDict | null;
  weeklyPlanUpdate: { [code: string]: WeeklyPlanUpdate };
  setCustomerVal: CallableFunction;
  setWeeklyPlanUpdate: CallableFunction;
  updateWeeklyPlanDict: CallableFunction;
  setErrorMessage: CallableFunction;
}> = ({
  customers,
  customerVal,
  weeklyPlanDict,
  weeklyPlanUpdate,
  setCustomerVal,
  setWeeklyPlanUpdate,
  updateWeeklyPlanDict,
  setErrorMessage,
}) => {
  const ctx = useContext(apiContext);

  const initializeWeeklyPlanUpdate = (val: Customer) => {
    const weeklyPlanSeed: { [name: string]: WeeklyPlanUpdate } = {};
    if (weeklyPlanDict && val) {
      Object.keys(weeklyPlanDict).forEach((weekdayKey) => {
        Object.keys(weeklyPlanDict[weekdayKey]).forEach((slotKey) => {
          if (
            weeklyPlanDict[weekdayKey][slotKey].map((c: { code: string; name: string }) => c.code).includes(val.code)
          ) {
            weeklyPlanSeed[`${weekdayKey}_${slotKey}_${val.code}`] = {
              weekday_code: weekdayKey,
              slot_code: slotKey,
              customer_code: val.code,
              status: true,
            };
          }
        });
      });
    }
    setWeeklyPlanUpdate(weeklyPlanSeed);
  };

  const onChangeFunc = (val: Customer) => {
    initializeWeeklyPlanUpdate(val);
    setCustomerVal(val);
  };

  const onSaveFunc = () => {
    setErrorMessage(null);
    ctx
      .postAPI('/api/berth/weekly-plan-edit', weeklyPlanUpdate)
      .then(() => {
        updateWeeklyPlanDict();
      })
      .catch((e: AxiosError) => {
        setErrorMessage(e.response?.data);
        if (customerVal) {
          initializeWeeklyPlanUpdate(customerVal);
        }
        console.error(e);
      });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SelectBox
          id="customer-select"
          items={customers}
          labelKey="name"
          idKey="code"
          boxLabel="取引先"
          boxPlaceholder="取引先を選択"
          itemVal={customerVal}
          setFunc={onChangeFunc}
        />
      </Grid>
      <Grid item>
        <Button
          color="success"
          startIcon={<SaveIcon />}
          disabled={!customerVal}
          variant="contained"
          onClick={onSaveFunc}
        >
          保存
        </Button>
      </Grid>
    </Grid>
  );
};

export const PlanSearchBox: React.FC<{
  warehouses: Warehouse[];
  warehouseVal: Warehouse | null;
  onSetWarehouse: CallableFunction;
  disabled: boolean;
}> = ({ warehouses, warehouseVal, onSetWarehouse, disabled }) => (
  <SelectBox
    id="warehouse-select"
    items={warehouses}
    labelKey="warehouse_name"
    idKey="warehouse_code"
    boxLabel="倉庫"
    disabled={disabled}
    boxPlaceholder="倉庫を選択"
    itemVal={warehouseVal}
    setFunc={onSetWarehouse}
  />
);
